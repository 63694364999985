define('fti-pump-selector/components/head-curve-chart', ['exports', 'd3-selection', 'd3-scale', 'd3-axis', 'd3-shape', 'lodash'], function (exports, _d3Selection, _d3Scale, _d3Axis, _d3Shape, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: 'curvechart curvechart--head',

    portraitSvgWidth: 700,
    portraitSvgHeight: 200,
    portraitAxisFontSize: '14px',

    isLandscape: false,
    landscapeSvgWidth: 700,
    landscapeSvgHeight: 200,
    landscapeAxisFontSize: '11px',

    viewBox: '0 0 400 400',
    attributeBindings: ['width', 'height', 'viewBox'],

    effectiveSvgWidth: Ember.computed('svgWidth', function () {
      return this.get('isLandscape') ? this.get('landscapeSvgWidth') : this.get('portraitSvgWidth');
    }),

    effectiveSvgHeight: Ember.computed('svgHeight', function () {
      return this.get('isLandscape') ? this.get('landscapeSvgHeight') : this.get('portraitSvgHeight');
    }),

    effectiveAxisFontSize: Ember.computed('isLandscape', function () {
      return this.get('isLandscape') ? this.get('landscapeAxisFontSize') : this.get('portraitAxisFontSize');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      // Schedule a call to our `drawCircles` method on Ember's "render" queue, which will
      // happen after the component has been placed in the DOM, and subsequently
      // each time data is changed.
      Ember.run.scheduleOnce('render', this, this.createChart);
    },
    createChart: function createChart() {
      var _this = this;

      var plot = (0, _d3Selection.select)(this.element);
      var headData = Ember.get(this, 'headData');
      var maximumImpHeadData = Ember.get(this, 'maximumImpellerData');
      var efficiencyData = Ember.get(this, 'efficiencyData');
      var effectiveSvgWidth = Ember.get(this, 'effectiveSvgWidth');
      var effectiveSvgHeight = Ember.get(this, 'effectiveSvgHeight');
      var margin = { top: 10, bottom: 10, left: 40, right: 40 };

      this.set('viewBox', '0 0 ' + this.get('effectiveSvgWidth') + ' ' + this.get('effectiveSvgHeight'));

      plot.selectAll('*').remove();

      var headXMax = _lodash.default.maxBy(headData, 'x').x + 40;
      var maximumImpHeadXMax = _lodash.default.get(_lodash.default.maxBy(maximumImpHeadData, 'x'), 'x', 0) + 10;
      var xMax = _lodash.default.max([headXMax, maximumImpHeadXMax]);
      var xScale = (0, _d3Scale.scaleLinear)().domain([0, xMax]).range([margin.left, effectiveSvgWidth - margin.right]);
      var xAxis = (0, _d3Axis.axisBottom)(xScale).ticks(16).tickSizeInner(-(effectiveSvgHeight - margin.top - margin.bottom)).tickSizeOuter(-(effectiveSvgHeight - margin.top - margin.bottom));
      var xAxisVectors = plot.append('g').attr('class', 'axis axis--x').attr('transform', 'translate(0, ' + (effectiveSvgHeight - margin.bottom) + ')').call(xAxis);
      // Turn back on xAxis labels if this chart is the last in the list
      if (this.get('showXAxisLabels')) {
        xAxisVectors.selectAll('.tick text').style('display', 'inline');
      }

      // Set up common scale for both y-axises
      var headYMax = _lodash.default.maxBy(headData, 'y').y + 20;
      var maximumImpHeadYMax = _lodash.default.get(_lodash.default.maxBy(maximumImpHeadData, 'y'), 'y', 0) + 20;
      var efficiencyMax = _lodash.default.maxBy(efficiencyData, 'y').y + 10;
      var yMax = _lodash.default.max([headYMax, maximumImpHeadYMax, efficiencyMax]);
      var yScale = (0, _d3Scale.scaleLinear)().domain([0, yMax]).range([effectiveSvgHeight - margin.bottom, margin.top]);

      var yAxisLeft = (0, _d3Axis.axisLeft)(yScale).ticks(10).tickSizeInner(-(effectiveSvgWidth - margin.left - margin.right)).tickSizeOuter(-(effectiveSvgWidth - margin.left - margin.right));
      plot.append('g').attr('class', 'axis axis--y1').attr('transform', 'translate(' + margin.left + ', 0)').call(yAxisLeft);
      var headLine = (0, _d3Shape.line)().x(function (d) {
        return xScale(d.x);
      }).y(function (d) {
        return yScale(d.y);
      }).curve(_d3Shape.curveBasis);

      var yAxisRight = (0, _d3Axis.axisLeft)(yScale).ticks(10).tickSize(0);
      plot.append('g').attr('class', 'axis axis--y2').attr('transform', 'translate(' + (effectiveSvgWidth - margin.right + 10) + ', 0)').call(yAxisRight).selectAll('text').style('text-anchor', 'start');
      var efficiencyLine = (0, _d3Shape.line)().x(function (d) {
        return xScale(d.x);
      }).y(function (d) {
        return yScale(d.y);
      }).curve(_d3Shape.curveBasis);

      // Plot before in order for it to be below the main head line
      if (this.get('minimumImpeller.id') !== undefined) {
        // Create the minimum impeller head data plot line
        plot.append('svg:path').attr('d', headLine(this.get('minimumImpellerData'))).attr('stroke', '#000').attr('stroke-width', 2).attr('fill', 'none');
      }

      // Create the head data plot line
      plot.append('svg:path').attr('d', headLine(this.get('headData'))).attr('stroke', '#369ead').attr('stroke-width', 2).attr('fill', 'none');

      if (this.get('maximumImpeller.id') !== undefined) {
        // Create the maximum impeller head data plot line
        plot.append('svg:path').attr('d', headLine(this.get('maximumImpellerData'))).attr('stroke', '#000').attr('stroke-width', 2).attr('fill', 'none');
      }

      // Create the efficiency data plot line
      plot.append('svg:path').attr('d', efficiencyLine(this.get('efficiencyData'))).attr('stroke', '#369ead').attr('stroke-width', 2).attr('fill', 'none');

      var lastEffPoint = _lodash.default.last(this.get('efficiencyData'));
      plot.append('text').attr('class', 'curvechart__linehelper').attr('text-anchor', 'middle').attr('x', xScale(lastEffPoint.x) + 30).attr('y', yScale(lastEffPoint.y) - 2).text('Efficiency');

      // Add in left axis label
      plot.append('text').attr('transform', 'rotate(-90)').attr('y', 0).attr('x', 0 - effectiveSvgHeight / 2).attr('dy', '1em').style('text-anchor', 'middle').style('font-size', this.get('effectiveAxisFontSize')).text(this.get('y1AxisTitle'));

      // Add in right axis label
      plot.append('text').attr('transform', 'rotate(-90)').attr('y', effectiveSvgWidth - margin.right + 22).attr('x', 0 - effectiveSvgHeight / 2).attr('dy', '1em').style('text-anchor', 'middle').style('font-size', this.get('effectiveAxisFontSize')).text(this.get('y2AxisTitle'));

      var firstPoint = _lodash.default.head(this.get('headData'));
      plot.append('text').attr('class', 'curvechart__linehelper').attr('text-anchor', 'middle').attr('x', xScale(firstPoint.x) + 20).attr('y', yScale(firstPoint.y) - 10).text(this.get('impeller.diameter') + ' in');

      if (this.get('minimumImpeller.id') !== undefined) {
        var firstMinimumPoint = _lodash.default.head(this.get('minimumImpellerData'));
        plot.append('text').attr('class', 'curvechart__linehelper curvechart__linehelper--alt').attr('text-anchor', 'middle').attr('x', xScale(firstMinimumPoint.x) + 20).attr('y', yScale(firstMinimumPoint.y) + 15).text(this.get('minimumImpeller.diameter') + ' in');
      }

      if (this.get('maximumImpeller.id') !== undefined) {
        var firstMaximumPoint = _lodash.default.head(this.get('maximumImpellerData'));
        plot.append('text').attr('class', 'curvechart__linehelper curvechart__linehelper--alt').attr('text-anchor', 'middle').attr('x', xScale(firstMaximumPoint.x) + 20).attr('y', yScale(firstMaximumPoint.y) + 12).text(this.get('maximumImpeller.diameter') + ' in');
      }

      // Add in head envelope
      if (!Ember.isBlank(this.get('pumpApplication'))) {
        plot.append('path').attr('d', function () {
          var x = xScale(_this.get('pumpApplication.design_flow')),
              y = yScale(_this.get('pumpCalculations.head'));
          return 'M ' + x + ' ' + y + ' l 0 0 l 0 25 l -25 -25 z';
        }).style('stroke', 'blue').style('stroke-width', 2).style('fill', 'transparent');
      }
    }
  });
});