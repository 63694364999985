define('fti-pump-selector/services/max-pressures', ['exports', 'lodash/collection'], function (exports, _collection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.pressures = [{
        model: 'DB10',
        maxPressurePSI: 80
      }, {
        model: 'DB11',
        maxPressurePSI: 90
      }, {
        model: 'DB15',
        maxPressurePSI: 90
      }, {
        model: 'DB22',
        maxPressurePSI: 90
      }, {
        model: 'DB3',
        maxPressurePSI: 60
      }, {
        model: 'DB4',
        maxPressurePSI: 60
      }, {
        model: 'DB5',
        maxPressurePSI: 60
      }, {
        model: 'DB6',
        maxPressurePSI: 80
      }, {
        model: 'DB6H',
        maxPressurePSI: 80
      }, {
        model: 'DB7',
        maxPressurePSI: 80
      }, {
        model: 'DB8',
        maxPressurePSI: 80
      }, {
        model: 'DB9',
        maxPressurePSI: 80
      }, {
        model: 'SP11',
        maxPressurePSI: 90
      }, {
        model: 'SP15',
        maxPressurePSI: 90
      }, {
        model: 'SP22',
        maxPressurePSI: 90
      }, {
        model: 'UC1516',
        maxPressurePSI: 175
      }, {
        model: 'UC1518',
        maxPressurePSI: 175
      }, {
        model: 'UC326',
        maxPressurePSI: 175
      }, {
        model: 'SP10',
        maxPressurePSI: 80
      }, {
        model: 'DB5.5',
        maxPressurePSI: 60
      }, {
        model: 'AC4',
        maxPressurePSI: 100
      }, {
        model: 'AC5',
        maxPressurePSI: 100
      }, {
        model: 'AC6',
        maxPressurePSI: 100
      }, {
        model: 'AC5H',
        maxPressurePSI: 100
      }, {
        model: 'AC5R',
        maxPressurePSI: 100
      }, {
        model: 'AC6',
        maxPressurePSI: 100
      }, {
        model: 'AC6H',
        maxPressurePSI: 100
      }, {
        model: 'AC8',
        maxPressurePSI: 120
      }, {
        model: 'AC8H',
        maxPressurePSI: 120
      }, {
        model: 'GP11',
        maxPressurePSI: 35
      }, {
        model: 'GP22',
        maxPressurePSI: 55
      }, {
        model: 'GP32',
        maxPressurePSI: 55
      }, {
        model: 'KC22',
        maxPressurePSI: 70
      }, {
        model: 'KC10',
        maxPressurePSI: 60
      }, {
        model: 'KC11',
        maxPressurePSI: 50
      }, {
        model: 'KC3',
        maxPressurePSI: 60
      }, {
        model: 'KC32',
        maxPressurePSI: 70
      }, {
        model: 'KC4',
        maxPressurePSI: 60
      }, {
        model: 'KC5',
        maxPressurePSI: 60
      }, {
        model: 'KC5.5',
        maxPressurePSI: 60
      }, {
        model: 'KC6',
        maxPressurePSI: 60
      }, {
        model: 'KC6H',
        maxPressurePSI: 60
      }, {
        model: 'KC7',
        maxPressurePSI: 60
      }, {
        model: 'KC8',
        maxPressurePSI: 60
      }, {
        model: 'MSKC',
        maxPressurePSI: 80
      }, {
        model: 'MSVKC',
        maxPressurePSI: 80
      }, {
        model: 'AK4',
        maxPressurePSI: 100
      }, {
        model: 'AK5',
        maxPressurePSI: 100
      }, {
        model: 'AK5R',
        maxPressurePSI: 100
      }, {
        model: 'AV4',
        maxPressurePSI: 100
      }, {
        model: 'AV5',
        maxPressurePSI: 100
      }, {
        model: 'AV5H',
        maxPressurePSI: 100
      }, {
        model: 'AV5R',
        maxPressurePSI: 100
      }, {
        model: 'AV6',
        maxPressurePSI: 100
      }, {
        model: 'AV6H',
        maxPressurePSI: 100
      }, {
        model: 'AV8',
        maxPressurePSI: 120
      }, {
        model: 'AV8H',
        maxPressurePSI: 120
      }, {
        model: 'UC438',
        maxPressurePSI: 300
      }, {
        model: 'UC3158',
        maxPressurePSI: 300
      }];
    },
    findMaxPressure: function findMaxPressure(modelRoot) {
      var pressures = this.get('pressures');

      var pressureFound = _collection.default.find(pressures, 'model', modelRoot);
      if (pressureFound) {
        return pressureFound.maxPressurePSI;
      } else {
        return 0;
      }
    }
  });
});