define('fti-pump-selector/services/pump-base-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    getRootObject: function getRootObject(root) {
      return this.store.queryRecord('pump-base-price', { root: root });
    }
  });
});