define('fti-pump-selector/routes/fti-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: '',
    pageTitle: '',

    activate: function activate() {
      this._super();
      var appController = this.controllerFor('application');
      appController.set('pageTitle', this.get('pageTitle'));
    }
  });
});