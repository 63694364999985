define('fti-pump-selector/lib/ember-promise-array-proxy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin, {
    /**
     * Content of the array
     * Will be set to the resolution of the promise
     */
    content: Ember.computed(function () {
      // Get the object's promise and assert that it is set
      var promise = this.get('promise');
      (true && !(promise) && Ember.assert('promise property must be set', promise));

      // Set up an internal ArrayProxy that we will return right away

      var contentProxy = Ember.ArrayProxy.create({
        content: Ember.A()
      });

      // Set the content on the inner ArrayProxy once the promise resolves
      promise.then(function (array) {
        (true && !(Ember.isArray(array)) && Ember.assert('resolved content must be an array', Ember.isArray(array)));

        contentProxy.set('content', array);
      });
      return contentProxy;
    }),

    /**
     * Promise that should resolve to an array
     */
    promise: null
  });
});