define('fti-pump-selector/components/pump-performance-curves', ['exports', 'fti-pump-selector/utils/conversions', 'fti-pump-selector/models/pump-calculations', 'lodash'], function (exports, _conversions, _pumpCalculations, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pumpApplication: Ember.inject.service(),

    pumpCalculations: Ember.computed.alias('model.pumpCalculations'),
    minimumImpeller: Ember.computed.alias('model.minimumImpeller'),
    maximumImpeller: Ember.computed.alias('model.maximumImpeller'),
    showPrintCurves: true,

    speedValue: Ember.computed.oneWay('model.speed'),
    trimValue: 100,
    trimmedDiameter: Ember.computed.oneWay('model.diameter'),
    sRatio: 1,
    dRatio: 1,

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('plainCurves') === true) {
        this.setProperties({
          showPrintCurves: false,
          disableInfoLines: true
        });
      }
    },


    // Create pump applications for pumps which didn't have it done during pump results
    // Initialize pump application calculations for minimum impeller
    minimumImpellerWithCalcs: Ember.computed('minimumImpeller', function () {
      var minImp = this.get('minimumImpeller');
      if (this.get('minimumImpeller.id') !== undefined && Ember.isNone(minImp.get('pumpCalculations'))) {
        var pumpCalcs = _pumpCalculations.default.create({
          pump: this.get('minimumImpeller'),
          flowUnit: this.get('flowUnit'),
          headUnit: this.get('headUnit'),
          powerUnit: this.get('powerUnit'),
          pressureUnit: this.get('pressureUnit'),
          viscosityUnit: this.get('viscosityUnit'),
          viscosity: Number(this.get('viscosity')),
          designFlow: Number(this.get('designFlow')),
          specificGravity: Number(this.get('specificGravity'))
        });
        minImp.set('pumpCalculations', pumpCalcs);
      }
      return minImp;
    }),

    // Create pump applications for pumps which didn't have it done during pump results
    // Initialize pump application calculations for maximum impeller
    maximumImpellerWithCalcs: Ember.computed('maximumImpeller', function () {
      var maxImp = this.get('maximumImpeller');
      if (this.get('maximumImpeller.id') !== undefined && Ember.isNone(maxImp.get('pumpCalculations'))) {
        var pumpCalcs = _pumpCalculations.default.create({
          pump: this.get('maximumImpeller'),
          flowUnit: this.get('flowUnit'),
          headUnit: this.get('headUnit'),
          powerUnit: this.get('powerUnit'),
          pressureUnit: this.get('pressureUnit'),
          viscosityUnit: this.get('viscosityUnit'),
          viscosity: Number(this.get('viscosity')),
          designFlow: Number(this.get('designFlow')),
          specificGravity: Number(this.get('specificGravity'))
        });
        maxImp.set('pumpCalculations', pumpCalcs);
      }
      return maxImp;
    }),

    flowDataPoints: Ember.computed('model', function () {
      var _this = this;

      return this.get('pumpCalculations.cQArray').map(function (q) {
        return new _conversions.Flow('GPM', q).convertTo(_this.get('flowUnit'));
      });
    }),
    minimumImpellerFlowDataPoints: Ember.computed('minimumImpellerWithCalcs.id', function () {
      var _this2 = this;

      if (this.get('minimumImpellerWithCalcs.id') !== undefined) {
        return this.get('minimumImpellerWithCalcs.pumpCalculations.cQArray').map(function (q) {
          return new _conversions.Flow('GPM', q).convertTo(_this2.get('flowUnit'));
        });
      }
    }),
    maximumImpellerFlowDataPoints: Ember.computed('maximumImpellerWithCalcs.id', function () {
      var _this3 = this;

      if (this.get('maximumImpellerWithCalcs.id') !== undefined) {
        return this.get('maximumImpellerWithCalcs.pumpCalculations.cQArray').map(function (q) {
          return new _conversions.Flow('GPM', q).convertTo(_this3.get('flowUnit'));
        });
      }
    }),

    headDataPoints: Ember.computed('model', function () {
      var _this4 = this;

      return this.get('pumpCalculations.cHArray').map(function (h) {
        return new _conversions.Head('FT', h, _this4.get('specificGravity')).convertTo(_this4.get('headUnit'));
      });
    }),
    minimumImpellerHeadDataPoints: Ember.computed('minimumImpellerWithCalcs.id', function () {
      var _this5 = this;

      if (this.get('minimumImpellerWithCalcs.id') !== undefined) {
        return this.get('minimumImpellerWithCalcs.pumpCalculations.cHArray').map(function (h) {
          return new _conversions.Head('FT', h, _this5.get('specificGravity')).convertTo(_this5.get('headUnit'));
        });
      }
    }),
    maximumImpellerHeadDataPoints: Ember.computed('maximumImpellerWithCalcs.id', function () {
      var _this6 = this;

      if (this.get('maximumImpellerWithCalcs.id') !== undefined) {
        return this.get('maximumImpellerWithCalcs.pumpCalculations.cHArray').map(function (h) {
          return new _conversions.Head('FT', h, _this6.get('specificGravity')).convertTo(_this6.get('headUnit'));
        });
      }
    }),

    headChartDataPoints: Ember.computed('sRatio', 'dRatio', 'flowDataPoints.[]', 'headDataPoints.[]', function () {
      var _this7 = this;

      var zipped = _lodash.default.zip(this.get('flowDataPoints'), this.get('headDataPoints'));

      return zipped.map(function (values) {
        return {
          x: values[0] * _this7.get('sRatio') * _this7.get('dRatio'),
          y: values[1] * Math.pow(_this7.get('sRatio'), 2) * Math.pow(_this7.get('dRatio'), 2)
        };
      });
    }),
    minimumImpellerHeadChartDataPoints: Ember.computed('sRatio', 'dRatio', 'minimumImpellerFlowDataPoints.[]', 'minimumImpellerHeadDataPoints.[]', function () {
      var _this8 = this;

      if (!Ember.isEmpty(this.get('minimumImpellerFlowDataPoints')) && !Ember.isEmpty(this.get('minimumImpellerHeadDataPoints'))) {
        var zipped = _lodash.default.zip(this.get('minimumImpellerFlowDataPoints'), this.get('minimumImpellerHeadDataPoints'));

        return zipped.map(function (values) {
          return {
            x: values[0] * _this8.get('sRatio') * _this8.get('dRatio'),
            y: values[1] * Math.pow(_this8.get('sRatio'), 2) * Math.pow(_this8.get('dRatio'), 2)
          };
        });
      }
    }),
    maximumImpellerHeadChartDataPoints: Ember.computed('sRatio', 'dRatio', 'maximumImpellerFlowDataPoints.[]', 'maximumImpellerHeadDataPoints.[]', function () {
      var _this9 = this;

      if (!Ember.isEmpty(this.get('maximumImpellerFlowDataPoints')) && !Ember.isEmpty(this.get('maximumImpellerHeadDataPoints'))) {
        var zipped = _lodash.default.zip(this.get('maximumImpellerFlowDataPoints'), this.get('maximumImpellerHeadDataPoints'));

        return zipped.map(function (values) {
          return {
            x: values[0] * _this9.get('sRatio') * _this9.get('dRatio'),
            y: values[1] * Math.pow(_this9.get('sRatio'), 2) * Math.pow(_this9.get('dRatio'), 2)
          };
        });
      }
    }),

    headChartYAxisTitle: Ember.computed('headUnit', function () {
      return 'Differential head / pressure - ' + this.get('headUnit');
    }),

    powerDataPoints: Ember.computed('model', function () {
      var _this10 = this;

      return this.get('pumpCalculations.cPowerArray').map(function (p) {
        return new _conversions.Power('HP', p).convertTo(_this10.get('powerUnit'));
      });
    }),
    powerChartDataPoints: Ember.computed('flowDataPoints.[]', 'powerDataPoints.[]', 'sRatio', 'dRatio', function () {
      var _this11 = this;

      var zipped = _lodash.default.zip(this.get('flowDataPoints'), this.get('powerDataPoints'));

      return zipped.map(function (values) {
        return {
          x: values[0] * _this11.get('sRatio') * _this11.get('dRatio'),
          y: values[1] * Math.pow(_this11.get('sRatio'), 3) * Math.pow(_this11.get('dRatio'), 3)
        };
      });
    }),
    powerChartYAxisTitle: Ember.computed('powerUnit', function () {
      return 'Power - ' + this.get('powerUnit');
    }),

    efficiencyDataPoints: Ember.computed('model', function () {
      return this.get('pumpCalculations.cEffArray');
    }),
    efficiencyChartDataPoints: Ember.computed('flowDataPoints.[]', 'efficiencyDataPoints.[]', 'sRatio', 'dRatio', function () {
      var _this12 = this;

      var zipped = _lodash.default.zip(this.get('flowDataPoints'), this.get('efficiencyDataPoints'));

      return zipped.map(function (values) {
        return {
          x: values[0] * _this12.get('sRatio') * _this12.get('dRatio'),
          y: values[1]
        };
      });
    }),
    efficiencyChartYAxisTitle: Ember.computed(function () {
      return 'Efficiency - %';
    }),

    npshDataPoints: Ember.computed('model', function () {
      var _this13 = this;

      return this.get('model.qhns').map(function (qhn) {
        return new _conversions.Head('FT', qhn.get('npsh'), _this13.get('specificGravity')).convertTo(_this13.get('headUnit'));
      });
    }),
    npshChartDataPoints: Ember.computed('flowDataPoints.[]', 'npshDataPoints.[]', 'sRatio', 'dRatio', function () {
      var _this14 = this;

      var zipped = _lodash.default.zip(this.get('flowDataPoints'), this.get('npshDataPoints'));

      return zipped.map(function (values) {
        // NPSH can not have 0 value points
        // This makes sure both axises are above 0.
        if (values[0] > 0 && values[1] > 0) {
          return {
            x: values[0] * _this14.get('sRatio') * _this14.get('dRatio'),
            y: values[1]
          };
        }
      }).filter(function (values) {
        if (!Ember.isNone(values)) {
          return values;
        }
      });
    }),
    npshChartYAxisTitle: Ember.computed('headUnit', function () {
      return 'NPSHr - ' + this.get('headUnit');
    }),
    isNPSHChartDataEmpty: Ember.computed('npshDataPoints', function () {
      return Ember.isEmpty(this.get('npshChartDataPoints').reject(function (point) {
        return point === undefined;
      }));
    }),

    modelChanged: Ember.observer('model', function () {
      this.trigger('redrawCharts');
    }),

    speedValueChanged: Ember.observer('speedValue', function () {
      this.set('sRatio', parseInt(this.get('speedValue')) / this.get('model.speed'));
      this.trigger('redrawCharts');
    }),

    trimValueChanged: Ember.observer('trimValue', function () {
      var modelDiameter = this.get('model.diameter');
      var trimmedDiameter = this.get('trimValue') / 100 * modelDiameter;
      var ratio = this.stepanoff(trimmedDiameter, modelDiameter) / modelDiameter;

      this.set('trimmedDiameter', trimmedDiameter);
      this.set('dRatio', ratio);

      this.trigger('redrawCharts');
    }),

    actions: {
      varySpeedChanged: function varySpeedChanged(value) {
        this.set('speedValue', Math.round(value));
      },
      printCurves: function printCurves() {
        var curveDataView = this.$();
        var curveData = curveDataView.clone();
        var curveDataPrint = Ember.$('<div/>').addClass('curve-data-print').html(curveData).appendTo('body');

        curveDataPrint.find('.slider-controls').remove();
        curveDataPrint.find('.print-curves').remove();

        Ember.$('.curve-data-print').printArea({
          mode: 'iframe',
          popClose: true
        });

        Ember.$('.curve-data-print').remove();
      }
    },

    generatePNGFromCanvas: function generatePNGFromCanvas(domPath) {
      var canvas = this.$(domPath).get(0);
      if (canvas) {
        return canvas.toDataURL('image/png');
      }
    },
    replaceChartDivWithImage: function replaceChartDivWithImage(container, domPath, img) {
      if (img && img !== '') {
        var domImg = Ember.$('<img/>', { src: img });
        container.find(domPath).empty().append(domImg);
      }
    },
    stepanoff: function stepanoff(trim, dia) {
      // This applies the Stepanoff correction to affinioty law diameter calculations
      // See p. 86, A. J. Stepanoff, "Centrifugal and Axial Flow Pumps", 2nd Edition
      // use the function value to get the diameter ratio to use in affinity calculations
      return 1.087 * trim - 0.09348 * dia;
    }
  });
});