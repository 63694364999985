define('fti-pump-selector/lib/sliced-computed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = sliced;
  function sliced(dependentKey, firstIndex, lastIndex) {
    return Ember.computed(dependentKey + '.[]', function () {
      return this.get(dependentKey).slice(firstIndex, lastIndex);
    });
  }
});