define('fti-pump-selector/models/settings', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _object.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.storageKey = 'fti-app-settings';
      this.initialContent = {
        unitSystem: 1,
        frequency: 60,
        fluid: 121,
        temperature: 68,
        temperatureUnit: 'F',
        viscosity: 1,
        viscosityUnit: 'centistokes',
        specificGravity: 1,
        motorSpeed: 'high',
        motorSize: 'design_point',
        motorAdapter: 'nema',
        motorPhase: 3,
        motorType: 'tefc',
        expressHeadInPressure: false,
        includeMotors: false,
        wetEndOnly: false,
        pump_seri: 'DB'
      };
    }
  });
});