define('fti-pump-selector/services/app-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    validFilters: false,
    allowDesignReset: false,
    contactFactoryPricing: false,
    // Drives the navigation options for pump selectors.
    pumpTypes: Ember.A([{
      value: 'pump-selector',
      label: 'Centrifugal'
    }, {
      value: 'drum-pump-selector',
      label: 'Drum'
    }])
  });
});