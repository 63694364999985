define('fti-pump-selector/transforms/array', ['exports', '@ember-data/serializer/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      console.log('serialized:', serialized);
      return Array.isArray(serialized) ? serialized : [];
    },
    serialize: function serialize(deserialized) {
      console.log('deserialized:', deserialized);
      return Array.isArray(deserialized) ? deserialized : [];
    }
  });
});