define('fti-pump-selector/components/uc-modal-results-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.isBlank(this.get('sortedResults'))) {
        this.sortedResults = [];
      }
    },


    selectedResult: Ember.computed.oneWay('selectedResultInitial'),

    // flag to determine if results being displayed include motors
    // when false only show magnet, adapter info.
    hasMotor: false,

    sortedResultsWithSelected: Ember.computed('sortedResults', 'selectedResult', function () {
      var selected = this.get('selectedResult');

      return this.get('sortedResults').map(function (result) {
        return {
          item: result,
          selected: selected && selected.get('id') === result.get('id')
        };
      });
    }),

    actions: {
      select: function select(result) {
        this.set('selectedResult', result);
        this.get('handleSelectedResult')(result);
      }
    }
  });
});