define('fti-pump-selector/components/pump-result-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'table-striped'],

    pumpList: Ember.computed('pumpSelected', 'results.[]', function () {
      var selected = this.get('pumpSelected');

      return this.get('results').map(function (pump) {
        return {
          isSelected: selected && pump.get('id') === selected.get('id'),
          pump: pump
        };
      });
    }),

    actions: {
      select: function select(selectedPump) {
        this.get('selectPump')(selectedPump);
      }
    }
  });
});