define('fti-pump-selector/components/sweet-alert', ['exports', 'ember-sweetalert/components/sweet-alert'], function (exports, _sweetAlert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sweetAlert.default;
    }
  });
});