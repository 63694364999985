define('fti-pump-selector/components/ember-inline-editor', ['exports', 'ember-inline-edit/components/ember-inline-editor'], function (exports, _emberInlineEditor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberInlineEditor.default;
    }
  });
});