define('fti-pump-selector/router', ['exports', 'fti-pump-selector/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('friction-loss-calculator', { path: '/friction-loss-calculator' });
    this.route('terms-of-use', { path: '/terms-of-use' });

    this.route('pump-selector', { path: '/pump-selector' });
    this.route('sp-pump-options', { path: '/pump-selector/sp-pump-options' });
    this.route('uc-pump-options', { path: '/pump-selector/uc-pump-options' });
    this.route('ac-pump-options', { path: '/pump-selector/ac-pump-options' });
    this.route('pump-selector-results', { path: '/pump-selector/results' }, function () {
      this.route('part-number', { path: '/part-number/:pump_id' });
      this.route('curve-data', { path: '/curve-data/:pump_id' });
      this.route('uc-part-number', { path: '/uc-part-number/:pump_id' });
    });
    this.route('pump-quote', { path: '/quote/:pump_id' });

    this.route('drum-pump-selector', { path: '/drum-pump-selector' });
    this.route('drum-pump-selector-results', {
      path: '/drum-pump-selector/results'
    });
    this.route('drum-pump-details', { path: '/drum-pump-selector/results/pump-detail/:pump_id' }, function () {
      this.route('motor-details', { path: '/motor-details/:motor_id' });
    });

    this.route('login');
    this.route('register');
    this.route('waiting-approval');
    this.route('my-profile');
    this.route('edit-profile');
  });

  exports.default = Router;
});