define('fti-pump-selector/controllers/uc-pump-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    pumpSelector: Ember.inject.controller(),
    pumpApplication: Ember.inject.service(),

    queryParams: ['series', 'design_flow', 'design_head', 'english_head', 'english_flow', 'motor_speed', 'frequency', 'viscosity', 'viscosity_unit', 'specific_gravity', 'fluid_id', 'temperature'],

    pageTitle: 'UC Pump Options',
    bearing: null,

    selectedFO: Ember.computed.equal('bearing', 'FO'),
    selectedFB: Ember.computed.equal('bearing', 'FB'),

    actions: {
      generatePumpResults: function generatePumpResults() {
        var params = {
          series: this.get('series'),
          english_flow: this.get('english_flow'),
          english_head: this.get('english_head'),
          design_flow: this.get('design_flow'),
          design_head: this.get('design_head'),
          motor_speed: this.get('motor_speed'),
          frequency: this.get('frequency'),
          viscosity: this.get('viscosity'),
          viscosity_unit: this.get('viscosity_unit'),
          specific_gravity: this.get('specific_gravity'),
          dri_coat_shaft: this.get('hasDriCoatShaftBushings'),
          frame_mounted: this.get('isFrameMounted'),
          bearing: this.get('bearing.value'),
          bronze_non_sparking: this.get('hasBronzeNonSparkingRing'),
          fluid_id: this.get('fluid_id'),
          temperature: this.get('temperature')
        };

        this.transitionToRoute('pump-selector-results', { queryParams: params });
      },
      selectBearing: function selectBearing(bearingType) {
        this.set('bearing', bearingType);
      }
    }
  });
});