define('fti-pump-selector/controllers/drum-pump-selector-results', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    drumPumpSelector: Ember.inject.controller(),
    features: Ember.inject.service(),

    yesHtmlSafe: '<span class="yes-return">Yes</span'.htmlSafe(),
    noHtmlSafe: '<span class="no-return">No</span>'.htmlSafe(),

    selectedResult: null,

    filteredResults: Ember.computed('model.[]', 'drumPumpSelector.{unit,immersionLength,motorType,fluid,temperature,specificGravity,viscosity,hazardousLocation,flammable,atexRequired,fdaCompliant,batteryRequired}', function () {
      var results = this.get('model');
      if (Ember.isNone(results)) {
        return;
      }

      var fResults = results.filter(function (item) {
        return item;
      });

      // filter by chemical compatibility
      var fluid = this.get('drumPumpSelector.fluid');
      fResults = results.filter(function (item) {
        return item.get('compatibleChemicals').any(function (chemical) {
          return chemical === fluid;
        });
      });

      // filter by immersion length
      var desiredImmersionLength = this.get('drumPumpSelector.immersionLength.value');
      if (!Ember.isNone(desiredImmersionLength)) {
        fResults = fResults.filter(function (item) {
          return item.get('pumpLength') === desiredImmersionLength;
        });
      }

      if (this.get('features').isEnabled('enableExtraDrumPumpFilters')) {
        var operatingTemperature = this.get('drumPumpSelector.chemTempValue');
        fResults = fResults.filter(function (item) {
          return item.get('minTemperature') <= operatingTemperature && item.get('maxTemperature') >= operatingTemperature;
        });
      }

      if (this.get('features').isEnabled('enableExtraDrumPumpFilters')) {
        var operatingSpecificGravity = this.get('drumPumpSelector.specificGravity');
        fResults = fResults.filter(function (item) {
          return item.get('maxSpecificGravity') >= operatingSpecificGravity;
        });
      }

      var hazardousAreaSafe = this.get('drumPumpSelector.hazardousLocation') || this.get('drumPumpSelector.flammable');
      if (hazardousAreaSafe) {
        fResults = fResults.filter(function (item) {
          return item.get('isHazardousAreaSafe') === true;
        });
      }

      var isAtexRequired = this.get('drumPumpSelector.atexRequired');
      if (isAtexRequired) {
        fResults = fResults.filter(function (item) {
          return item.get('isAtexPump') === true;
        });
      }

      var isFDACompliantRequired = this.get('drumPumpSelector.fdaCompliant');
      if (isFDACompliantRequired) {
        fResults = fResults.filter(function (item) {
          return item.get('isSanitary') === true;
        });
      }

      var batteryRequired = this.get('drumPumpSelector.batteryRequired');
      if (batteryRequired) {
        fResults = fResults.filter(function (item) {
          return item.get('hasBatteryMotors');
        });
      }

      // // Needs to change property checked based on selected motor.
      // let operatingViscosity = this.get('drumPumpSelector.viscosity');
      // fResults = fResults.filter((item) => {
      //   return item.get('maxViscosityElecMotor') >= operatingViscosity;
      // });

      // Motor selection filtering
      var motorSelection = this.get('drumPumpSelector.motorType.value');
      if (motorSelection) {
        fResults = fResults.filter(function (item) {
          var selectionString = ('has-' + motorSelection + '-motors').camelize();
          return item.get(selectionString);
        });
      }

      var sortedResults = fResults.sort(function (a, b) {
        return a.get('value') - b.get('value');
      });

      // return fResults.slice(0, 10);
      return sortedResults;
    }),

    actions: {
      goToPumpDetails: function goToPumpDetails() {
        if (!Ember.isNone(this.get('selectedResult'))) {
          this.transitionToRoute('drum-pump-details', this.get('selectedResult'));
        } else {
          (0, _emberSweetalert.default)({
            title: 'Select a row',
            text: "You must first select pump by clicking on it's row.",
            type: 'warning',
            confirmButtonText: 'Try again'
          });
        }
      }
    }
  });
});