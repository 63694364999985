define('fti-pump-selector/controllers/pump-quote', ['exports', 'ember-data', 'ember-moment/computeds/moment', 'ember-moment/computeds/format', 'ember-moment/computeds/locale', 'fti-pump-selector/config/environment', 'fti-pump-selector/models/pump-calculations'], function (exports, _emberData, _moment, _format, _locale, _environment, _pumpCalculations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);
      this.todaysDate = new Date();
    },


    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    pumpApplication: Ember.inject.service(),
    maxPressures: Ember.inject.service(),

    pageTitle: 'Pump Quote',

    discountPercent: 0,
    markupPercent: 0,
    displayPricing: true,
    displayDistributorInfo: true,
    displayQuoteSettings: false,

    ftiUserHeaderLogoUrl: Ember.computed('config', function () {
      return _environment.default.APP.staticEndpoint + '/images/finishthompson_footer_logo.png';
    }),
    ftiAnonHeaderLogoUrl: Ember.computed('config', function () {
      return _environment.default.APP.staticEndpoint + '/images/finishthompson_header_logo.png';
    }),

    fluid: Ember.computed.alias('pumpApplication.fluid'),
    temperature: Ember.computed.alias('pumpApplication.temperature'),
    temperature_unit: Ember.computed.alias('pumpApplication.temperature_unit'),
    design_head: Ember.computed.alias('pumpApplication.design_head'),
    design_flow: Ember.computed.alias('pumpApplication.design_flow'),
    flowUnit: Ember.computed.alias('pumpApplication.flow_unit'),
    headUnit: Ember.computed.alias('pumpApplication.head_unit'),
    powerUnit: Ember.computed.alias('pumpApplication.power_unit'),
    pressureUnit: Ember.computed.alias('pumpApplication.pressure_unit'),
    specificGravity: Ember.computed.alias('pumpApplication.specific_gravity'),
    viscosity: Ember.computed.alias('pumpApplication.viscosity'),
    viscosityUnit: Ember.computed.alias('pumpApplication.viscosity_unit.value'),
    pumpCalcs: Ember.computed.alias('model.pump.pumpCalculations'),

    maximumImpellerPumpCalcs: Ember.computed('model.pump.maximumImpeller.id', function () {
      return _pumpCalculations.default.create({
        pump: this.get('model.pump.maximumImpeller'),
        flowUnit: this.get('flowUnit'),
        headUnit: this.get('headUnit'),
        powerUnit: this.get('powerUnit'),
        pressureUnit: this.get('pressureUnit'),
        viscosityUnit: this.get('viscosityUnit'),
        viscosity: Number(this.get('viscosity')),
        designFlow: Number(this.get('design_flow')),
        specificGravity: Number(this.get('specificGravity'))
      });
    }),
    maximumImpellerNolPower: Ember.computed('maximumImpellerPumpCalcs', function () {
      if (!Ember.isNone(this.get('maximumImpellerPumpCalcs'))) {
        return this.get('maximumImpellerPumpCalcs.nol_power');
      }
    }),
    maximumPressure: Ember.computed('model.pump.id', function () {
      return this.get('maxPressures').findMaxPressure('model.pump.modelNumber');
    }),

    partNumber: Ember.computed.alias('model.partNumber'),

    currentDate: (0, _format.default)((0, _locale.default)((0, _moment.default)('todaysDate'), 'moment.locale'), 'LL'),

    pumpBaseQuery: Ember.computed('model.pumpRoot', function () {
      var root = this.get('model.pumpRoot');
      return _emberData.default.PromiseObject.create({
        promise: this.store.queryRecord('pump-base-price', { root: root })
      });
    }),

    pumpBase: Ember.computed('model.{pump.pumpBasePrices,pumpRoot}', 'pumpBaseQuery.content', function () {
      return this.get('pumpBaseQuery.content');
      // if (!Ember.isEmpty(this.get('model.pumpBasePrices'))) {
      //   return this.get('model.pumpBasePrices').findBy('root', this.get('pumpRoot'));
      // }
    }),

    pumpBasePrice: Ember.computed('pumpBase', function () {
      console.log('TTTTTTTTTTTTTTTTTTT');
      console.log('Pump Base:', this.get('pumpBase'));
      console.log('TTTTTTTTTTTTTTTTTTT');
      if (!Ember.isNone(this.get('pumpBase'))) {
        return this.get('pumpBase.price');
      }
    }),

    motorSafetyFeatureAlias: Ember.computed.alias('model.motorSafetyFeature'),
    vaporSealMaterialAlias: Ember.computed.alias('model.vaporSealMaterial'),
    housingMaterialAlias: Ember.computed.alias('model.housingMaterial'),
    bushingMaterialAlias: Ember.computed.alias('model.bushingMaterial'),
    shaftMaterialAlias: Ember.computed.alias('model.shaftMaterial'),
    oringMaterialAlias: Ember.computed.alias('model.oringMaterial'),
    connectionAlias: Ember.computed.alias('model.connection'),
    magnetAlias: Ember.computed.alias('model.magnet'),
    baseplateAlias: Ember.computed.alias('model.baseplate'),
    titaniumAlias: Ember.computed.alias('model.titanium'),
    motorAlias: Ember.computed.alias('model.motor'),
    motorAdapterAlias: Ember.computed.alias('model.motorAdapter'),
    clampRingAlias: Ember.computed.alias('model.clampRing'),

    accessoriesPrice: Ember.computed('pumpBase', 'motorSafetyFeatureAlias', 'vaporSealMaterialAlias', 'bushingMaterialAlias', 'shaftMaterialAlias', 'oringMaterialAlias', 'connectionAlias', 'titaniumAlias', 'clampRingAlias', function () {
      var price = 0.0;
      console.log('TTTTTTTTTTTTTTTTTT');
      // console.log(this.get('pumpBase.pumpAdditives').value)
      // console.log('Pump Base:', this.get('pumpBase').getProperties('root', 'listPrice', 'exportPrice'))
      console.log('Model:', this.get('model'));
      // console.log('Pump Base:', JSON.stringify(this.get('pumpBase.pumpAdditives').))
      console.log('Motor Safety Feature Alias:', this.get('motorSafetyFeatureAlias.dash_number'));
      console.log('Vapor Seal Material Alias:', this.get('vaporSealMaterialAlias.dash_number'));
      console.log('Bushing Material Alias:', this.get('bushingMaterialAlias.dash_number'));
      console.log('Shaft Material Alias:', this.get('shaftMaterialAlias.dash_number'));
      console.log('Oring Material Alias:', this.get('oringMaterialAlias.dash_number'));
      console.log('Connection Alias:', this.get('connectionAlias.dash_number'));
      console.log('Titanium Alias:', this.get('titaniumAlias'));
      console.log('Clamp Ring Alias:', this.get('clampRingAlias.dash_number'));
      console.log('TTTTTTTTTTTTTTTTTT');
      if (!Ember.isNone(this.get('pumpBase'))) {
        if (this.get('motorSafetyFeatureAlias') !== null) {
          var motorSafetyDash = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('motorSafetyFeatureAlias.dash_number'));

          if (!Ember.isBlank(motorSafetyDash)) {
            price += motorSafetyDash.get('price');
          }
        }

        if (this.get('vaporSealMaterialAlias') !== null) {
          var vaporSeal = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('vaporSealMaterialAlias.dash_number'));

          if (!Ember.isBlank(vaporSeal)) {
            price += vaporSeal.get('price');
          }
        }

        // Currently not needed as it's part of the root
        // if @get('housingMaterialAlias') != null
        //   housingMaterial = @get('pumpBase.pumpAdditives').findBy('dashNumber', @get('housingMaterialAlias.dash_number'))
        //   if !isBlank(housingMaterial)
        //     price += housingMaterial.get('price')

        if (this.get('bushingMaterialAlias') !== null) {
          var bushingMaterial = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('bushingMaterialAlias.dash_number'));

          if (!Ember.isBlank(bushingMaterial)) {
            price += bushingMaterial.get('price');
          }
        }

        if (this.get('shaftMaterialAlias') !== null) {
          var shaftMaterial = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('shaftMaterialAlias.dash_number'));

          if (!Ember.isBlank(shaftMaterial)) {
            price += shaftMaterial.get('price');
          }
        }

        if (this.get('oringMaterialAlias') !== null) {
          var oringMaterial = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('oringMaterialAlias.dash_number'));

          if (!Ember.isBlank(oringMaterial)) {
            price += oringMaterial.get('price');
          }
        }

        if (this.get('connectionAlias') !== null) {
          var connection = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('connectionAlias.dash_number'));

          if (!Ember.isBlank(connection)) {
            price += connection.get('price');
          }
        }

        if (this.get('clampRingAlias') !== null) {
          var clampRing = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('clampRingAlias.dash_number'));

          if (!Ember.isBlank(clampRing)) {
            price += clampRing.get('price');
          }
        }

        // if @get('motorMagnetAlias') != null
        //   motorMagnet = @get('pumpBase.pumpAdditives').findBy('dashNumber', @get('motorMagnetAlias.dash_number'))
        //   if !isBlank(motorMagnet)
        //     price += motorMagnet.get('price')

        if (this.get('titaniumAlias')) {
          var titanium = this.get('pumpBase.pumpAdditives').findBy('dashNumber', 'TI');

          if (!Ember.isBlank(titanium)) {
            price += titanium.get('price');
          }
        }
      }

      return price;
    }),

    baseplatePrice: Ember.computed('pumpBase.pumpAdditives', 'baseplaceAlias', function () {
      if (!Ember.isBlank(this.get('pumpBase.pumpAdditives'))) {
        var baseplate = this.get('pumpBase.pumpAdditives').findBy('dashNumber', this.get('baseplateAlias.dash_number'));

        if (!Ember.isBlank(baseplate)) {
          return baseplate.get('price');
        }
      }
    }),

    motorPrice: Ember.computed(function () {
      if (this.get('motorAlias.price')) {
        return this.get('motorAlias.price');
      }

      return 0;
    }),

    pumpTotalPrice: Ember.computed('pumpBasePrice', 'accessoriesPrice', function () {
      console.log('TTTTTTTTTTTTTTTTTT');
      console.log('Pump Base Price', this.get('pumpBasePrice'));
      console.log('Accessories Price', this.get('accessoriesPrice'));
      console.log('Motor Price', this.get('motorPrice'));
      console.log('TTTTTTTTTTTTTTTTTT');
      return this.get('pumpBasePrice') + this.get('accessoriesPrice') + this.get('motorPrice');
    }),

    adjustedPrice: Ember.computed('pumpTotalPrice', 'discountPercent', 'markupPercent', function () {
      var currentPrice = this.get('pumpTotalPrice');

      if (this.get('discountPercent') > 0 || this.get('markupPercent') > 0) {
        currentPrice -= this.get('discountPercent') / 100 * currentPrice;
        currentPrice += this.get('markupPercent') / 100 * currentPrice;
      }

      return currentPrice;
    }),

    quoteEmailHREF: Ember.computed('pumpBasePrice', 'accessoriesPrice', 'pumpTotalPrice', 'discountPercent', 'markupPercent', function () {
      var string = '';

      string += 'Date: ' + this.get('currentDate') + '\n';
      string += 'Part Number ' + this.get('partNumber') + '\n';
      string += '\n\nMaterials of Construction\n';
      string += 'Housing: ' + this.get('housingMaterialAlias.name') + '\n';
      string += 'O-ring: ' + this.get('oringMaterialAlias.name') + '\n';
      string += 'Bushing: ' + this.get('bushingMaterialAlias.name') + '\n';
      string += 'Shaft: ' + this.get('shaftMaterialAlias.name') + '\n';
      string += 'Motor Adapter: ' + this.get('motorAdapterAlias.name') + '\n';
      string += 'Connection: ' + this.get('connectionAlias.name') + '\n';
      string += 'Magnet Set: ' + this.get('magnetAlias.description') + '\n';
      string += 'Suction: ' + this.get('model.pump.suctionLength') + ' Inch\n';
      string += 'Discharge: ' + this.get('model.pump.suctionLength') + ' Inch\n';
      string += '\n\nOperating Conditions\n';
      string += 'Design Flow: ' + this.get('design_flow') + ' ' + this.get('flowUnit') + '\n';
      string += 'Design Head: ' + this.get('design_head') + ' ' + this.get('headUnit') + '\n';
      string += 'Fluid: ' + this.get('fluid.name') + '\n';
      string += 'Temperature: ' + this.get('temperature') + ' ' + this.get('temperature_unit') + '\n';
      string += 'Specific Gravity: ' + this.get('specificGravity') + '\n';
      string += 'Viscosity: ' + this.get('viscosity') + ' ' + this.get('viscosityUnit') + '\n';
      string += 'Maximum Pressure: ' + this.get('maximumPressure') + ' PSI\n';
      string += 'Minimum Flow: ' + this.get('model.pump.minFlow') + ' ' + this.get('flowUnit') + '\n';
      string += 'Speed: ' + this.get('model.pump.speed') + ' RPM\n';
      string += '\n\nPerformance\n';
      if (this.get('model.pump.closedImpeller')) {
        string += 'Impeller Type: CLOSED\n';
      } else {
        string += 'Impeller Type: OPEN\n';
      }
      if (this.get('model.pump.minimumImpeller.diameter')) {
        string += 'Minimum Impeller Diameter: ' + this.get('model.pump.minimumImpeller.diameter') + ' Inch\n';
      }
      string += 'Design Impeller Diameter: ' + this.get('model.pump.diameter') + ' Inch\n';
      string += 'Power @ Design Flow: ' + this.get('pumpCalcs.power') + ' ' + this.get('powerUnit') + '\n';
      string += 'Design Impeller NOL Power: ' + this.get('pumpCalcs.nol_power') + ' ' + this.get('powerUnit') + '\n';
      if (this.get('model.pump.maximumImpeller.diameter')) {
        string += 'Maximum Impeller Diameter: ' + this.get('model.pump.maximumImpeller.diameter') + ' Inch\n';
      }
      if (this.get('model.pump.maximumImpeller')) {
        string += 'Maximum Impeller NOL Power: ' + this.get('maximumImpellerNolPower') + ' ' + this.get('powerUnit') + '\n';
      }
      string += 'Head @ Design Flow: ' + this.get('pumpCalcs.head') + ' ' + this.get('headUnit') + '\n';
      string += 'Efficiency @ Design Flow: ' + this.get('pumpCalcs.efficiency') + ' %\n';
      string += 'NPSHr @ Design Flow: ' + this.get('pumpCalcs.net_positive_suction_head') + ' ' + this.get('headUnit') + '\n';
      string += 'Shut Off Head: ' + this.get('pumpCalcs.shutoffHead') + ' FT\n';
      string += 'Shut Off Pressure: ' + this.get('pumpCalcs.shutoffPressure') + ' PSI\n';
      string += 'BEP: ' + this.get('model.pump.bestEfficiency').toFixed(2) + ' %\n';

      return 'mailto:?subject=Pump Quote&body=' + encodeURIComponent(string);
    }),

    // Comment out for now -- possibly not needed
    // summaryText: alias('model.summaryText'),

    discountPercentChanged: Ember.observer('discountPercent', function () {
      if (this.get('discountPercent') > 0) {
        this.set('markupPercent', 0);
      }
    }),

    markupPercentChanged: Ember.observer('markupPercent', function () {
      if (this.get('markupPercent') > 0) {
        this.set('discountPercent', 0);
      }
    }),

    showCustomDistributorInfo: Ember.computed('displayDistributorInfo', 'session.isAuthenticated', function () {
      return this.get('displayDistributorInfo') && this.get('session.isAuthenticated');
    }),

    actions: {
      backToPartNumber: function backToPartNumber() {
        if (!Ember.isBlank(this.get('model.pump'))) {
          this.transitionToRoute('pump-selector-results.part-number', this.get('model.pump'));
        } else {
          this.transitionToRoute('pump-selector-results');
        }
      },
      printQuote: function printQuote() {
        window.print();
      },
      printSlimQuote: function printSlimQuote() {
        var curveDataView = Ember.$('.pump-quote__landscape-curves');
        var curveData = curveDataView.clone();
        Ember.$('<div/>').addClass('curve-data-print').html(curveData).appendTo('body');

        Ember.$('.curve-data-print').printArea({
          mode: 'iframe',
          popClose: true,
          extraHead: '<style type="text/css" media="print"> @page { size: landscape; } </style>'
        });

        Ember.$('.curve-data-print').remove();
      },
      toggleDownloadModal: function toggleDownloadModal() {
        console.log('TTTTTTTTTTTTTTTTTT');
        console.log(this.get('model.pump.pumpDownloads'));
        console.log(this.get('model.pump'));
        console.log('TTTTTTTTTTTTTTTTTT');
        this.toggleProperty('isShowingDownloadModal');
      },
      toggleQuoteSettings: function toggleQuoteSettings() {
        this.toggleProperty('displayQuoteSettings');
      },
      onSaveQuoteNo: function onSaveQuoteNo(value) {
        this.set('quoteNo', value);
      },
      onSaveCustomerName: function onSaveCustomerName(value) {
        this.set('customerName', value);
      },
      onSaveCustomerRefNo: function onSaveCustomerRefNo(value) {
        this.set('customerRefNo', value);
      },
      setDisplayDistributorInfo: function setDisplayDistributorInfo(value) {
        this.set('displayDistributorInfo', value);
      },
      setDisplayPricing: function setDisplayPricing(value) {
        this.set('displayPricing', value);
      }
    }
  });
});