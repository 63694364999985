define('fti-pump-selector/models/simple-base', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    dash_number: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    label: Ember.computed('name', 'dash_number', function () {
      if (!Ember.isBlank(this.get('dash_number')) && !Ember.isBlank(this.get('name'))) {
        return this.get('dash_number') + ' - ' + this.get('name');
      } else if (!Ember.isBlank(this.get('dash_number'))) {
        return this.get('dash_number');
      } else {
        return this.get('name');
      }
    }),
    contactFactory: (0, _attr.default)('boolean')
  });
});