define('fti-pump-selector/adapters/application', ['exports', 'ember-data', 'fti-pump-selector/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-inflector'], function (exports, _emberData, _environment, _dataAdapterMixin, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var underscore = Ember.String.underscore;
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service('session'),

    host: _environment.default.APP.dataEndPoint,
    namespace: 'api/v1',

    authorize: function authorize(xhr) {
      var _get = this.get('session.data.authenticated'),
          access_token = _get.access_token;

      xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },


    // allows the multiword paths in urls to be underscored
    pathForType: function pathForType(type) {
      var underscored = underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    }
  });
});