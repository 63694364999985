define('fti-pump-selector/components/power-curve-chart', ['exports', 'd3-selection', 'd3-scale', 'd3-axis', 'd3-shape', 'lodash'], function (exports, _d3Selection, _d3Scale, _d3Axis, _d3Shape, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: 'curvechart curvechart--power',

    portraitSvgWidth: 700,
    portraitSvgHeight: 100,
    portraitAxisFontSize: '14px',

    isLandscape: false,
    landscapeSvgWidth: 700,
    landscapeSvgHeight: 80,
    landscapeAxisFontSize: '11px',

    viewBox: '0 0 400 400',
    attributeBindings: ['width', 'height', 'viewBox'],

    effectiveSvgWidth: Ember.computed('svgWidth', function () {
      return this.get('isLandscape') ? this.get('landscapeSvgWidth') : this.get('portraitSvgWidth');
    }),

    effectiveSvgHeight: Ember.computed('svgHeight', function () {
      return this.get('isLandscape') ? this.get('landscapeSvgHeight') : this.get('portraitSvgHeight');
    }),

    effectiveAxisFontSize: Ember.computed('isLandscape', function () {
      return this.get('isLandscape') ? this.get('landscapeAxisFontSize') : this.get('portraitAxisFontSize');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      // Schedule a call to our `createChart` method on Ember's "render" queue, which will
      // happen after the component has been placed in the DOM, and subsequently
      // each time data is changed.
      Ember.run.scheduleOnce('render', this, this.createChart);
    },
    createChart: function createChart() {
      var plot = (0, _d3Selection.select)(this.element);
      var data = Ember.get(this, 'data');
      var maximumImpHeadData = Ember.get(this, 'maximumImpellerData');
      var effectiveSvgWidth = Ember.get(this, 'effectiveSvgWidth');
      var effectiveSvgHeight = Ember.get(this, 'effectiveSvgHeight');
      var margin = { top: 10, bottom: 10, left: 40, right: 40 };

      this.set('viewBox', '0 0 ' + this.get('effectiveSvgWidth') + ' ' + this.get('effectiveSvgHeight'));

      plot.selectAll('*').remove();

      var powerXMax = _lodash.default.maxBy(data, 'x').x + 40;
      var maximumImpHeadXMax = _lodash.default.get(_lodash.default.maxBy(maximumImpHeadData, 'x'), 'x', 0) + 10;
      var xMax = _lodash.default.max([powerXMax, maximumImpHeadXMax]);
      var xScale = (0, _d3Scale.scaleLinear)().domain([0, xMax]).range([margin.left, effectiveSvgWidth - margin.right]);
      var xAxis = (0, _d3Axis.axisBottom)(xScale).ticks(16).tickSizeInner(-(effectiveSvgHeight - margin.top - margin.bottom)).tickSizeOuter(-(effectiveSvgHeight - margin.top - margin.bottom));
      plot.append('g').attr('class', 'axis axis--x').attr('transform', 'translate(0, ' + (effectiveSvgHeight - margin.bottom) + ')').call(xAxis);

      var yScale = (0, _d3Scale.scaleLinear)().domain([0, _lodash.default.maxBy(data, 'y').y + 1]).range([effectiveSvgHeight - margin.bottom, margin.top]);
      var yAxis = (0, _d3Axis.axisLeft)(yScale).ticks(4).tickSizeInner(-(effectiveSvgWidth - margin.left - margin.right)).tickSizeOuter(-(effectiveSvgWidth - margin.left - margin.right));
      plot.append('g').attr('class', 'axis axis--y').attr('transform', 'translate(' + margin.left + ', 0)').call(yAxis);

      var lineGen = (0, _d3Shape.line)().x(function (d) {
        return xScale(d.x);
      }).y(function (d) {
        return yScale(d.y);
      }).curve(_d3Shape.curveBasis);

      plot.append('svg:path').attr('d', lineGen(this.get('data'))).attr('stroke', '#369ead').attr('stroke-width', 2).attr('fill', 'none');

      var lastPoint = _lodash.default.last(this.get('data'));
      plot.append('text').attr('class', 'curvechart__linehelper').attr('text-anchor', 'middle').attr('x', xScale(lastPoint.x) + 25).attr('y', yScale(lastPoint.y)).text('Power');

      if (!Ember.isBlank(this.get('yAxisTitle'))) {
        plot.append('text').attr('transform', 'rotate(-90)').attr('y', 0).attr('x', 0 - effectiveSvgHeight / 2).attr('dy', '1em').style('text-anchor', 'middle').style('font-size', this.get('effectiveAxisFontSize')).text(this.get('yAxisTitle'));
      }
    }
  });
});