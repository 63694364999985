define('fti-pump-selector/routes/friction-loss-calculator', ['exports', 'fti-pump-selector/routes/fti-route'], function (exports, _ftiRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ftiRoute.default.extend({
    setupController: function setupController(controller) {
      controller.set('pipeFittings', this.store.findAll('pipe-fitting'));
    }
  });
});