define('fti-pump-selector/routes/uc-pump-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    appState: Ember.inject.service(),
    pumpApplication: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('appState.validFilters')) {
        this.transitionTo('pump-selector');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (this.get('pumpApplication.isFlammable')) {
        controller.set('hasBronzeNonSparkingRing', true);
      }
    }
  });
});