define('fti-pump-selector/models/baseplate', ['exports', 'fti-pump-selector/models/simple-base', 'ember-data/attr', 'ember-data/relationships'], function (exports, _simpleBase, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _simpleBase.default.extend({
    part_number: (0, _attr.default)('string'),

    compatiableHousingMaterials: (0, _relationships.hasMany)('baseplate')
  });
});