define('fti-pump-selector/components/one-way-zip-code-mask', ['exports', 'ember-inputmask/components/one-way-zip-code-mask'], function (exports, _oneWayZipCodeMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _oneWayZipCodeMask.default;
    }
  });
});