define('fti-pump-selector/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appState: Ember.inject.service(),
    pageTitle: 'Pump Selector'
  });
});