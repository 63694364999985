define('fti-pump-selector/initializers/add-polyfills', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AddPolyfillsInitializer = {
    name: 'add-polyfills',
    initialize: function initialize() {
      if (!Math.log10) {
        return Math.log10 = function (t) {
          return Math.log(t) / Math.LN10;
        };
      }
    }
  };

  exports.default = AddPolyfillsInitializer;
});