define('fti-pump-selector/models/motor-adapter', ['exports', 'fti-pump-selector/models/simple-base', 'ember-data/attr', 'ember-data/relationships'], function (exports, _simpleBase, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _simpleBase.default.extend({
    maxHp: (0, _attr.default)('number'),
    adapterType: (0, _attr.default)('string'),
    selectionRank: (0, _attr.default)('number'),
    compatibleMotors: (0, _relationships.hasMany)('motor')
  });
});