define('fti-pump-selector/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),

    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get('sessionAccount').loadCurrentUser().catch(function (err) {
        console.error(err); // eslint-disable-line
        _this.get('session').invalidate();
      });
    },


    actions: {
      showModal: function showModal(name, content) {
        this.controllerFor(name).set('content', content);
        this.render(name, {
          into: 'application',
          outlet: 'modal'
        });
      },
      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});