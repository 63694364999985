define('fti-pump-selector/models/pump-base-price', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    root: (0, _attr.default)('string'),
    listPrice: (0, _attr.default)('number'),
    exportPrice: (0, _attr.default)('number'),
    pumpAdditives: (0, _relationships.hasMany)('pump-additive'),
    price: Ember.computed('listPrice', 'exportPrice', function () {
      if (this.get('exportPrice')) {
        return this.get('exportPrice');
      }
      if (this.get('listPrice')) {
        return this.get('listPrice');
      }
    })
  });
});