define('fti-pump-selector/app', ['exports', 'fti-pump-selector/resolver', 'ember-load-initializers', 'fti-pump-selector/config/environment', 'fti-pump-selector/reopen/ember-number'], function (exports, _resolver, _emberLoadInitializers, _environment, _emberNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // eslint-disable-line

  var App = void 0;

  // Custom Extentions
  /* global Raven  */


  console.log('Enable Sentry Logging: ', _environment.default.APP.ENABLE_SENTRY_LOGGING); // eslint-disable-line

  if (_environment.default.APP.ENABLE_SENTRY_LOGGING) {
    Raven.config('https://43d94f6b70b24575adb86d3176934a8d@sentry.io/149258').addPlugin(Raven.Plugins.Ember).install();

    Ember.onerror = function (error) {
      if (error.message && error.message !== 'TransitionAborted') {
        Raven.captureException(error);
      }
    };

    Ember.RSVP.on('error', function (error) {
      if (error.message && error.message !== 'TransitionAborted') {
        Raven.captureException(error);
      }
    });

    Ember.Logger.error = function (message, cause, stack) {
      Raven.captureException(new Error(message), {
        extra: {
          cause: cause,
          stack: stack
        }
      });
    };
  }

  window.App = App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    rootElement: '#pump-selector-container'
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});