define('fti-pump-selector/models/pump-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function notBlank(dependentObject, dependentKeyOne, dependentKeyTwo, prefix, suffix) {
    return Ember.computed(dependentObject + '.' + dependentKeyOne, dependentObject + '.' + dependentKeyTwo, function () {
      var desiredKey = dependentObject + '.' + dependentKeyOne;
      var fallbackKey = dependentObject + '.' + dependentKeyTwo;
      if (!Ember.isBlank(this.get(desiredKey))) {
        return '' + prefix + this.get(desiredKey) + suffix;
      } else if (!Ember.isBlank(this.get(fallbackKey))) {
        return '' + prefix + this.get(fallbackKey) + suffix;
      } else {
        return '';
      }
    });
  }

  exports.default = Ember.Object.extend({
    pump: null,
    partNumber: '',

    motorSafetyFeature: null,
    vaporSealMaterial: null,
    housingMaterial: null,
    bushingMaterial: null,
    shaftMaterial: null,
    oringMaterial: null,
    connection: null,
    magnet: null,
    baseplate: null,
    titanium: null,
    motor: null,
    clampRing: null,

    pumpRoot: Ember.computed('pump.series', function () {
      switch (this.get('pump.series')) {
        case 'DB':
        case 'SP':
          return this.get('partNumber').split('-')[0];
        // Default will handle UC currently
        default:
          return this.get('pump.modelNumber');
      }
    }),

    summaryText: Ember.computed('model.pump', 'housingMaterial', 'connection', 'oringMaterial', 'magnet', 'shaftMaterial', 'bushingMaterial', 'titanium', 'motorSafetyFeature', 'vaporSealMaterial', function () {
      var pumpSummaryString = '';
      switch (this.get('pump.series')) {
        case 'DB':
        case 'SP':
          pumpSummaryString = this.get('pump.modelNumber') + ' Pump with ' + this.get('housingMaterial.description') + '  wetted parts ';
          pumpSummaryString += (this.get('pump.closedImpeller') ? 'Closed' : 'Open') + ' Impeller, ';
          pumpSummaryString += this.get('pump.diameter') + ' inches diameter;';

          pumpSummaryString += this.get('connectionSummary');
          pumpSummaryString += this.get('oringMaterialSummary');
          pumpSummaryString += this.get('magnetSummary');
          pumpSummaryString += this.get('shaftMaterialSummary');
          pumpSummaryString += this.get('bushingMaterialSummary');
          if (this.get('titanium')) {
            pumpSummaryString += ' Titanium hardware;';
          }
          pumpSummaryString += this.get('motorSafetyFeatureSummary');
          pumpSummaryString += this.get('vaporSealSummary');
          pumpSummaryString += this.get('clampRingSummary');

          break;
        case 'UC':
          pumpSummaryString = this.get('pump.modelNumber') + ' Pump';
          break;
        default:
          pumpSummaryString = this.get('pump.modelNumber') + ' Pump';
          break;
      }

      return pumpSummaryString;
    }),

    connectionSummary: notBlank('connection', 'description', 'name', ' ', ' Connection;'),
    oringMaterialSummary: notBlank('oringMaterial', 'description', 'name', ' ', ' Orings;'),
    magnetSummary: notBlank('magnet', 'description', 'name', ' ', ';'),
    shaftMaterialSummary: notBlank('shaftMaterial', 'description', 'name', ' ', ' Shaft;'),
    bushingMaterialSummary: notBlank('bushingMaterial', 'description', 'name', ' ', ' Bushing;'),
    motorSafetyFeatureSummary: notBlank('motorSafetyFeature', 'description', 'name', ' ', ';'),
    vaporSealSummary: notBlank('vaporSealMaterial', 'description', 'name', ' ', ' vapor seal kit;'),
    clampRingSummary: notBlank('clampRing', 'description', 'name', ' ', ';')
  });
});