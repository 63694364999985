define('fti-pump-selector/models/pipe-fitting', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    k1: (0, _attr.default)('number'),
    ki: (0, _attr.default)('number'),
    kd: (0, _attr.default)('number')
  });
});