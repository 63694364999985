define('fti-pump-selector/models/selection-facets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    pump_type: null,
    frequency: null,
    fluid: null,
    pump_seri: null,
    motor_speed: null,
    motor_size: null,
    motor_adapters: null,
    motor_phases: null,
    motor_types: null
  });
});