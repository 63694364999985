define('fti-pump-selector/routes/edit-profile', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    model: function model() {
      return this.get('store').find('user', 'me');
    },


    actions: {
      save: function save(model) {
        var _this = this;

        model.save().then(function () {
          _this.transitionTo('my-profile');
        }).catch(function (err) {
          console.error(err); // eslint-disable-line
        });
      },
      cancel: function cancel(model) {
        model.rollbackAttributes();
        this.transitionTo('my-profile');
      }
    }
  });
});