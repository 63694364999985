define('fti-pump-selector/models/impeller-dash', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    root: (0, _attr.default)('string'),
    dash: (0, _attr.default)('string'),
    diameter: (0, _attr.default)('number'),
    closedImpeller: (0, _attr.default)('boolean')
  });
});