define('fti-pump-selector/utils/conversions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Converter = function () {
    function Converter() {
      _classCallCheck(this, Converter);
    }

    _createClass(Converter, [{
      key: 'convertTo',
      value: function convertTo(toUnit) {
        if (this.validUnits.indexOf(toUnit) < 0) {
          throw new Error('Invalid Unit Specified: ' + toUnit);
        }

        return this.conversionTable[this.unit][toUnit].call(this, this.value);
      }
    }, {
      key: 'unit',
      get: function get() {
        return this._unit;
      },
      set: function set(value) {
        this._unit = value;
      }
    }, {
      key: 'value',
      get: function get() {
        return this._value;
      },
      set: function set(value) {
        var tempVal = Number(value);

        if (isNaN(tempVal)) {
          throw new Error('Unable to convert input value to number.');
        }

        this._value = tempVal;
      }
    }]);

    return Converter;
  }();

  var Flow = function (_Converter) {
    _inherits(Flow, _Converter);

    function Flow(unit, value) {
      _classCallCheck(this, Flow);

      var _this = _possibleConstructorReturn(this, (Flow.__proto__ || Object.getPrototypeOf(Flow)).call(this, unit, value));

      _this.validUnits = ['GPM', 'CMPH', 'LPM'];
      _this.conversionTable = {
        GPM: {
          GPM: function GPM(value) {
            return value;
          },
          CMPH: function CMPH(value) {
            return value * 0.227124707;
          },
          LPM: function LPM(value) {
            return value * 3.78541178;
          }
        },
        LPM: {
          LPM: function LPM(value) {
            return value;
          },
          CMPH: function CMPH(value) {
            return value * 0.06;
          },
          GPM: function GPM(value) {
            return value * 0.264172052;
          }
        },
        CMPH: {
          CMPH: function CMPH(value) {
            return value;
          },
          GPM: function GPM(value) {
            return value * 4.40286754;
          },
          LPM: function LPM(value) {
            return value * 16.6666667;
          }
        }
      };

      if (Ember.isEmpty(unit) || Ember.isEmpty(value)) {
        throw new Error('Invalid arguments given.');
      }
      if (_this.validUnits.indexOf(unit) < 0) {
        throw new Error('Invalid Unit Specified: ' + unit);
      }

      _this.unit = unit;
      _this.value = value;
      return _this;
    }

    _createClass(Flow, [{
      key: 'specificGravity',
      get: function get() {
        return this._specificGravity;
      },
      set: function set(value) {
        this._specificGravity = value;
      }
    }]);

    return Flow;
  }(Converter);

  var Head = function (_Converter2) {
    _inherits(Head, _Converter2);

    function Head(unit, value, sg) {
      _classCallCheck(this, Head);

      var _this2 = _possibleConstructorReturn(this, (Head.__proto__ || Object.getPrototypeOf(Head)).call(this, unit, value));

      _this2.validUnits = ['FT', 'M', 'PSI', 'KPA', 'BAR'];
      _this2.conversionTable = {
        FT: {
          FT: function FT() {
            return 1 * this.value;
          },
          M: function M() {
            return 0.3048 * this.value;
          },
          PSI: function PSI() {
            return 0.4335 * this.value * this.specificGravity;
          },
          KPA: function KPA() {
            return 2.9889 * this.value * this.specificGravity;
          },
          BAR: function BAR() {
            return 0.029889 * this.value * this.specificGravity;
          }
        },
        M: {
          FT: function FT() {
            return 3.28084 * this.value;
          }
        },
        PSI: {
          FT: function FT() {
            return this.value * 2.3068 / this.specificGravity;
          }
        },
        KPA: {
          FT: function FT() {
            return this.value * 0.33457 / this.specificGravity;
          }
        },
        BAR: {
          FT: function FT() {
            return this.value * 33.457 / this.specificGravity;
          }
        }
      };

      if (Ember.isEmpty(unit) || Ember.isEmpty(value)) {
        throw new Error('Invalid arguments given.');
      }
      if (_this2.validUnits.indexOf(unit) < 0) {
        throw new Error('Invalid unit specified: ' + unit);
      }

      _this2.unit = unit;
      _this2.value = value;
      _this2.specificGravity = sg;
      return _this2;
    }

    return Head;
  }(Converter);

  var Power = function (_Converter3) {
    _inherits(Power, _Converter3);

    function Power(unit, value) {
      _classCallCheck(this, Power);

      var _this3 = _possibleConstructorReturn(this, (Power.__proto__ || Object.getPrototypeOf(Power)).call(this, unit, value));

      _this3.validUnits = ['HP', 'KW'];
      _this3.conversionTable = {
        HP: {
          HP: function HP(value) {
            return value;
          },
          KW: function KW(value) {
            return 0.745699872 * value;
          }
        },
        KW: {
          HP: function HP(value) {
            return value / 0.745699872;
          },
          KW: function KW(value) {
            return value;
          }
        }
      };

      if (Ember.isEmpty(unit) || Ember.isEmpty(value)) {
        throw new Error('Invalid arguments given.');
      }
      if (_this3.validUnits.indexOf(unit) < 0) {
        throw new Error('Invalid unit specified: ' + unit);
      }

      _this3.unit = unit;
      _this3.value = value;
      return _this3;
    }

    return Power;
  }(Converter);

  var Temperature = function (_Converter4) {
    _inherits(Temperature, _Converter4);

    function Temperature(unit, value) {
      _classCallCheck(this, Temperature);

      var _this4 = _possibleConstructorReturn(this, (Temperature.__proto__ || Object.getPrototypeOf(Temperature)).call(this, unit, value));

      _this4.validUnits = ['C', 'F'];
      _this4.conversionTable = {
        F: {
          F: function F(value) {
            return value * 1;
          },
          C: function C(value) {
            return (value - 32) * 5 / 9;
          }
        },
        C: {
          C: function C(value) {
            return value * 1;
          },
          F: function F(value) {
            return value * 9 / 5 + 32;
          }
        }
      };
      var safeValue = Number(value);

      if (Ember.isEmpty(unit) || Ember.isBlank(safeValue)) {
        throw new Error('Invalid arguments given.');
      }
      if (_this4.validUnits.indexOf(unit) < 0) {
        throw new Error('Invalid unit specified: ' + unit);
      }

      _this4.unit = unit;
      _this4.value = safeValue;
      return _this4;
    }

    return Temperature;
  }(Converter);

  var Length = function (_Converter5) {
    _inherits(Length, _Converter5);

    function Length(unit, value) {
      _classCallCheck(this, Length);

      var _this5 = _possibleConstructorReturn(this, (Length.__proto__ || Object.getPrototypeOf(Length)).call(this, unit, value));

      _this5.validUnits = ['FT', 'M'];
      _this5.conversionTable = {
        FT: {
          FT: function FT(value) {
            return value * 1;
          },
          M: function M(value) {
            return value * 0.3048;
          }
        },
        M: {
          M: function M(value) {
            return value * 1;
          },
          FT: function FT(value) {
            return value * 3.280839895;
          }
        }
      };

      if (Ember.isEmpty(unit) || Ember.isEmpty(value)) {
        throw new Error('Invalid arguments given.');
      }
      if (_this5.validUnits.indexOf(unit) < 0) {
        throw new Error('Invalid unit specified: ' + unit);
      }

      _this5.unit = unit;
      _this5.value = value;
      return _this5;
    }

    return Length;
  }(Converter);

  var Viscosity = function (_Converter6) {
    _inherits(Viscosity, _Converter6);

    function Viscosity(unit, value, sg) {
      _classCallCheck(this, Viscosity);

      var _this6 = _possibleConstructorReturn(this, (Viscosity.__proto__ || Object.getPrototypeOf(Viscosity)).call(this, unit, value));

      _this6.validUnits = ['SSU', 'CST', 'CP', 'centistokes', 'ssu', 'centipoise'];

      if (Ember.isEmpty(unit) || Ember.isEmpty(value)) {
        throw new Error('Invalid arguments given.');
      }
      if (_this6.validUnits.indexOf(unit) < 0) {
        throw new Error('Invalid unit specified: ' + unit);
      }

      _this6.unit = unit;
      _this6.value = Number(value);
      _this6.specificGravity = Number(sg);
      return _this6;
    }

    _createClass(Viscosity, [{
      key: 'conversionTable',
      value: function conversionTable(to_unit) {
        switch (this.unit) {
          case 'SSU':
          case 'ssu':
            switch (to_unit) {
              case 'SSU':
              case 'ssu':
                return this.value;
              case 'CST':
              case 'centistokes':
                if (this.value === 0) {
                  return 0;
                } else if (this.value <= 100) {
                  return 0.226 * this.value - 195 / this.value;
                } else {
                  return 0.22 * this.value - 135 / this.value;
                }
            }
            break;
          case 'CST':
          case 'centistokes':
            switch (to_unit) {
              case 'CST':
              case 'centistokes':
                return this.value;
            }
            break;
          case 'CP':
          case 'centipoise':
            switch (to_unit) {
              case 'CP':
              case 'centipoise':
                return this.value;
              case 'CST':
              case 'centistokes':
                return this.value / this.specificGravity;
            }
        }
        return 0;
      }
    }, {
      key: 'convertTo',
      value: function convertTo(toUnit) {
        if (this.validUnits.indexOf(toUnit) < 0) {
          throw new Error('Invalid unit specified: ' + toUnit);
        }

        return this.conversionTable(toUnit);
      }
    }]);

    return Viscosity;
  }(Converter);

  var Pressure = function (_Converter7) {
    _inherits(Pressure, _Converter7);

    function Pressure(unit, value) {
      _classCallCheck(this, Pressure);

      var _this7 = _possibleConstructorReturn(this, (Pressure.__proto__ || Object.getPrototypeOf(Pressure)).call(this, unit, value));

      _this7.validUnits = ['PSI', 'BAR', 'KPA'];
      _this7.conversionTable = {
        PSI: {
          PSI: function PSI(value) {
            return value;
          },
          BAR: function BAR(value) {
            return value / 14.5038;
          },
          KPA: function KPA(value) {
            return value * 6.89475729;
          }
        },
        BAR: {
          PSI: function PSI(value) {
            return value * 14.5038;
          },
          BAR: function BAR(value) {
            return value;
          },
          KPA: function KPA(value) {
            return value * 100;
          }
        },
        KPA: {
          PSI: function PSI(value) {
            return value / 6.89475729;
          },
          BAR: function BAR(value) {
            return value / 100;
          },
          KPA: function KPA(value) {
            return value;
          }
        }
      };

      if (Ember.isEmpty(unit) || Ember.isEmpty(value)) {
        throw new Error('Invalid arguments given.');
      }
      if (_this7.validUnits.indexOf(unit) < 0) {
        throw new Error('Invalid unit specified: ' + unit);
      }

      _this7.unit = unit;
      _this7.value = value;
      return _this7;
    }

    return Pressure;
  }(Converter);

  exports.Flow = Flow;
  exports.Head = Head;
  exports.Power = Power;
  exports.Temperature = Temperature;
  exports.Length = Length;
  exports.Viscosity = Viscosity;
  exports.Pressure = Pressure;
});