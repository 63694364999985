define('fti-pump-selector/controllers/friction-loss-calculator', ['exports', 'fti-pump-selector/controllers/base', 'fti-pump-selector/utils/conversions'], function (exports, _base, _conversions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    pageTitle: 'Friction Loss Calculator',

    _suction_losses: 0,
    _discharge_losses: 0,
    _total_losses: 0,

    diameters: Ember.A([{
      value: 0.546,
      label: '1/2 - 80'
    }, {
      value: 0.742,
      label: '3/4 - 80'
    }, {
      value: 0.957,
      label: '1 - 80'
    }, {
      value: 1.278,
      label: '1 1/4 - 80'
    }, {
      value: 1.5,
      label: '1 1/2 - 80'
    }, {
      value: 1.939,
      label: '2 - 80'
    }, {
      value: 2.323,
      label: '2 1/2 - 80'
    }, {
      value: 2.9,
      label: '3 - 80'
    }, {
      value: 3.826,
      label: '4 - 80'
    }, {
      value: 0.622,
      label: '1/2 - 40'
    }, {
      value: 0.824,
      label: '3/4 - 40'
    }, {
      value: 1.049,
      label: '1 - 40'
    }, {
      value: 1.38,
      label: '1 1/4 - 40'
    }, {
      value: 1.61,
      label: '1 1/2 - 40'
    }, {
      value: 2.067,
      label: '2 - 40'
    }, {
      value: 2.469,
      label: '2 1/2 - 40'
    }, {
      value: 3.068,
      label: '3 - 40'
    }, {
      value: 4.026,
      label: '4 - 40'
    }]),

    _cFlow: Ember.computed('flow', 'flow_unit', function () {
      var flow_value = parseFloat(this.get('flow'));
      return new _conversions.Flow(this.get('flow_unit'), flow_value);
    }),

    _cDischargeLength: Ember.computed('units', 'discharge_length', function () {
      var length = this.get('discharge_length');

      switch (this.get('units.value')) {
        case 2:
        case 3:
        case 4:
        case 5:
          return length * 3.28084;
        default:
          return length;
      }
    }),

    _cSuctionLength: Ember.computed('units', 'suction_length', function () {
      var length = this.get('suction_length');

      switch (this.get('units.value')) {
        case 2:
        case 3:
        case 4:
        case 5:
          return length * 3.28084;
        default:
          return length;
      }
    }),

    _cViscosity: Ember.computed('viscosity', 'specific_gravity', 'viscosity_unit', function () {
      var vis = parseFloat(this.get('viscosity'));

      switch (this.get('viscosity_unit.value')) {
        case 'centistokes':
          return vis * this.get('specific_gravity');
        case 'ssu':
          if (vis <= 100) {
            return (0.226 * vis - 195 / vis) * this.get('specific_gravity');
          } else {
            return (0.22 * vis - 135 / vis) * this.get('specific_gravity');
          }
        default:
          return vis;
      }
    }),

    discharge_reynolds: Ember.computed('_cFlow', 'specific_gravity', '_cViscosity', 'discharge_diameter', function () {
      return 3157 * this.get('_cFlow').convertTo('GPM') * this.get('specific_gravity') / (parseFloat(this.get('discharge_diameter.value')) * this.get('_cViscosity'));
    }),

    discharge_moody: Ember.computed('discharge_diameter', 'discharge_reynolds', function () {
      var A = void 0,
          B = void 0,
          Denom = void 0,
          discharge_dia = void 0,
          discharge_reynolds = void 0,
          eps = void 0,
          f = void 0;
      eps = 0.0018;
      A = void 0;
      B = void 0;
      f = void 0;
      Denom = 0.0;

      discharge_dia = parseFloat(this.get('discharge_diameter.value'));
      discharge_reynolds = this.get('discharge_reynolds');

      Denom = Math.pow(7 / discharge_reynolds, 0.9) + 0.27 * eps / discharge_dia;
      A = Math.pow(2.457 * Math.log(1 / Denom), 16);
      B = Math.pow(37530 / discharge_reynolds, 16);
      f = Math.pow(Math.pow(8 / discharge_reynolds, 12) + 1 / Math.pow(A + B, 1.5), 0.0833333);

      return 8 * f;
    }),

    suction_reynolds: Ember.computed('_cFlow', 'specific_gravity', '_cViscosity', 'suction_diameter', function () {
      return 3157 * this.get('_cFlow').convertTo('GPM') * this.get('specific_gravity') / (parseFloat(this.get('suction_diameter.value')) * this.get('_cViscosity'));
    }),

    suction_moody: Ember.computed('suction_diameter', 'suction_reynolds', function () {
      var A = void 0,
          B = void 0,
          Denom = void 0,
          eps = void 0,
          f = void 0,
          suction_dia = void 0,
          suction_reynolds = void 0;
      eps = 0.0018;
      A = void 0;
      B = void 0;
      f = void 0;
      Denom = 0.0;

      suction_dia = parseFloat(this.get('suction_diameter.value'));
      suction_reynolds = this.get('suction_reynolds');

      Denom = Math.pow(7 / suction_reynolds, 0.9) + 0.27 * eps / suction_dia;
      A = Math.pow(2.457 * Math.log(1 / Denom), 16);
      B = Math.pow(37530 / suction_reynolds, 16);
      f = Math.pow(Math.pow(8 / suction_reynolds, 12) + 1 / Math.pow(A + B, 1.5), 0.0833333);

      return 8 * f;
    }),

    suction_losses: Ember.computed('_suction_losses', function () {
      return (Math.round(this.get('_suction_losses') * 10) / 10).toFixed(1);
    }),

    discharge_losses: Ember.computed('_discharge_losses', function () {
      return (Math.round(this.get('_discharge_losses') * 10) / 10).toFixed(1);
    }),

    total_losses: Ember.computed('discharge_losses', 'suction_losses', function () {
      return (Math.round(parseFloat(this.get('suction_losses')) + parseFloat(this.get('discharge_losses'))) * 10 / 10).toFixed(1);
    }),

    fittingLoss: function fittingLoss(reynolds, diameter, fitting_name, amount) {
      var amt = parseInt(amount);
      if (amount > 0) {
        var fitting = this.get('pipeFittings').findProperty('name', fitting_name);
        if (!Ember.isBlank(fitting)) {
          var fitting_loss = fitting.get('k1') / reynolds + fitting.get('ki') * (1 + fitting.get('kd') / Math.pow(diameter, 0.3));
          return fitting_loss * amt;
        }
      }
      return 0;
    },
    lineLoss: function lineLoss(length, diameter, moody) {
      return moody * (length / diameter) * 12;
    },
    vsquare: function vsquare(flow, diameter) {
      return 0.002593 * Math.pow(flow, 2) / Math.pow(diameter, 4);
    },


    actions: {
      // Selection action for ember-select on [units]
      selectUnits: function selectUnits(value, option) {
        this.set('units', option);
      },

      // Selection action for ember-select on [viscosity_unit]
      selectViscosityUnit: function selectViscosityUnit(value, option) {
        this.set('viscosity_unit', option);
      },

      // Selection action for ember-select on [suction_diameter]
      selectSuctionDiameter: function selectSuctionDiameter(value, option) {
        this.set('suction_diameter', option);
      },

      // Selection action for ember-select on [discharge_diameter]
      selectDischargeDiameter: function selectDischargeDiameter(value, option) {
        this.set('discharge_diameter', option);
      },
      calculate: function calculate() {
        var totalSuctionLosses = void 0,
            totalDischargeLosses = void 0;

        var suctionLosses = [this.fittingLoss(this.get('suction_reynolds'), this.get('suction_diameter.value'), '90-Elbow', this.get('suction_90_elbow')), this.fittingLoss(this.get('suction_reynolds'), this.get('suction_diameter.value'), 'Globe Valve', this.get('suction_globe_valve')), this.fittingLoss(this.get('suction_reynolds'), this.get('suction_diameter.value'), 'Gate Valve', this.get('suction_gate_valve')), this.fittingLoss(this.get('suction_reynolds'), this.get('suction_diameter.value'), 'Ball Valve', this.get('suction_ball_valve')), this.fittingLoss(this.get('suction_reynolds'), this.get('suction_diameter.value'), 'Swing Check', this.get('suction_swing_check')), this.fittingLoss(this.get('suction_reynolds'), this.get('suction_diameter.value'), 'Lift Check', this.get('suction_life_check')), this.lineLoss(this.get('suction_length'), this.get('suction_diameter.value'), this.get('suction_moody'))];
        totalSuctionLosses = suctionLosses.reduce(function (memo, val) {
          return memo + val;
        });

        totalSuctionLosses = totalSuctionLosses * this.vsquare(this.get('_cFlow').convertTo('GPM'), this.get('suction_diameter.value'));
        this.set('_suction_losses', totalSuctionLosses);

        var dischargeLosses = [this.fittingLoss(this.get('discharge_reynolds'), this.get('discharge_diameter.value'), '90-Elbow', this.get('discharge_90_elbow')), this.fittingLoss(this.get('discharge_reynolds'), this.get('discharge_diameter.value'), 'Globe Valve', this.get('discharge_globe_valve')), this.fittingLoss(this.get('discharge_reynolds'), this.get('discharge_diameter.value'), 'Gate Valve', this.get('discharge_gate_valve')), this.fittingLoss(this.get('discharge_reynolds'), this.get('discharge_diameter.value'), 'Ball Valve', this.get('discharge_ball_valve')), this.fittingLoss(this.get('discharge_reynolds'), this.get('discharge_diameter.value'), 'Swing Check', this.get('discharge_swing_check')), this.fittingLoss(this.get('discharge_reynolds'), this.get('discharge_diameter.value'), 'Lift Check', this.get('discharge_life_check')), this.lineLoss(this.get('discharge_length'), this.get('discharge_diameter.value'), this.get('discharge_moody'))];
        totalDischargeLosses = dischargeLosses.reduce(function (memo, val) {
          return memo + val;
        });

        totalDischargeLosses = totalDischargeLosses * this.vsquare(this.get('_cFlow').convertTo('GPM'), this.get('discharge_diameter.value'));
        this.set('_discharge_losses', totalDischargeLosses);
      }
    }
  });
});