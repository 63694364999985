define('fti-pump-selector/routes/sp-pump-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    appState: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('appState.validFilters')) {
        this.transitionTo('pump-selector');
      }
    }
  });
});