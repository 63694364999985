define('fti-pump-selector/models/spare-part', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    partNumber: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    productCategory: (0, _attr.default)('string'),
    listPrice: (0, _attr.default)('Number'),
    netPrice: (0, _attr.default)('Number'),
    hasPositivePrice: Ember.computed('listPrice', 'netPrice', function () {
      return this.get('listPrice') && this.get('listPrice') > 0 || this.get('netPrice') && this.get('netPrice') > 0;
    }),
    // TODO: Should this really be netPrice? Double Check!!
    price: Ember.computed('listPrice', 'netPrice', function () {
      if (this.get('netPrice') >= 0.0) {
        return this.get('netPrice').toFixed(2);
      }
      if (this.get('listPrice') >= 0.0) {
        return this.get('listPrice').toFixed(2);
      }
    })
  });
});