define('fti-pump-selector/models/drum-pump-chemical', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    specific_gravity: (0, _attr.default)('number'),
    typical_viscosity: (0, _attr.default)('number'),
    flammable: (0, _attr.default)('boolean'),
    flash_point: (0, _attr.default)('number')
  });
});