define('fti-pump-selector/components/quote-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appState: Ember.inject.service(),
    defaultOkText: 'Ok',
    okButtonText: Ember.computed.or('okText', 'defaultOkText'),
    // DB6V-2-8P-14-Ti-A
    modelNumber: Ember.computed.alias('appState.modelNumber'),
    quoteInfo: Ember.computed.alias('appState.quoteInfo'),
    actions: {
      get_another_price: function get_another_price() {
        this.$('.modal').modal('hide');
        // this.ok();
        this.set('appState.quoteModal', false);
        this.set('appState.getPriceModal', true);
      },
      close: function close() {
        this.$('.modal').modal('hide');
        this.set('appState.quoteModal', false);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('.modal').modal().on('hidden.bs.modal', function () {
        _this.close();
      });
    }
  });
});