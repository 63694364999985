define('fti-pump-selector/models/drum-pump-motor', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    motorType: (0, _attr.default)('string'),
    modelNumber: (0, _attr.default)('string'),
    partNumber: (0, _attr.default)('string'),
    value: (0, _attr.default)('number'),
    inputEnergy: (0, _attr.default)('number'),
    outputEnergy: (0, _attr.default)('number'),
    frequency: (0, _attr.default)('number'),
    voltage: (0, _attr.default)('string'),
    phase: (0, _attr.default)('number'),
    airRequirements: (0, _attr.default)('string'),
    fla: (0, _attr.default)('number'),
    rpm: (0, _attr.default)('string'),
    speed: (0, _attr.default)('string'),
    maxViscosity: (0, _attr.default)('number'),
    boxDimensionsWidth: (0, _attr.default)('number'),
    boxDimensionsHeight: (0, _attr.default)('number'),
    boxDimensionsDepth: (0, _attr.default)('number'),
    weight: (0, _attr.default)('number'),
    certifications: (0, _attr.default)('string'),
    isHazardousAreaSafe: (0, _attr.default)('boolean'),
    batteryOperated: (0, _attr.default)('boolean')
  });
});