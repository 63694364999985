define('fti-pump-selector/models/user', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-data/relationships', 'fti-pump-selector/config/environment'], function (exports, _attr, _model, _relationships, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    pricing: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    companyName: (0, _attr.default)('string'),
    companyAddressFirst: (0, _attr.default)('string'),
    companyAddressSecond: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zipCode: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    logo: (0, _relationships.belongsTo)('logo'),

    logoUrl: Ember.computed('logo.filename', function () {
      if (this.get('logo.image.url')) {
        return '' + _environment.default.APP.dataEndPoint + this.get('logo.image.url');
      }
    })
  });
});