define('fti-pump-selector/routes/drum-pump-selector', ['exports', 'fti-pump-selector/routes/fti-route'], function (exports, _ftiRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ftiRoute.default.extend({
    pageTitle: 'Drum Pump Selector',
    title: 'FTI Drum Pump Selector',

    afterModel: function afterModel() {
      var _this = this;

      // Return promise here to in order to have the fluid loading
      // delay the render process. The controller variable is then
      // set below in the setupController method
      if (!this.get('fluidsLoaded')) {
        return this.store.findAll('drum-pump-chemical').then(function (data) {
          _this.set('fluids', data);
          _this.set('fluidsLoaded', true);
        });
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(arguments);

      controller.set('fluids', this.get('fluids'));
      if (Ember.isNone(controller.get('fluid'))) {
        controller.set('fluid', controller.get('fluids').filterBy('name', 'Water').get('firstObject'));
      }
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('static/drum-pump-selector-intro', {
        into: 'application',
        outlet: 'introText',
        controller: 'application'
      });
    }
  });
});