define('fti-pump-selector/models/motor', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // Start SimpleBase
    name: (0, _attr.default)('string'),
    dash_number: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    label: Ember.computed('name', 'dash_number', function () {
      if (!Ember.isBlank(this.get('dash_number')) && !Ember.isBlank(this.get('name'))) {
        return this.get('dash_number') + ' - ' + this.get('name');
      } else if (!Ember.isBlank(this.get('dash_number'))) {
        return this.get('dash_number');
      } else {
        return this.get('name');
      }
    }),
    contactFactory: (0, _attr.default)('boolean'),
    // End SimpleBase

    adapterType: (0, _attr.default)('string'),
    motorTypeCode: (0, _attr.default)('string'),
    motorType: (0, _attr.default)('string'),
    premiumEfficiency: (0, _attr.default)('boolean'),
    voltage: (0, _attr.default)('string'),
    phase: (0, _attr.default)('number'),
    power: (0, _attr.default)('number'),
    speed60: (0, _attr.default)('number'),
    speed50: (0, _attr.default)('number'),
    speed: (0, _attr.default)('number'),
    dualRated: (0, _attr.default)('boolean'),
    sortOrder: (0, _attr.default)('number'),
    listPrice: (0, _attr.default)('number'),
    exportPrice: (0, _attr.default)('number'),

    compatibleMotorAdapter: (0, _relationships.belongsTo)('motor-adapter'),

    price: Ember.computed('listPrice', 'exportPrice', function () {
      if (this.get('exportPrice')) {
        return this.get('exportPrice');
      }
      if (this.get('listPrice')) {
        return this.get('listPrice');
      }
    }),

    speedRange: Ember.computed('@each.speed', function () {
      if (this.get('is50Hertz')) {
        if (this.get('speed50') > 1800) {
          return 'high';
        } else {
          return 'low';
        }
      } else if (this.get('is60Hertz')) {
        if (this.get('speed60') > 1800) {
          return 'high';
        } else {
          return 'low';
        }
      } else {
        if (this.get('speed60') > 1800 || this.get('speed50')) {
          return 'high';
        } else {
          return 'low';
        }
      }
    }),

    is50Hertz: Ember.computed('speed50', 'speed60', function () {
      return !Ember.isEmpty(this.get('speed50')) && Ember.isEmpty(this.get('speed60'));
    }),

    is60Hertz: Ember.computed('speed50', 'speed60', function () {
      return Ember.isEmpty(this.get('speed50')) && !Ember.isEmpty(this.get('speed60'));
    }),

    isDualHertz: Ember.computed('speed50', 'speed60', function () {
      return !Ember.isEmpty(this.get('speed50')) && !Ember.isEmpty(this.get('speed60'));
    })
  });
});