define('fti-pump-selector/components/pump-result', ['exports', 'fti-pump-selector/models/pump-calculations'], function (exports, _pumpCalculations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    results: Ember.computed('pumps.[]', function () {
      var _this = this;

      var content = this.get('pumps');
      if (!content) {
        return;
      }

      var correctedPumps = content.toArray().map(function (pump) {
        pump.set('pumpCalculations', _pumpCalculations.default.create({
          pump: pump,
          flowUnit: _this.get('flowUnit'),
          headUnit: _this.get('headUnit'),
          powerUnit: _this.get('powerUnit'),
          pressureUnit: _this.get('pressureUnit'),
          viscosityUnit: _this.get('viscosityUnit'),
          viscosity: Number(_this.get('viscosity')),
          designFlow: Number(_this.get('designFlow')),
          specificGravity: Number(_this.get('specificGravity'))
        }));
        return pump;
      });

      return correctedPumps;
    })
  });
});