define('fti-pump-selector/controllers/drum-pump-details/motor-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    displayBoxDimensions: Ember.computed('model.{boxDimensionsWidth,model.boxDimensionsHeight,model.boxDimensionsDepth}', function () {
      return this.get('model.boxDimensionsWidth') && this.get('model.boxDimensionsHeight') && this.get('model.boxDimensionsDepth');
    }),
    boxDimensionWidthMetric: Ember.computed('model.boxDimensionsWidth', function () {
      var dim = this.get('model.boxDimensionsWidth');
      return new Qty(dim + ' in').to('cm').toPrec(0.1);
    }),
    boxDimensionHeightMetric: Ember.computed('model.boxDimensionsHeight', function () {
      var dim = this.get('model.boxDimensionsHeight');
      return new Qty(dim + ' in').to('cm').toPrec(0.1);
    }),
    boxDimensionDepthMetric: Ember.computed('model.boxDimensionsDepth', function () {
      var dim = this.get('model.boxDimensionsDepth');
      return new Qty(dim + ' in').to('cm').toPrec(0.1);
    }),
    weightMetric: Ember.computed('model.weight', function () {
      var weight = this.get('model.weight');
      return new Qty(weight + ' lbs').to('kg').toPrec(0.1);
    })
  });
});