define('fti-pump-selector/routes/drum-pump-selector-results', ['exports', 'fti-pump-selector/routes/fti-route'], function (exports, _ftiRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ftiRoute.default.extend({
    appState: Ember.inject.service(),

    title: 'Pump Selector Results - FTI Drum Pump Selector',
    pageTitle: 'Pump Selector Results',

    beforeModel: function beforeModel() {
      if (!this.get('appState.validDrumPumpFilters')) {
        this.transitionTo('drum-pump-selector');
      }
    },


    // activate: function() {
    //   this._super();
    //   this.get('appState').set('allowDesignReset', true);
    // },

    model: function model() {
      var _this = this;

      if (!this.get('modelLoaded')) {
        return this.get('store').findAll('drum-pump-model').then(function (data) {
          _this.set('modelLoaded', true);
          return data;
        });
      } else {
        return this.get('store').findAll('drum-pump-model');
      }
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('static/title-intro-only', {
        into: 'application',
        outlet: 'introText',
        controller: 'application'
      });
    }
  });
});