define('fti-pump-selector/routes/pump-selector-results', ['exports', 'fti-pump-selector/routes/fti-route'], function (exports, _ftiRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ftiRoute.default.extend({
    appState: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('appState.validFilters')) {
        this.transitionTo('pump-selector');
      }
    },
    activate: function activate() {
      this._super();
      this.get('appState').set('allowDesignReset', true);
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName !== 'pumpSelectorResults.partNumber' && transition.targetName !== 'pumpSelectorResults.curveData') {
          this.controller.set('selectedResult', null);
        }
      }
    }
  });
});