define('fti-pump-selector/routes/drum-pump-details/motor-details', ['exports', 'fti-pump-selector/routes/fti-route'], function (exports, _ftiRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ftiRoute.default.extend({
    pageTitle: 'Drum Pump Selector',
    title: 'FTI Drum Pump Selector',

    model: function model(params) {
      return this.get('store').find('drum-pump-motor', params.motor_id);
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('static/title-intro-only', {
        into: 'application',
        outlet: 'introText',
        controller: 'application'
      });
    }
  });
});