define('fti-pump-selector/controllers/sp-pump-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['series', 'design_flow', 'design_head', 'english_head', 'english_flow', 'motor_speed', 'frequency', 'viscosity', 'viscosity_unit', 'specific_gravity', 'fluid_id', 'temperature'],

    pageTitle: 'SP Pump Options',

    suctionLength: null,
    suctionLengths: Ember.A([{
      value: 0,
      label: 'Flooded'
    }, {
      value: 5,
      label: '5 Feet (1.5 M)'
    }, {
      value: 10,
      label: '10 Feet (3 M)'
    }, {
      value: 15,
      label: '15 Feet (4.5 M)'
    }]),

    actions: {
      // Selection action for ember-select on [suctionLengths]
      selectSuctionLength: function selectSuctionLength(value, option) {
        this.set('suctionLength', option);
      },
      generatePumpResults: function generatePumpResults() {
        var params = {
          series: this.get('series'),
          english_flow: this.get('english_flow'),
          english_head: this.get('english_head'),
          design_flow: this.get('design_flow'),
          design_head: this.get('design_head'),
          motor_speed: this.get('motor_speed'),
          frequency: this.get('frequency'),
          viscosity: this.get('viscosity'),
          viscosity_unit: this.get('viscosity_unit'),
          specific_gravity: this.get('specific_gravity'),
          suctionLength: this.get('suctionLength.value'),
          fluid_id: this.get('fluid_id'),
          temperature: this.get('temperature')
        };

        this.transitionToRoute('pump-selector-results', { queryParams: params });
      }
    }
  });
});