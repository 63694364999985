define('fti-pump-selector/controllers/ac-pump-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    pumpApplication: Ember.inject.service(),

    pageTitle: 'AC Pump Options',

    availableConfigurations: Ember.A([{
      value: 'horizontal',
      label: 'Horizontal'
    }, {
      value: 'vertical',
      label: 'Vertical'
    }]),

    actions: {
      // Selection action for ember-select on [configurations]
      selectConfigurations: function selectConfigurations(value, option) {
        this.set('configurations', option);
      },
      generatePumpResults: function generatePumpResults() {
        return this.transitionToRoute('pump-selector-results', this.get('pumpApplication.pump_seri.value'));
      }
    }
  });
});