define('fti-pump-selector/controllers/pump-search-modal', ['exports', 'ember-data', 'fti-pump-selector/utils/pump', 'ember-sweetalert'], function (exports, _emberData, _pump, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),

    _pumpModelNumber: null,

    pumpModelNumber: Ember.computed('_pumpModelNumber', {
      get: function get() {
        if (!Ember.isNone(this.get('_pumpModelNumber'))) {
          return this.get('_pumpModelNumber');
        }
      },
      set: function set(key, value) {
        this.set('_pumpModelNumber', value);
        return this.get('_pumpModelNumber').toUpperCase();
      }
    }),

    isLoading: Ember.computed('isPumpLoading', 'pumpFoundAdditives.isPending', function () {
      return !!this.get('isPumpLoading') || !!this.get('pumpFoundAdditives.isPending');
    }),

    pumpPrice: Ember.computed.alias('pumpFound.price'),

    pumpModelNumberChanged: Ember.observer('pumpModelNumber', function () {
      this.set('pumpFound', null);
    }),

    pumpFoundAdditives: Ember.computed('pumpFound', function () {
      if (!Ember.isNone(this.get('pumpFound'))) {
        return _emberData.default.PromiseObject.create({
          promise: this.get('pumpFound.pumpAdditives')
        });
      }
    }),

    pumpObjectFound: Ember.computed('pumpFound', 'pumpRoot', function () {
      if (!Ember.isNone(this.get('pumpFound'))) {
        var root = this.get('pumpRoot');
        var newRoot = '';
        if (root.charAt(root.length - 1) === 'W') {
          newRoot = root.slice(0, -2);
        } else {
          newRoot = root.slice(0, -1);
        }

        return _emberData.default.PromiseObject.create({
          promise: this.store.queryRecord('pump', { model_number: newRoot })
        });
      }
    }),

    totalPumpPrice: Ember.computed('pumpFound.price', 'accessoriesPrice', function () {
      return this.get('pumpFound.price') + this.get('accessoriesPrice');
    }),

    pumpRoot: Ember.computed('pumpModelNumber', function () {
      var pumpUtil = new _pump.default();
      return pumpUtil.findRoot(this.get('pumpModelNumber'));
    }),

    pumpAccessories: Ember.computed('pumpModelNumber', function () {
      var pumpUtil = new _pump.default();
      return pumpUtil.findAccessories(this.get('pumpModelNumber'));
    }),

    accessoriesPrice: Ember.computed('pumpFound', 'pumpAccessories', 'pumpFoundAdditives.content.[]', function () {
      var _this = this;

      if (!Ember.isBlank(this.get('pumpFound'))) {
        var options = this.get('pumpAccessories');
        var accessoriesPrice = 0;

        if (!Ember.isEmpty(options) && !Ember.isNone(this.get('pumpFoundAdditives.content'))) {
          var prices = options.map(function (option) {
            var additive = _this.get('pumpFoundAdditives.content').findBy('dashNumber', option);
            if (!Ember.isNone(additive)) {
              return additive.get('price');
            } else {
              return 0;
            }
          });

          if (prices.length) {
            accessoriesPrice = prices.compact().reduce(function (prev, item) {
              return prev + item;
            });
          } else {
            accessoriesPrice = 0;
          }
        }

        return accessoriesPrice;
      }
    }),

    defaultHousingMaterial: Ember.computed('pumpObjectFound', function () {
      var _this2 = this;

      var defaultMaterial = this.get('pumpObjectFound.defaultHousingMaterial').toString();
      var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        _this2.get('pumpObjectFound.housingMaterials').then(function (data) {
          var obj = data.findBy('id', defaultMaterial);
          if (!Ember.isNone(obj)) {
            resolve(obj);
          }
        });
      });

      return _emberData.default.PromiseObject.create({
        promise: phmPromise
      });
    }),

    defaultHousingMaterialDescription: Ember.computed('defaultHousingMaterial.content', function () {
      if (!Ember.isNone(this.get('defaultHousingMaterial.content'))) {
        if (!Ember.isBlank(this.get('defaultHousingMaterial.content.description'))) {
          return this.get('defaultHousingMaterial.content.description');
        } else {
          return this.get('defaultHousingMaterial.content.name');
        }
      }
    }),

    connectionComponent: Ember.computed('pumpObjectFound', 'pumpAccessories', function () {
      var _this3 = this;

      if (this.get('pumpObjectFound')) {
        var accessoryID = '';
        var acc = [];

        if (!Ember.isNone(this.get('pumpAccessories'))) {
          acc = this.get('pumpAccessories');
        }

        if (acc.indexOf('B') !== -1) {
          accessoryID = '2';
        } else if (acc.indexOf('U') !== -1) {
          accessoryID = '3';
        } else if (acc.indexOf('FS') !== -1) {
          accessoryID = '4';
        } else if (acc.indexOf('FF') !== -1) {
          accessoryID = '5';
        } else {
          accessoryID = this.get('pumpObjectFound.defaultConnection').toString();
        }

        var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          _this3.get('pumpObjectFound.connections').then(function (data) {
            var obj = data.findBy('id', accessoryID);
            if (!Ember.isNone(obj)) {
              resolve(obj);
            }
          });
        });

        return _emberData.default.PromiseObject.create({
          promise: phmPromise
        });
      }
    }),

    connectionComponentDescription: Ember.computed('connectionComponent.content', function () {
      if (!Ember.isNone(this.get('connectionComponent.content'))) {
        if (!Ember.isBlank(this.get('connectionComponent.content.description'))) {
          return this.get('connectionComponent.content.description');
        } else {
          return this.get('connectionComponent.content.name');
        }
      }
    }),

    defaultOringMaterial: Ember.computed('pumpObjectFound', function () {
      var _this4 = this;

      if (this.get('pumpObjectFound')) {
        var defaultMaterial = this.get('pumpObjectFound.defaultOringMaterial').toString();
        var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          _this4.get('pumpObjectFound.oringMaterials').then(function (data) {
            var obj = data.findBy('id', defaultMaterial);
            if (!Ember.isNone(obj)) {
              resolve(obj);
            }
          });
        });

        return _emberData.default.PromiseObject.create({
          promise: phmPromise
        });
      }
    }),

    defaultOringMaterialDescription: Ember.computed('defaultOringMaterial.content', function () {
      if (!Ember.isNone(this.get('defaultOringMaterial.content'))) {
        if (!Ember.isBlank(this.get('defaultOringMaterial.content.description'))) {
          return this.get('defaultOringMaterial.content.description');
        } else {
          return this.get('defaultOringMaterial.content.name');
        }
      }
    }),

    defaultMagnet: Ember.computed('pumpObjectFound.content', function () {
      var _this5 = this;

      if (this.get('pumpObjectFound')) {
        var defaultMaterial = this.get('pumpObjectFound.defaultMagnet').toString();
        var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          _this5.get('pumpObjectFound.magnets').then(function (data) {
            if (!Ember.isNone(data)) {
              var obj = data.findBy('id', defaultMaterial);
              if (!Ember.isNone(obj)) {
                resolve(obj);
              }
            }
          });
        });

        return _emberData.default.PromiseObject.create({
          promise: phmPromise
        });
      }
    }),

    defaultMagnetDescription: Ember.computed('defaultMagnet.content', function () {
      if (!Ember.isNone(this.get('defaultMagnet'))) {
        if (!Ember.isBlank(this.get('defaultMagnet.description'))) {
          return this.get('defaultMagnet.description');
        } else {
          return this.get('defaultMagnet.name');
        }
      }
    }),

    defaultShaftMaterial: Ember.computed('pumpObjectFound', function () {
      var _this6 = this;

      if (this.get('pumpObjectFound')) {
        var defaultMaterial = this.get('pumpObjectFound.defaultShaftMaterial').toString();
        var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          _this6.get('pumpObjectFound.shaftMaterials').then(function (data) {
            var obj = data.findBy('id', defaultMaterial);
            if (!Ember.isNone(obj)) {
              resolve(obj);
            }
          });
        });

        return _emberData.default.PromiseObject.create({
          promise: phmPromise
        });
      }
    }),

    defaultShaftMaterialDescription: Ember.computed('defaultShaftMaterial.content', function () {
      if (!Ember.isNone(this.get('defaultShaftMaterial.content'))) {
        if (!Ember.isBlank(this.get('defaultShaftMaterial.content.description'))) {
          return this.get('defaultShaftMaterial.content.description');
        } else {
          return this.get('defaultShaftMaterial.content.name');
        }
      }
    }),

    defaultBushingMaterial: Ember.computed('pumpObjectFound', function () {
      var _this7 = this;

      if (this.get('pumpObjectFound')) {
        var defaultMaterial = this.get('pumpObjectFound.defaultBushingMaterial').toString();
        var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          _this7.get('pumpObjectFound.bushingMaterials').then(function (data) {
            var obj = data.findBy('id', defaultMaterial);
            if (!Ember.isNone(obj)) {
              resolve(obj);
            }
          });
        });

        return _emberData.default.PromiseObject.create({
          promise: phmPromise
        });
      }
    }),

    defaultBushingMaterialDescription: Ember.computed('defaultBushingMaterial.content', function () {
      if (!Ember.isNone(this.get('defaultBushingMaterial.content'))) {
        if (!Ember.isBlank(this.get('defaultBushingMaterial.content.description'))) {
          return this.get('defaultBushingMaterial.content.description');
        } else {
          return this.get('defaultBushingMaterial.content.name');
        }
      }
    }),

    defaultHardwareMaterial: Ember.computed('pumpObjectFound', function () {
      var _this8 = this;

      if (this.get('pumpObjectFound')) {
        var defaultMaterial = this.get('pumpObjectFound.defaultHardwareMaterial').toString();
        var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          _this8.get('pumpObjectFound.hardwareMaterials').then(function (data) {
            var obj = data.findBy('id', defaultMaterial);
            if (!Ember.isNone(obj)) {
              resolve(obj);
            }
          });
        });

        return _emberData.default.PromiseObject.create({
          promise: phmPromise
        });
      }
    }),

    defaultHardwareMaterialDescription: Ember.computed('defaultHardwareMaterial.content', function () {
      if (!Ember.isNone(this.get('defaultHardwareMaterial.content'))) {
        if (!Ember.isBlank(this.get('defaultHardwareMaterial.content.description'))) {
          return this.get('defaultHardwareMaterial.content.description');
        } else {
          return this.get('defaultHardwareMaterial.content.name');
        }
      }
    }),

    defaultSealMaterial: Ember.computed('pumpObjectFound', function () {
      var _this9 = this;

      if (this.get('pumpObjectFound')) {
        var defaultMaterial = this.get('pumpObjectFound.defaultSealMaterial').toString();
        var phmPromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          _this9.get('pumpObjectFound.sealMaterials').then(function (data) {
            var obj = data.findBy('id', defaultMaterial);
            if (!Ember.isNone(obj)) {
              resolve(obj);
            }
          });
        });

        return _emberData.default.PromiseObject.create({
          promise: phmPromise
        });
      }
    }),

    defaultSealMaterialDescription: Ember.computed('defaultSealMaterial.content', function () {
      if (!Ember.isNone(this.get('defaultSealMaterial.content'))) {
        if (!Ember.isBlank(this.get('defaultSealMaterial.content.description'))) {
          return this.get('defaultSealMaterial.content.description');
        } else {
          return this.get('defaultSealMaterial.content.name');
        }
      }
    }),

    pumpDescription: Ember.computed('pumpFound', 'pumpRoot', 'pumpObjectFound.content.[]', 'defaultHousingMaterialDescription', 'connectionComponentDescription', 'defaultOringMaterialDescription', 'defaultMagnetDescription', 'defaultShaftMaterialDescription', 'defaultBushingMaterialDescription', 'defaultHardwareMaterialDescription', 'defaultSealMaterialDescription', 'pumpAccessories', function () {
      var root = this.get('pumpRoot');
      var mat = '';
      var wetEnd = false;

      var acc = [];

      if (!Ember.isNone(this.get('pumpAccessories'))) {
        acc = this.get('pumpAccessories');
      }

      // Get Housing material dash number
      if (root.charAt(root.length - 1) === 'W') {
        mat = root.charAt(root.length - 2);
        wetEnd = true;
      } else {
        mat = root.charAt(root.length - 1);
      }

      // TODO: Figure out how to pull the names from the dash numbers and remove hard-coded names!!! Get descriptions for diameter and magnets

      if (!Ember.isNone(this.get('pumpFound')) && !Ember.isNone(this.get('pumpObjectFound.content'))) {
        var pumpDescString = this.get('pumpObjectFound.modelNumber') + ' Pump with ';

        // Housing Material
        if (mat === 'P') {
          pumpDescString += ' Polypropylene wetted parts;';
        } else if (mat === 'V') {
          pumpDescString += ' PVDF wetted parts;';
        } else {
          pumpDescString += ' ' + this.get('defaultHousingMaterialDescription') + ' wetted parts;';
        }

        pumpDescString += ' ' + (this.get('pumpObjectFound.closedImpeller') ? 'Closed' : 'Open') + ' Impeller,';
        pumpDescString += ' ' + this.get('pumpObjectFound.diameter') + '  inches diameter;';

        // Connection
        pumpDescString += ' ' + this.get('connectionComponentDescription') + ' Connection;';

        // Oring
        if (acc.indexOf('E') !== -1) {
          pumpDescString += ' EPDM Orings;';
        } else if (acc.indexOf('S') !== -1) {
          pumpDescString += ' Simriz Orings;';
        } else if (acc.indexOf('K') !== -1) {
          pumpDescString += ' Kalrez Orings;';
        } else if (!Ember.isNone(this.get('defaultOringMaterialDescription'))) {
          pumpDescString += ' ' + this.get('defaultOringMaterialDescription') + '  Orings;';
        }

        if (!Ember.isBlank(this.get('defaultMagnetDescription'))) {
          pumpDescString += ' ' + this.get('defaultMagnetDescription') + ' Magnet;';
        }

        // Shaft materials
        if (acc.indexOf('D') !== -1) {
          pumpDescString += ' Dri-Coat SiC Shaft;';
        } else if (acc.indexOf('SI') !== -1) {
          pumpDescString += ' Silicon Carbide Shaft;';
        } else if (acc.indexOf('HC') !== -1) {
          pumpDescString += ' HastelloyC Shaft;';
        } else if (acc.indexOf('NACR') !== -1) {
          pumpDescString += ' Ceramic Shaft;';
        } else if (!Ember.isNone(this.get('defaultShaftMaterialDescription'))) {
          pumpDescString += ' ' + this.get('defaultShaftMaterialDescription') + '  Shaft;';
        }

        // Bushing
        if (acc.indexOf('R') !== -1) {
          pumpDescString += ' Ceramic Bushing;';
        } else if (acc.indexOf('T') !== -1) {
          pumpDescString += ' PTFE Bushing;';
        } else if (!Ember.isNone(this.get('defaultBushingMaterialDescription'))) {
          pumpDescString += ' ' + this.get('defaultBushingMaterialDescription') + ' Bushing;';
        }

        // Hardware Materials
        if (acc.indexOf('Ti') !== -1) {
          pumpDescString += ' Titanium Hardware;';
        }
        if (!Ember.isNone(this.get('defaultHardwareMaterialDescription'))) {
          if (this.get('defaultHardwareMaterialDescription').toUpperCase() === 'TITANIUM') {
            pumpDescString += ' Titanium hardware;';
          }
        }

        // Vapor Seal
        if (acc.indexOf('VE') !== -1) {
          pumpDescString += ' EPDM Vapor Seal Materials;';
        } else if (acc.indexOf('VV') !== -1) {
          pumpDescString += ' Viton or FKM Vapor Seal Materials;';
        } else if (!Ember.isNone(this.get('defaultSealMaterialDescription'))) {
          pumpDescString += ' ' + this.get('defaultSealMaterialDescription') + ' Vapor Seal Material;';
        }

        if (wetEnd) {
          pumpDescString += ' Wet End Only;';
        }

        /*
        TODO: Get motor safety feature description (not in pump object content)
            if (!isBlank(this.get('motorSafetyFeatureAlias.description'))) {
              pumpDescString += ' ' + (this.get('motorSafetyFeatureAlias.description')) + ';';
            } else if ( !isBlank(this.get('motorSafetyFeatureAlias.name'))) {
              pumpDescString += ' ' + (this.get('motorSafetyFeatureAlias.name'))  + ' Connection;';
            }
        */

        return pumpDescString;
      }
    }),

    actions: {
      search_pumps: function search_pumps() {
        var _this10 = this;

        var pumpUtil = new _pump.default();
        this.set('isPumpLoading', true);

        this.store.queryRecord('pump-base-price', { root: this.get('pumpRoot') }).then(function (record) {
          pumpUtil.validatePumpNumber(record, _this10.get('pumpAccessories')).then(function (pumpValidation) {
            _this10.set('isPumpLoading', false);

            if (pumpValidation.valid) {
              if (!Ember.isNone(record)) {
                _this10.set('pumpFound', record);
              }
            }
          }).catch(function (error) {
            _this10.set('isPumpLoading', false);
            (0, _emberSweetalert.default)({
              title: 'Pump Number Error',
              text: 'Unable to validate pump number.\n' + error.errors[0].message,
              type: 'error',
              confirmButtonText: 'Try again'
            }).catch(_emberSweetalert.default.noop);
          });
        });
      }
    }
  });
});