define('fti-pump-selector/services/session-account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    pricingAvailable: Ember.computed.bool('account.pricing'),

    loadCurrentUser: function loadCurrentUser() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var token = _this.get('session.data.authenticated.access_token');
        if (!Ember.isEmpty(token)) {
          _this.get('store').find('user', 'me').then(function (user) {
            _this.set('account', user);
            resolve();
          }, reject);
        } else {
          resolve();
        }
      });
    }
  });
});