define('fti-pump-selector/controllers/part-search-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isLoading: false,

    partNumber: Ember.computed.or('part_number', ''),

    actions: {
      search_parts: function search_parts() {
        var _this = this;

        this.set('isLoading', true);
        this.set('partNotFound', false);

        this.store.findRecord('spare-part', this.get('partNumber')).then(function (part) {
          _this.set('isLoading', false);
          _this.set('partFound', part);
        }, function (reason) {
          _this.set('isLoading', false);
          _this.set('partNotFound', true);
          _this.set('partFound', null);
          Ember.Logger.error('Failed to find part: ', reason);
        });
      }
    }
  });
});