define('fti-pump-selector/controllers/drum-pump-details', ['exports', 'fti-pump-selector/lib/ember-promise-array-proxy'], function (exports, _emberPromiseArrayProxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    drumPumpSelector: Ember.inject.controller(), // Used for flammable flag. Should be a queryParam instead
    drumPumpSelectorResults: Ember.inject.controller(),

    flammable: Ember.computed.oneWay('drumPumpSelector.flammable'),
    hazardous: Ember.computed.oneWay('drumPumpSelector.hazardousLocation'),
    batteryRequired: Ember.computed.oneWay('drumPumpSelector.batteryRequired'),
    motorType: Ember.computed.oneWay('drumPumpSelector.motorType'),

    motorsPromise: Ember.computed('model.motors.[]', function () {
      var promise = this.get('model.motors');
      return _emberPromiseArrayProxy.default.create({
        promise: promise
      });
    }),

    hasSearchResults: Ember.computed('drumPumpSelectorResults.filteredResults.[]', function () {
      return this.get('drumPumpSelectorResults.filteredResults.length');
    }),

    filteredMotors: Ember.computed('motorsPromise.content.[]', 'flammable', 'batteryRequired', 'motorType', function () {
      var motors = this.get('motorsPromise.content');
      // filter and always return to add enumerable mixins?
      var fMotors = motors.filter(function (motor) {
        return motor;
      });

      if (this.get('flammable') || this.get('hazardous')) {
        fMotors = fMotors.filter(function (motor) {
          if (motor.get('isHazardousAreaSafe')) {
            return motor;
          }
        });
      }

      if (this.get('batteryRequired')) {
        fMotors = fMotors.filter(function (motor) {
          if (motor.get('batteryOperated')) {
            return motor;
          }
        });
      }

      var motorType = this.get('motorType.value');
      if (motorType) {
        // Current values of motorTypes look like the following:
        //    [power-method](-[xp:explosion-proof])?-[voltage]
        // Kept in the format, we can with one selection describe multiple conditions
        // Filtering is then just a matrix of making sure each condition is hit
        switch (motorType) {
          case 'electric-any':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('voltage') && motor.get('batteryOperated') === false) {
                return motor;
              }
            });
            break;

          case 'electric-115':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('voltage') && motor.get('voltage').split('/').includes('115') && motor.get('batteryOperated') === false) {
                return motor;
              }
            });
            break;

          case 'electric-230':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('voltage') && motor.get('voltage').split('/').includes('230') && motor.get('batteryOperated') === false) {
                return motor;
              }
            });
            break;

          case 'electric-xp-any':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('voltage') && motor.get('batteryOperated') === false && motor.get('isHazardousAreaSafe')) {
                return motor;
              }
            });
            break;

          case 'electric-xp-115':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('voltage') && motor.get('voltage').split('/').includes('115') && motor.get('batteryOperated') === false && motor.get('isHazardousAreaSafe')) {
                return motor;
              }
            });
            break;

          case 'electric-xp-230':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('voltage') && motor.get('voltage').split('/').includes('230') && motor.get('batteryOperated') === false && motor.get('isHazardousAreaSafe')) {
                return motor;
              }
            });
            break;

          case 'air':
            fMotors = fMotors.filter(function (motor) {
              if (!Ember.isBlank(motor.get('airRequirements'))) {
                return motor;
              }
            });
            break;

          case 'battery-115':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('batteryOperated') === true && motor.get('voltage') && motor.get('voltage').split('/').includes('115')) {
                return motor;
              }
            });
            break;

          case 'battery-230':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('batteryOperated') === true && motor.get('voltage') && motor.get('voltage').split('/').includes('230')) {
                return motor;
              }
            });
            break;

          case 'battery-12':
          case 'battery-car':
            fMotors = fMotors.filter(function (motor) {
              if (motor.get('batteryOperated') === true && motor.get('voltage') && motor.get('voltage').split('/').includes('12')) {
                return motor;
              }
            });
            break;
        }
      }

      return fMotors;
    }),

    sortedMotors: Ember.computed('filteredMotors.[]', function () {
      var motors = this.get('filteredMotors');

      if (motors.sort) {
        return motors.sort(function (a, b) {
          return a.get('value') - b.get('value');
        });
      }
    }),

    diameterMetric: Ember.computed('model.tubeDiameter', function () {
      var td = this.get('model.tubeDiameter');
      if (td) {
        return new Qty(td + ' in').to('cm').toPrec(0.1);
      }
    }),
    maxHeadElecMotorMetric: Ember.computed('model.maxHeadElecMotor', function () {
      var maxHead = this.get('model.maxHeadElecMotor');
      if (maxHead) {
        return new Qty(maxHead + ' ft').to('m').toPrec(0.1);
      }
    }),
    maxHeadAirMotorMetric: Ember.computed('model.maxHeadAirMotor', function () {
      var maxHead = this.get('model.maxHeadAirMotor');
      if (maxHead) {
        return new Qty(maxHead + ' ft').to('m').toPrec(0.1);
      }
    }),
    maxFlowElecMotorMetric: Ember.computed('model.maxFlowElecMotor', function () {
      var maxFlow = this.get('model.maxFlowElecMotor');
      if (maxFlow) {
        return new Qty(maxFlow + ' gallons / minute').to('liters / minute').toPrec(1);
      }
    }),
    maxFlowAirMotorMetric: Ember.computed('model.maxFlowAirMotor', function () {
      var maxFlow = this.get('model.maxFlowAirMotor');
      if (maxFlow) {
        return new Qty(maxFlow + ' gallons / minute').to('liters / minute').toPrec(1);
      }
    }),
    maxFlow12vMotorMetric: Ember.computed('model.maxFlow12vMotor', function () {
      var maxFlow = this.get('model.maxFlow12vMotor');
      if (maxFlow) {
        return new Qty(maxFlow + ' gallons / minute').to('liters / minute').toPrec(1);
      }
    }),
    maxTemperatureCelcius: Ember.computed('model.maxTemperature', function () {
      if (!Ember.isBlank(this.get('model.maxTemperature'))) {
        var maxTemp = this.get('model.maxTemperature');
        return new Qty(maxTemp + ' tempF').to('tempC').toPrec(1).scalar;
      }
    }),
    pumpLengthMetric: Ember.computed('model.pumpLength', function () {
      if (!Ember.isBlank(this.get('model.pumpLength'))) {
        var pumpLength = this.get('model.pumpLength');
        return new Qty(pumpLength + ' in').to('cm').toPrec(1);
      }
    }),
    pumpWeightMetric: Ember.computed('model.weight', function () {
      if (!Ember.isBlank(this.get('model.weight'))) {
        var pumpWeight = this.get('model.weight');
        return new Qty(pumpWeight + ' lbs').to('kg').toPrec(0.1);
      }
    }),
    boxDimensionWidthMetric: Ember.computed('model.boxDimensionWidth', function () {
      if (!Ember.isBlank(this.get('model.boxDimensionWidth'))) {
        var dim = this.get('model.boxDimensionWidth');
        return new Qty(dim + ' in').to('cm').toPrec(0.1);
      }
    }),
    boxDimensionHeightMetric: Ember.computed('model.boxDimensionHeight', function () {
      if (!Ember.isBlank(this.get('model.boxDimensionHeight'))) {
        var dim = this.get('model.boxDimensionHeight');
        return new Qty(dim + ' in').to('cm').toPrec(0.1);
      }
    }),
    boxDimensionDepthMetric: Ember.computed('model.boxDimensionDepth', function () {
      if (!Ember.isBlank(this.get('model.boxDimensionDepth'))) {
        var dim = this.get('model.boxDimensionDepth');
        return new Qty(dim + ' in').to('cm').toPrec(0.1);
      }
    })
  });
});