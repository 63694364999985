define('fti-pump-selector/models/calculator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    units: null,
    flow: 0,
    specific_gravity: 0,
    viscosity: 0,
    suction_diameter: null,
    suction_length: 0,
    suction_90_elbow: 0,
    suction_globe_valve: 0,
    suction_gate_valve: 0,
    suction_ball_valve: 0,
    suction_swing_check: 0,
    suction_life_check: 0,
    discharge_diameter: null,
    discharge_length: 0,
    discharge_90_elbow: 0,
    discharge_globe_valve: 0,
    discharge_gate_valve: 0,
    discharge_ball_valve: 0,
    discharge_swing_check: 0,
    discharge_life_check: 0
  });
});