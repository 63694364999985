define('fti-pump-selector/controllers/drum-pump-selector', ['exports', 'fti-pump-selector/models/settings', 'ember-sweetalert'], function (exports, _settings, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.unit = { value: 1, label: 'English' };
    },


    appState: Ember.inject.service(),
    settings: _settings.default.create(),

    // Set to null when temperature re-enabled and polished
    immersionLength: null,
    motorType: null,
    fluid: null,
    temperature: 68,
    specificGravity: Ember.computed.oneWay('fluid.specificGravity'),
    viscosity: 1.0,

    unitsAvailable: Ember.A([{ value: 1, label: 'English' }, { value: 2, label: 'Metric' }]),

    // Returns batch of units and labels to be used for selected unit set
    selectedUnits: Ember.computed('unit', function () {
      switch (this.get('unit.value')) {
        case 1:
          return this.get('englishUnits');
        case 2:
          return this.get('metricUnits');
      }
    }),
    englishUnits: Ember.Object.create({
      length: 'ft',
      temperature: 'tempF',
      temperatureLabel: '°F'
    }),
    metricUnits: Ember.Object.create({
      length: 'm',
      temperature: 'tempC',
      temperatureLabel: '°C'
    }),

    immersionLengths: Ember.A([{ value: 16, label: '16 in (41 cm)' }, { value: 27, label: '27 in (69 cm)' }, { value: 40, label: '40 in (102 cm)' }, { value: 48, label: '48 in (122 cm)' }, { value: 60, label: '60 in (152 cm)' }, { value: 72, label: '72 in (183 cm)' }]),

    motorTypes: Ember.A([{ value: 'electric', label: 'Electric' }, { value: 'electric-115', label: '115V', parentId: 'electric' }, { value: 'electric-230', label: '230V', parentId: 'electric' }, { value: 'electric-any', label: 'Any Voltage', parentId: 'electric' }, { value: 'electric-xp', label: 'Electric - Explosion Proof / Hazardous' }, { value: 'electric-xp-115', label: '115V', parentId: 'electric-xp' }, { value: 'electric-xp-230', label: '230V', parentId: 'electric-xp' }, { value: 'electric-xp-any', label: 'Any Voltage', parentId: 'electric-xp' }, { value: 'air-powered', label: 'Air' }, { value: 'air', label: 'Air', parentId: 'air-powered' }, { value: 'battery', label: '12V Battery Driven' }, {
      value: 'battery-115',
      label: '115V USA Charger Plug',
      parentId: 'battery'
    }, {
      value: 'battery-230',
      label: '230V Euro Charger Plug',
      parentId: 'battery'
    }, { value: 'battery-12', label: '12V Car Charger Plug', parentId: 'battery' }]),

    fluidsSorted: Ember.computed('fluids.[]', function () {
      if (!Ember.isBlank(this.get('fluids'))) {
        return this.get('fluids').sortBy('name');
      }
    }),

    // Always convert the current temperature of the chemical to °F
    chemTemp: Ember.computed('selectedUnits', 'fluid', 'temperature', function () {
      return new Qty(this.get('temperature') + ' ' + this.get('selectedUnits.temperature')).to('tempF');
    }),
    chemTempValue: Ember.computed('chemTemp', function () {
      return this.get('chemTemp').scalar;
    }),

    // Used to keep track of if the user selected the fluid as flammable, or if the currently
    // selected temperature is above the °F flash point. Obeys it's internal flammable
    // property first and then the fluid at temp.
    flammable: Ember.computed('unit', 'fluid', 'chemTemp', {
      get: function get() {
        // Check internal property
        if (this.get('_flammable')) {
          return true;
        }

        if (this.get('fluid.flammable')) {
          return true;
        }

        // Determine if current temperature is beyond fluid's fahrenheit flash point
        if (!Ember.isEmpty(this.get('fluid.flashPointF'))) {
          if (this.get('chemTempValue') >= this.get('fluid.flashPointF')) {
            return true;
          }
        }

        return false;
      },
      set: function set(key, value) {
        // Set internal property and return
        this.set('_flammable', value);
        return this.get('_flammable');
      }
    }),

    actions: {
      // Selection action for ember-select on [unit]
      selectUnit: function selectUnit(value, option) {
        this.set('unit', option);
      },

      // Selection action for ember-select on [fluid]
      selectFluid: function selectFluid(value, option) {
        this.set('fluid', option);
      },

      // Selection action for ember-select on [immersionLength]
      selectImmersionLength: function selectImmersionLength(value, option) {
        this.set('immersionLength', option);
      },

      // Selection action for ember-select on [motorType]
      selectMotorType: function selectMotorType(value, option) {
        this.set('motorType', option);
      },
      resetToWater: function resetToWater() {
        this.set('fluid', this.get('fluids').filterBy('name', 'Water').get('firstObject'));
      },


      // Checks if the current temperature of the fluid if above 300°F which is the ceiling of compatible pumps
      warnHighTemperature: function warnHighTemperature() {
        if (this.get('chemTempValue') > 300) {
          (0, _emberSweetalert.default)({
            title: 'Temperature Too High',
            text: 'The temperature entered exceeds the maximum temperature limit for FTI pumps.',
            type: 'error',
            confirmButtonText: 'Try again'
          }).then(function () {
            // Run in a run loop because this is in the controller instead of a view or component.
            Ember.run.later(function () {
              Ember.$('.temperature').focus();
            }, 2);
          }).catch(_emberSweetalert.default.noop);
        }
      },
      generatePumpResults: function generatePumpResults() {
        if (Ember.isEmpty(this.get('fluid'))) {
          (0, _emberSweetalert.default)({
            title: 'Enter a chemical',
            text: 'You must select a chemical before continuing.',
            type: 'warning',
            confirmButtonText: 'Try again'
          }).catch(_emberSweetalert.default.noop);
          return;
        }

        if (Ember.isEmpty(this.get('temperature'))) {
          (0, _emberSweetalert.default)({
            title: 'Enter a temperature',
            text: 'You must enter a temperature amount before continuing.',
            type: 'warning',
            confirmButtonText: 'Try again'
          }).catch(_emberSweetalert.default.noop);
          return;
        }

        if (Ember.isEmpty(this.get('temperature'))) {
          (0, _emberSweetalert.default)({
            title: 'Temperature Too High',
            text: 'The temperature entered exceeds the maximum temperature limit for FTI pumps.',
            type: 'error',
            confirmButtonText: 'Try again'
          }).catch(_emberSweetalert.default.noop);
          return;
        }

        if (Ember.isEmpty(this.get('viscosity'))) {
          (0, _emberSweetalert.default)({
            title: 'Select a viscosity',
            text: 'You must enter a viscosity amount before continuing.',
            type: 'warning',
            confirmButtonText: 'Try again'
          }).catch(_emberSweetalert.default.noop);
          return;
        }

        if (this.get('viscosity') < 1) {
          (0, _emberSweetalert.default)({
            title: 'Viscosity must be greater than 1',
            text: 'Viscosity is unable to be less than the viscosity of water (1).',
            type: 'warning',
            confirmButtonText: 'Try again'
          }).catch(_emberSweetalert.default.noop);
          return;
        }

        if (this.get('specific_gravity') < 0) {
          (0, _emberSweetalert.default)({
            title: 'Specific gravity must be positive',
            text: 'Specific gravity can not be a negative amount.',
            type: 'warning',
            confirmButtonText: 'Try again'
          }).catch(_emberSweetalert.default.noop);
          return;
        }

        this.set('appState.validDrumPumpFilters', true);
        this.transitionToRoute('drum-pump-selector-results');
      }
    }
  });
});