define('fti-pump-selector/models/magnet', ['exports', 'fti-pump-selector/models/simple-base', 'ember-data/attr', 'ember-data/relationships'], function (exports, _simpleBase, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _simpleBase.default.extend({
    adapterDash: (0, _attr.default)('string'),
    compatibleModel: (0, _attr.default)('string'),
    maxHp: (0, _attr.default)('number'),
    maxTemp: (0, _attr.default)('number'),
    maxTorque: (0, _attr.default)('number'),
    selectionRank: (0, _attr.default)('number'),
    motorAdapters: (0, _relationships.hasMany)('motor-adapter'),
    label: Ember.computed('dash_number', 'adapterDash', function () {
      if (!Ember.isBlank(this.get('ucPower'))) {
        return this.get('dash_number') + ' - Max HP: ' + Math.round(this.get('ucPower'));
      } else {
        if (!Ember.isBlank(this.get('adapterDash'))) {
          return this.get('dash_number') + ' - ' + this.get('adapterDash');
        }

        return '' + this.get('dash_number');
      }
    })
  });
});