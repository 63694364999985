define('fti-pump-selector/models/pump-calculations', ['exports', 'fti-pump-selector/utils/conversions'], function (exports, _conversions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    pump: null,
    flowUnit: null,
    headUnit: null,
    powerUnit: null,
    viscosityUnit: null,
    pressureUnit: null,
    viscosity: null,
    designFlow: null,
    specificGravity: null,

    bSolved: Ember.computed('viscosity', 'viscosity_unit', 'specific_gravity', 'pump.bFactor', function () {
      if (this.get('pump.bFactor')) {
        var viscosity = new _conversions.Viscosity(this.get('viscosityUnit'), this.get('viscosity'), this.get('specificGravity')).convertTo('CST');
        return Math.pow(viscosity, 0.5) * this.get('pump.bFactor');
      }
    }),

    cqSolved: Ember.computed('bSolved', function () {
      if (this.get('bSolved')) {
        if (this.get('bSolved') <= 1) {
          return 1;
        } else if (this.get('bSolved') >= 40) {
          return void 0;
        } else {
          var first_exp = -0.165 * Math.pow(Math.log10(this.get('bSolved')), 3.15);
          return Math.pow(2.71, first_exp);
        }
      }
    }),

    ceSolved: Ember.computed('bSolved', function () {
      if (this.get('bSolved') <= 1) {
        return 1;
      } else if (this.get('bSolved') > 1 && this.get('bSolved') < 40) {
        return Math.pow(this.get('bSolved'), -0.0547 * Math.pow(this.get('bSolved'), 0.69));
      }
    }),

    cQArray: Ember.computed('pump.qhns.[]', 'cqSolved', function () {
      var _this = this;

      if (this.get('cqSolved')) {
        return this.get('pump.qhns').map(function (item) {
          return item.get('q') * _this.get('cqSolved');
        });
      }
    }),

    cHArray: Ember.computed('pump.{qhns.[],bepFlow}', 'cqSolved', 'bSolved', function () {
      var _this2 = this;

      if (this.get('cqSolved')) {
        return this.get('pump.qhns').map(function (item, index) {
          return _this2.CH(_this2.get('bSolved'), _this2.get('pump.bepFlow'), _this2.get('pump.qhns').objectAt(index).get('q'), _this2.get('cqSolved')) * item.get('h');
        });
      }
    }),

    cEffArray: Ember.computed('pump.qhns.[]', 'ceSolved', function () {
      var _this3 = this;

      if (this.get('ceSolved')) {
        return this.get('pump.qhns').map(function (item) {
          return item.get('eff') * _this3.get('ceSolved');
        });
      }
    }),

    cPowerArray: Ember.computed('cQArray.[]', 'cHArray.[]', 'cEffArray.[]', 'pump.qhns.[]', 'specificGravity', function () {
      var _this4 = this;

      if (this.get('cQArray') && this.get('cHArray') && this.get('cEffArray')) {
        return this.get('pump.qhns').map(function (item, index) {
          return _this4.get('cQArray').objectAt(index) * _this4.get('cHArray').objectAt(index) * _this4.get('specificGravity') / (_this4.get('cEffArray').objectAt(index) * 39.6);
        });
      }
    }),

    convertedEnglishFlow: Ember.computed('flowUnit', 'designFlow', function () {
      return new _conversions.Flow(this.get('flowUnit'), this.get('designFlow')).convertTo('GPM');
    }),

    head: Ember.computed('pump', 'viscosity', 'convertedEnglishFlow', 'specificGravity', 'cQArray.[]', 'cHArray.[]', 'headUnit', function () {
      var pump_head = void 0;
      if (this.get('viscosity') > 1) {
        pump_head = this.headSpline(this.get('pump'), this.get('convertedEnglishFlow'), this.get('cQArray'), this.get('cHArray'));
      } else {
        pump_head = this.headSpline(this.get('pump'), this.get('convertedEnglishFlow'));
      }
      var pumpHeadConverted = new _conversions.Head('FT', pump_head, this.get('specificGravity')).convertTo(this.get('headUnit'));
      return Math.round10(pumpHeadConverted, -2);
    }),

    efficiency: Ember.computed('viscosity', 'convertedEnglishFlow', 'cQArray.[]', 'cHArray.[]', function () {
      var pump_efficiency = void 0;
      if (this.get('viscosity') > 1) {
        pump_efficiency = this.effSpline(this.get('pump'), this.get('convertedEnglishFlow'), this.get('cQArray'), this.get('cEffArray'));
      } else {
        pump_efficiency = this.effSpline(this.get('pump'), this.get('convertedEnglishFlow'));
      }
      return Math.round10(pump_efficiency, -2);
    }),

    power: Ember.computed('pump.id', 'viscosity', 'specificGravity', 'convertedEnglishFlow', 'cQArray.[]', 'cPowerArray.[]', 'powerUnit', function () {
      var pump_power = void 0;
      if (this.get('viscosity') > 1 || this.get('specificGravity') !== 1) {
        pump_power = this.powerSpline(this.get('pump'), this.get('convertedEnglishFlow'), this.get('cQArray'), this.get('cPowerArray'));
      } else {
        pump_power = this.powerSpline(this.get('pump'), this.get('convertedEnglishFlow'));
      }
      console.log(pump_power, this.get('pump.id'), this.get('bSolved'), this.get('pump.bepFlow'), this.get('pump.qhns'), this.get('cqSolved'));
      try {
        var pumpPowerConverted = new _conversions.Power('HP', pump_power).convertTo(this.get('powerUnit'));
        return Math.round10(pumpPowerConverted, -2);
      } catch (error) {
        /* eslint-disable no-console */
        console.error('Unable to convert power for pump: ', this.get('pump.id'));
        /* eslint-enable no-console */
        return 0;
      }
    }),

    nol_power: Ember.computed('viscosity', 'specificGravity', 'convertedEnglishFlow', 'cQArray.[]', 'cPowerArray.[]', 'powerUnit', function () {
      var pump_nol_power = void 0;
      if (this.get('viscosity') > 1 || this.get('specificGravity') !== 1) {
        pump_nol_power = this.nolPowerSpline(this.get('pump'), this.get('convertedEnglishFlow'), this.get('cQArray'), this.get('cPowerArray'));
      } else {
        pump_nol_power = this.nolPowerSpline(this.get('pump'), this.get('convertedEnglishFlow'));
      }
      var pumpNOLPowerConverted = new _conversions.Power('HP', pump_nol_power).convertTo(this.get('powerUnit'));
      return Math.round10(pumpNOLPowerConverted, -2);
    }),

    net_positive_suction_head: Ember.computed('convertedEnglishFlow', 'specificGravity', 'headUnit', function () {
      // Use maxFlowNpsh to check final flow stopping points for NPSHr
      if (this.get('convertedEnglishFlow') > this.get('pump.maxFlowNpsh')) {
        return null;
      }
      var npsh = this.npshSpline(this.get('pump'), this.get('convertedEnglishFlow'));
      var npshConverted = new _conversions.Head('FT', npsh, this.get('specificGravity')).convertTo(this.get('headUnit'));
      return Math.round10(npshConverted, -2);
    }),

    shutoffHead: Ember.computed('pump', 'viscosity', 'cQArray.[]', 'cHArray.[]', function () {
      var pump_shutoff = void 0;
      if (this.get('viscosity') > 1) {
        pump_shutoff = this.headSpline(this.get('pump'), this.get('pump.minFlow'), this.get('cQArray'), this.get('cHArray'));
      } else {
        pump_shutoff = this.headSpline(this.get('pump'), this.get('pump.minFlow'));
      }
      var pumpShutoffConverted = new _conversions.Head('FT', pump_shutoff, this.get('specificGravity')).convertTo(this.get('headUnit'));
      return Math.round10(pumpShutoffConverted, -2);
    }),

    shutoffPressure: Ember.computed('pump', 'viscosity', 'specificGravity', 'cQArray.[]', 'cHArray.[]', 'headUnit', function () {
      var pump_shutoff = void 0;
      if (this.get('viscosity') > 1) {
        pump_shutoff = this.headSpline(this.get('pump'), this.get('pump.minFlow'), this.get('cQArray'), this.get('cHArray'));
      } else {
        pump_shutoff = this.headSpline(this.get('pump'), this.get('pump.minFlow'));
      }
      var pumpShutoffPSI = new _conversions.Head('FT', pump_shutoff, this.get('specificGravity')).convertTo('PSI');
      var pumpHeadConverted = new _conversions.Pressure('PSI', pumpShutoffPSI, this.get('specificGravity')).convertTo(this.get('pressureUnit'));
      return Math.round10(pumpHeadConverted, -2);
    }),

    CH: function CH(b, bepFlow, flow, qCoeff) {
      if (b <= 1) {
        return 1;
      } else if (b >= 40) {
        return 0;
      } else {
        console.log('bepFlow', b, bepFlow, flow, qCoeff, '1 - (1 - ' + qCoeff + ') * Math.pow(' + flow + ' / ' + bepFlow + ', 0.75)');
        return 1 - (1 - qCoeff) * Math.pow(flow / bepFlow, 0.75);
      }
    },
    LinSpline: function LinSpline(x, y, xin) {
      var x1 = 0.0;
      var x2 = 0.0;
      var y1 = 0.0;
      var y2 = 0.0;
      var slope = 0.0;
      if (xin < x[0]) {
        return y[0];
      }
      if (xin > x[x.length - 1]) {
        return y[y.length - 1];
      }
      var i = 1;
      while (i <= x.length - 1) {
        if (xin <= x[i]) {
          x1 = x[i - 1];
          x2 = x[i];
          y1 = y[i - 1];
          y2 = y[i];
          slope = (y2 - y1) / (x2 - x1);
          break;
        }
        i++;
      }
      return y1 + slope * (xin - x1);
    },
    headSpline: function headSpline(pump, inputFlow, xArrayArg, yArrayArg) {
      if (inputFlow >= pump.get('minFlow') && inputFlow <= pump.get('maxFlow')) {
        var xArray = xArrayArg || pump.get('qhns').mapBy('q');
        var yArray = yArrayArg || pump.get('qhns').mapBy('h');
        return this.LinSpline(xArray, yArray, inputFlow);
      } else {
        return 0;
      }
    },
    effSpline: function effSpline(pump, inputFlow, xArrayArg, yArrayArg) {
      if (inputFlow >= pump.get('minFlow') && inputFlow <= pump.get('maxFlow')) {
        var xArray = xArrayArg || pump.get('qhns').mapBy('q');
        var yArray = yArrayArg || pump.get('qhns').mapBy('eff');
        return this.LinSpline(xArray, yArray, inputFlow);
      } else {
        return 0;
      }
    },
    powerSpline: function powerSpline(pump, inputFlow, xArrayArg, yArrayArg) {
      if (inputFlow >= pump.get('minFlow') && inputFlow <= pump.get('maxFlow')) {
        var xArray = xArrayArg || pump.get('qhns').mapBy('q');
        var yArray = yArrayArg || pump.get('qhns').mapBy('power');
        return this.LinSpline(xArray, yArray, inputFlow);
      } else {
        return 0;
      }
    },
    nolPowerSpline: function nolPowerSpline(pump, inputFlow, xArrayArg, yArrayArg) {
      var dummy = void 0;
      var temp = 0;
      var qi = pump.get('minFlow');
      var qArray = xArrayArg || pump.get('qhns').mapBy('q');
      var powArray = yArrayArg || pump.get('qhns').mapBy('power');

      while (qi < pump.get('maxFlow')) {
        dummy = this.LinSpline(qArray, powArray, qi);
        if (dummy > temp) {
          temp = dummy;
        }
        qi = qi + 2.0;
      }

      dummy = this.LinSpline(qArray, powArray, pump.get('maxFlow'));
      if (dummy > temp) {
        temp = dummy;
      }

      return temp;
    },
    npshSpline: function npshSpline(pump, inputFlow) {
      if (inputFlow >= pump.get('minFlow') && inputFlow <= pump.get('maxFlow')) {
        var qArray = pump.get('qhns').mapBy('q');
        var npshArray = pump.get('qhns').mapBy('npsh');
        return this.LinSpline(qArray, npshArray, inputFlow);
      } else {
        return 0;
      }
    }
  });
});