define('fti-pump-selector/controllers/pump-selector-results/curve-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    pumpApplication: Ember.inject.service(),

    design_head: Ember.computed.readOnly('pumpApplication.design_head'),
    design_flow: Ember.computed.readOnly('pumpApplication.design_flow'),
    flowUnit: Ember.computed.readOnly('pumpApplication.flow_unit'),
    headUnit: Ember.computed.readOnly('pumpApplication.head_unit'),
    powerUnit: Ember.computed.readOnly('pumpApplication.power_unit'),
    specificGravity: Ember.computed.readOnly('pumpApplication.specific_gravity'),
    viscosity: Ember.computed.readOnly('pumpApplication.viscosity'),
    viscosityUnit: Ember.computed.readOnly('pumpApplication.viscosity_unit.value'),
    viscosityUnitLabel: Ember.computed.readOnly('pumpApplication.viscosity_unit.label')
  });
});