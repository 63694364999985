define('fti-pump-selector/utils/unit-system', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var UNITS = Object.freeze({
    ENG: ['GPM', 'FT', 'HP', 'PSI'],
    SI1: ['CMPH', 'M', 'KW', 'KPA'],
    SI2: ['CMPH', 'M', 'KW', 'BAR'],
    SI3: ['LPM', 'M', 'KW', 'KPA'],
    SI4: ['LPM', 'M', 'KW', 'BAR']
  });

  var UnitSystem = function () {
    function UnitSystem() {
      _classCallCheck(this, UnitSystem);
    }

    _createClass(UnitSystem, [{
      key: 'init',
      value: function init(unit_num) {
        this.unitNum = unit_num;
      }
    }, {
      key: 'flowUnit',
      get: function get() {
        return UNITS[this.unitNum][0];
      }
    }, {
      key: 'headUnit',
      get: function get() {
        return UNITS[this.unitNum][1];
      }
    }, {
      key: 'powerUnit',
      get: function get() {
        return UNITS[this.unitNum][2];
      }
    }, {
      key: 'pressureUnit',
      get: function get() {
        return UNITS[this.unitNum][3];
      }
    }]);

    return UnitSystem;
  }();

  exports.default = UnitSystem;
});