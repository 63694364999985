define('fti-pump-selector/routes/drum-pump-details', ['exports', 'fti-pump-selector/routes/fti-route'], function (exports, _ftiRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ftiRoute.default.extend({
    _pageTitle: 'Drum Pump Selector',
    _title: 'FTI Drum Pump Selector',
    modelFragment: '',

    pageTitle: Ember.computed('_pageTitle', 'modelFragment', function () {
      if (!Ember.isNone(this.get('modelFragment'))) {
        return this.get('_pageTitle') + ' - ' + this.get('modelFragment');
      } else {
        return this.get('_pageTitle');
      }
    }),
    title: Ember.computed('_title', 'modelFragment', function () {
      if (!Ember.isNone(this.get('modelFragment'))) {
        return this.get('_title') + ' - ' + this.get('modelFragment');
      } else {
        return this.get('_title');
      }
    }),

    model: function model(params) {
      return this.get('store').find('drum-pump-model', params.pump_id);
    },
    afterModel: function afterModel(model) {
      // Set model number to fragment
      this.set('modelFragment', model.get('modelNumber'));
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('static/title-intro-only', {
        into: 'application',
        outlet: 'introText',
        controller: 'application'
      });
    }
  });
});