define('fti-pump-selector/routes/register', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('user');
    },


    actions: {
      register: function register(model) {
        var _this = this;

        model.save().then(function () {
          _this.transitionTo('waiting-approval');
        }).catch(function (err) {
          console.error(err); // eslint-disable-line
        });
      }
    }
  });
});