define('fti-pump-selector/models/email-quote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    partNumber: '',
    fluid: '',
    temperature: '',
    temperatureUnit: '',
    specificGravity: '',
    viscosity: '',
    speed: '',
    designHead: '',
    designFlow: '',
    pumpPrice: '',
    basePlatePrice: '',
    motorPrice: '',
    accessoriesPrice: '',
    totalPrice: '',

    bodyString: function bodyString() {
      var string = '';

      string += 'Part Number ' + this.get('partNumber') + '\n';
      string += 'Fluid: ' + this.get('fluid') + '\n';
      string += 'Temperature: ' + this.get('temperature') + ' ' + this.get('temperatureUnit') + '\n';
      string += 'Specific Gravity: ' + this.get('specificGravity') + '\n';
      string += 'Viscosity: ' + this.get('viscosity') + '\n';
      string += 'Speed: ' + this.get('speed') + '\n';
      string += 'Design Head: ' + this.get('designHead') + '\n';
      string += 'Design Flow: ' + this.get('designFlow') + '\n';

      if (!Ember.isEmpty(this.get('pumpPrice'))) {
        string += 'Pump Price: ' + this.get('pumpPrice') + '\n';
      }
      if (!Ember.isEmpty(this.get('basePlatePrice'))) {
        string += 'Base Plate Price: ' + this.get('basePlatePrice') + '\n';
      }
      if (!Ember.isEmpty(this.get('motorPrice'))) {
        string += 'Motor Price: ' + this.get('motorPrice') + '\n';
      }
      if (!Ember.isEmpty(this.get('accessoriesPrice'))) {
        string += 'Accessories Price: ' + this.get('accessoriesPrice') + '\n';
      }
      if (!Ember.isEmpty(this.get('totalPrice'))) {
        string += 'Total Price: ' + this.get('totalPrice') + '\n';
      }

      return string;
    },
    render: function render() {
      return this.bodyString();
    }
  });
});