define('fti-pump-selector/components/tri-state', ['exports', 'ember-tri-state/components/tri-state'], function (exports, _triState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _triState.default;
    }
  });
});