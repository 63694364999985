define('fti-pump-selector/macros/default-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (defaultValue) {
    return Ember.computed({
      get: function get(key) {
        if (!Ember.isNone(this.get('_' + key))) {
          return this.get('_' + key);
        } else {
          return this.get(defaultValue);
        }
      },
      set: function set(key, value) {
        this.set('_' + key, value);
        return this.get('_' + key);
      }
    });
  };
});