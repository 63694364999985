define('fti-pump-selector/routes/pump-selector-results/part-number', ['exports', 'fti-pump-selector/models/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    settings: _settings.default.create(),
    pumpApplication: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      // Pull back the part number controller and set properties to null in order
      // to have them cleared before the "next" run (a.k.a. all runs)
      // This shouldn't be removed on refactor unless properties upon controllers
      // being singletons have been dealt with.
      // To check if this is still an issue, run a test going to part number
      // generation with motors included and make sure a motor has been selected.
      // Go back a step and then turn off 'Suggest Motor'. Go to the part number
      // generation, and is there a motor selected when there shouldn't be?
      var psrpnController = this.controllerFor('pump-selector-results/part-number');
      psrpnController.set('motor', null);
      psrpnController.set('magnet', null);
      psrpnController.set('motorAdapter', null);
    },
    model: function model(params) {
      return this.store.findRecord('pump', params.pump_id, {
        reload: true,
        backgroundReload: false
      });
    },
    afterModel: function afterModel() {
      var _this = this;

      this._super.apply(this, arguments);
      // TODO: REMOVE THIS! This is a bad smell and should be refactored
      // when the refactoring for appState / pumpQuery is done.
      var promises = {
        materialMaxTemps: this.store.peekAll('material-max-temp'),
        impellerDashes: this.store.findAll('impeller-dash')
      };

      if (this.get('pumpApplication.include_motors')) {
        promises.motors = this.store.findAll('dbsp-motor');
      }

      return Ember.RSVP.hash(promises).then(function (hash) {
        _this.set('materialMaxTemps', hash.materialMaxTemps);
        _this.set('impellerDashes', hash.impellerDashes);

        if (!Ember.isNone(hash.motors)) {
          _this.set('motors', hash.motors);
        }
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('materialMaxTemps', this.get('materialMaxTemps'));
      controller.set('impellerDashes', this.get('impellerDashes'));

      if (!Ember.isNone(this.get('motors'))) {
        controller.set('motors', this.get('motors'));
      }

      if (this.get('pumpApplication.include_motors')) {
        controller.setProperties({
          magnet: controller.get('bestFitAdapterMagnetMotorResult.magnet'),
          motorAdapter: controller.get('bestFitAdapterMagnetMotorResult.adapter'),
          motor: controller.get('bestFitAdapterMagnetMotorResult.motor')
        });
      }
    }
  });
});