define('fti-pump-selector/routes/pump-selector-results/uc-part-number', ['exports', 'fti-pump-selector/models/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    settings: _settings.default.create(),
    pumpApplication: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      // Pull back the part number controller and set properties to null in order
      // to have them cleared before the "next" run (a.k.a. all runs)
      // This shouldn't be removed on refactor unless properties upon controllers
      // being singletons have been dealt with.
      // To check if this is still an issue, run a test going to part number
      // generation with motors included and make sure a motor has been selected.
      // Go back a step and then turn off 'Suggest Motor'. Go to the part number
      // generation, and is there a motor selected when there shouldn't be?
      var psrucpnController = this.controllerFor('pump-selector-results/uc-part-number');
      psrucpnController.set('motor', null);
      psrucpnController.set('motorAdapter', null);
    },
    model: function model(params) {
      return this.store.findRecord('pump', params.pump_id, {
        reload: true,
        backgroundReload: false
      });
    },
    afterModel: function afterModel() {
      var _this = this;

      this._super.apply(this, arguments);

      var promises = {
        materialMaxTemps: this.store.findAll('material-max-temp')
      };

      // if(this.get('pumpApplication.include_motors')) {
      //   // Alex Ambrose - 11-16-2017
      //   // Need to grab all motors because the motorAdapter compatiableMotors field contains both kinds of motors.
      //   // In reality, this needs to be two separate fields in order to slim down loading time.
      //   // This can be fixed later using some Ruby to create another field and then sort out motors based on the 'type' field
      //   // into their appropriate field.
      //   // promises.dbspMotors = this.store.findAll('dbsp-motor');
      //   promises.motors = this.store.findAll('uc-motor');
      // }

      return Ember.RSVP.hash(promises).then(function (hash) {
        _this.set('materialMaxTemps', hash.materialMaxTemps);

        // if (!isNone(hash.motors)) {
        //   this.set('motors', hash.motors);
        // }
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('materialMaxTemps', this.get('materialMaxTemps'));

      // if (!isNone(this.get('motors'))) {
      //   controller.set('motors', this.get('motors'));
      // }

      if (this.get('pumpApplication.include_motors')) {
        controller.setProperties({
          motorAdapter: controller.get('bestFitAdapterMotorResult.adapter'),
          motor: controller.get('bestFitAdapterMotorResult.motor')
        });
      }
    }
  });
});