define('fti-pump-selector/models/drum-pump-model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    modelNumber: (0, _attr.default)('string'),
    partNumber: (0, _attr.default)('string'),
    value: (0, _attr.default)('number'),
    pumpLength: (0, _attr.default)('number'),
    tubeMaterial: (0, _attr.default)('string'),
    tubeDiameter: (0, _attr.default)('number'),
    dischargeSize: (0, _attr.default)('number'),
    dischargeType: (0, _attr.default)('string'),
    oringMaterial: (0, _attr.default)('string'),
    statorMaterial: (0, _attr.default)('string'),
    shaftMaterial: (0, _attr.default)('string'),
    internalMaterials: (0, _attr.default)('string'),
    maxHeadElecMotor: (0, _attr.default)('number'),
    maxHeadAirMotor: (0, _attr.default)('number'),
    maxHead12vMotor: (0, _attr.default)('number'),
    maxFlowElecMotor: (0, _attr.default)('number'),
    maxFlowAirMotor: (0, _attr.default)('number'),
    maxFlow12vMotor: (0, _attr.default)('number'),
    maxTemperature: (0, _attr.default)('number'),
    minTemperature: (0, _attr.default)('number'),
    maxSpecificGravity: (0, _attr.default)('number'),
    maxViscosityElecMotor: (0, _attr.default)('number'),
    maxViscosityAirMotor: (0, _attr.default)('number'),
    maxViscosity12vMotor: (0, _attr.default)('number'),
    minimumViscosity: (0, _attr.default)('number'),
    boxDimensionWidth: (0, _attr.default)('number'),
    boxDimensionHeight: (0, _attr.default)('number'),
    boxDimensionDepth: (0, _attr.default)('number'),
    weight: (0, _attr.default)('number'),
    isSanitary: (0, _attr.default)('boolean'),
    isHazardousAreaSafe: (0, _attr.default)('boolean'),
    isAtexPump: (0, _attr.default)('boolean'),
    tagline: (0, _attr.default)('string'),

    hasElectricAnyMotors: (0, _attr.default)('boolean'),
    hasElectric115Motors: (0, _attr.default)('boolean'),
    hasElectric230Motors: (0, _attr.default)('boolean'),
    hasElectricXpAnyMotors: (0, _attr.default)('boolean'),
    hasElectricXp115Motors: (0, _attr.default)('boolean'),
    hasElectricXp230Motors: (0, _attr.default)('boolean'),
    hasAirMotors: (0, _attr.default)('boolean'),
    hasBatteryMotors: (0, _attr.default)('boolean'),
    hasBattery12Motors: (0, _attr.default)('boolean'),
    hasBattery115Motors: (0, _attr.default)('boolean'),
    hasBattery230Motors: (0, _attr.default)('boolean'),

    motors: (0, _relationships.hasMany)('drum-pump-motor'),
    compatibleChemicals: (0, _relationships.hasMany)('drum-pump-chemical')
  });
});