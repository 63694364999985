define('fti-pump-selector/controllers/application', ['exports', 'ember-sweetalert', 'fti-pump-selector/config/environment'], function (exports, _emberSweetalert, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    appState: Ember.inject.service(),
    pumpApplication: Ember.inject.service(),
    ftiAnonHeaderLogoUrl: Ember.computed('config', function () {
      return _environment.default.APP.staticEndpoint + '/images/finishthompson_header_logo.png';
    }),

    actions: {
      newDesignPoint: function newDesignPoint() {
        this.get('pumpApplication').resetToDefaults();
        this.transitionToRoute('pump-selector');
      },
      navigateToHelp: function navigateToHelp() {
        (0, _emberSweetalert.default)({
          title: 'Are you sure?',
          text: 'You will be navigating away from the selector and will lose current design point.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes'
        }).then(function (result) {
          if (result.value) {
            window.location = '/help';
          }
        }).catch(_emberSweetalert.default.noop);
      }
    }
  });
});