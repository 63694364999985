define('fti-pump-selector/controllers/pump-selector-results/part-number', ['exports', 'ember-sweetalert', 'fti-pump-selector/utils/conversions', 'fti-pump-selector/models/pump-selection'], function (exports, _emberSweetalert, _conversions, _pumpSelection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.adapterMagnetSortKeys = ['magnetDash:asc'];
      this.adapterMagnetMotorSortKeys = ['overallSelectionRank:asc'];
      this.initialAdapterMagnetMotorSortKeys = ['overallSelectionRank:asc'];
    },


    appState: Ember.inject.service(),
    pumpApplication: Ember.inject.service(),

    hardwareMaterial: null,
    magnet: Ember.computed.oneWay('defaultMagnetFound'),
    motorAdapter: null,
    motor: null,
    baseplate: null,
    isTitaniumHardware: false,
    motorSafetyFeatures: Ember.computed.alias('model.motorSafetyFeatures'),
    baseplates: Ember.computed.alias('model.baseplates'),
    includeMotors: Ember.computed.alias('pumpApplication.include_motors'),
    isShowingMAModal: false,
    isShowingMAMModal: false,
    selectedResult: null,
    power_unit: Ember.computed.oneWay('pumpApplication.power_unit'),

    pumpSelection: Ember.computed('model', 'partNumber', function () {
      return _pumpSelection.default.create({
        pump: this.get('model'),
        partNumber: this.get('partNumber'),
        motorSafetyFeature: this.get('motorSafetyFeature'),
        vaporSealMaterial: this.get('vaporSealMaterial'),
        housingMaterial: this.get('housingMaterial'),
        bushingMaterial: this.get('bushingMaterial'),
        shaftMaterial: this.get('shaftMaterial'),
        oringMaterial: this.get('oringMaterial'),
        connection: this.get('connection'),
        magnet: this.get('magnet'),
        baseplate: this.get('baseplate'),
        titanium: this.get('isTitaniumHardware'),
        motor: this.get('motor'),
        motorAdapter: this.get('motorAdapter'),
        clampRing: this.get('clampRing')
      });
    }),

    // Alexander 5/14/2015
    // This could have been kept simple like the oneWay properties above, but ATEX requires PVDF housing materials.
    housingMaterial: Ember.computed('_housingMaterial', 'defaultHousingMaterialFound', 'motorSafetyFeature', 'housingMaterials.[]', {
      get: function get() {
        // Check to see if ATEX is set, if it is, always return PVDF housing material
        if (this.get('motorSafetyFeature.name') === 'ATEX') {
          return this.get('housingMaterials').findBy('name', 'PVDF');
        }

        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_housingMaterial'))) {
          return this.get('_housingMaterial');
        }

        var defaultExistsInMaterialsList = this.get('housingMaterials').findBy('id', this.get('defaultHousingMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultHousingMaterialFound');
        } else {
          return this.get('housingMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_housingMaterial', value);
        return this.get('_housingMaterial');
      }
    }),

    motorSafetyFeature: Ember.computed('pumpApplication.motor_type', {
      get: function get() {
        // Check internal property first to see if it has a value, then return it
        if (!Ember.isNone(this.get('_motorSafetyFeature'))) {
          return this.get('_motorSafetyFeature');
        }

        // If nothing has been set by the user, return non-sparking if explosion_proof
        // has been selected.
        if (this.get('pumpApplication.motor_type.value') === 'explosion_proof') {
          return this.get('motorSafetyFeatures').findBy('name', 'Non-Sparking');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        var previous = this.get('_motorSafetyFeature');
        // If the motor type is explosion proof, we need to always have a safety feature enabled;
        // either ATEX or non-sparking.
        if (this.get('pumpApplication.motor_type.value') === 'explosion_proof' && Ember.isNone(value)) {
          (0, _emberSweetalert.default)({
            title: 'Must have safety feature',
            text: 'You have selected explosion proof for motors. This requires either non-sparking or ATEX.',
            type: 'error',
            confirmButtonText: 'Try again'
          }).then(function () {
            // Run in a run loop because this is in the controller instead of a view or component.
            Ember.run.later(function () {
              Ember.$('.motor-safety-feature-select').focus();
            }, 2);
          }).catch(_emberSweetalert.default.noop);

          if (!Ember.isNone(previous)) {
            this.set('_motorSafetyFeature', previous);
          } else {
            if (this.get('pumpApplication.motor_type.value') === 'explosion_proof') {
              this.set('_motorSafetyFeature', this.get('motorSafetyFeatures').findBy('name', 'Non-Sparking'));
            }
          }
          // Return normal valid value instead
        } else {
          this.set('_motorSafetyFeature', value);
        }
        return this.get('_motorSafetyFeature');
      }
    }),

    bushingMaterial: Ember.computed('_bushingMaterial', 'defaultBushingMaterialFound', 'bushingMaterials.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_bushingMaterial'))) {
          return this.get('_bushingMaterial');
        }

        var defaultExistsInMaterialsList = this.get('bushingMaterials').findBy('id', this.get('defaultBushingMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultBushingMaterialFound');
        } else {
          return this.get('bushingMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_bushingMaterial', value);
        return this.get('_bushingMaterial');
      }
    }),

    shaftMaterial: Ember.computed('_shaftMaterial', 'defaultShaftMaterialFound', 'shaftMaterials.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_shaftMaterial'))) {
          return this.get('_shaftMaterial');
        }

        var defaultExistsInMaterialsList = this.get('shaftMaterials').findBy('id', this.get('defaultShaftMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultShaftMaterialFound');
        } else {
          return this.get('shaftMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_shaftMaterial', value);
        return this.get('_shaftMaterial');
      }
    }),

    oringMaterial: Ember.computed('_oringMaterial', 'defaultOringMaterialFound', 'oringMaterials.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_oringMaterial'))) {
          return this.get('_oringMaterial');
        }

        var defaultExistsInMaterialsList = this.get('oringMaterials').findBy('id', this.get('defaultOringMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultOringMaterialFound');
        } else {
          return this.get('oringMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_oringMaterial', value);
        return this.get('_oringMaterial');
      }
    }),

    connection: Ember.computed('_connection', 'defaultConnectionFound', 'connections.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_connection'))) {
          return this.get('_connection');
        }

        if (this.get('pumpApplication.frequency.value') === 50) {
          return this.get('connections').findBy('dash_number', 'B');
        } else {
          var defaultExistsInMaterialsList = this.get('connections').findBy('id', this.get('defaultConnectionFound.id'));
          if (defaultExistsInMaterialsList) {
            return this.get('defaultConnectionFound');
          } else {
            return this.get('connections.firstObject');
          }
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_connection', value);
        return this.get('_connection');
      }
    }),

    vaporSealMaterial: Ember.computed('_vaporSealMaterial', 'defaultVaporSealMaterialFound', 'vaporSealMaterials.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_vaporSealMaterial'))) {
          return this.get('_vaporSealMaterial');
        }

        var defaultExistsInMaterialsList = this.get('vaporSealMaterials').findBy('id', this.get('defaultVaporSealMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultVaporSealMaterialFound');
        } else {
          return this.get('vaporSealMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_vaporSealMaterial', value);
        return this.get('_vaporSealMaterial');
      }
    }),

    clampRing: Ember.computed('_clampRing', 'defaultClampRingFound', 'clampRings.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_clampRing'))) {
          return this.get('_clampRing');
        }

        var defaultExistsInMaterialsList = this.get('clampRings').findBy('id', this.get('defaultClampRingFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultClampRingFound');
        } else {
          return this.get('clampRings.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_clampRing', value);
        return this.get('_clampRing');
      }
    }),

    dbIncompatibleAdapterType: Ember.computed('model', function () {
      if (this.get('model.modelNumber') === 'DB3' || this.get('model.modelNumber') === 'DB4' || this.get('model.modelNumber') === 'DB5') {
        if (this.get('pumpApplication.motor_adapter.value') === 'nema') {
          return true;
        }
      }

      return false;
    }),

    partNumber: Ember.computed('modelNumber', 'housingMaterial', 'bushingMaterial', 'oringMaterial', 'connection', 'magnet', 'motorAdapter', 'shaftMaterial', 'hardwareMaterial', 'vaporSealMaterial', 'motorSafetyFeature', 'baseplate', 'motor', 'clampRing', function () {
      var partNumberStr = '';

      // Add model number (should always exist)
      partNumberStr += this.get('modelNumber');

      // Add housing string part
      if (!Ember.isNone(this.get('housingMaterial.dash_number'))) {
        partNumberStr += this.get('housingMaterial.dash_number');
      }

      // Add W if wet end only
      if (this.get('pumpApplication.wet_end_only')) {
        partNumberStr += '-W';
      }

      // Add bushing string part
      if (!Ember.isNone(this.get('bushingMaterial.dash_number')) && this.get('bushingMaterial.id') !== this.get('model.defaultBushingMaterial').toString()) {
        partNumberStr += '-' + this.get('bushingMaterial.dash_number');
      }

      // Add o-ring string part
      if (!Ember.isNone(this.get('oringMaterial.dash_number')) && this.get('oringMaterial.id') !== this.get('model.defaultOringMaterial').toString()) {
        partNumberStr += '-' + this.get('oringMaterial.dash_number');
      }

      // Add connection string part
      if (!Ember.isNone(this.get('connection.dash_number')) && this.get('connection.id') !== this.get('model.defaultConnection').toString()) {
        partNumberStr += '-' + this.get('connection.dash_number');
      }

      // Impeller Dash string part
      if (!Ember.isNone(this.get('impellerDash')) && this.get('impellerDash.dash') !== '1') {
        partNumberStr += '-' + this.get('impellerDash.dash');
      }

      // Add magnet part
      if (!Ember.isNone(this.get('magnet.dash_number')) && this.get('magnet.id') !== this.get('model.defaultMagnet').toString()) {
        partNumberStr += '-' + this.get('magnet.dash_number');
      }

      // Add motor adapter part
      if (!Ember.isNone(this.get('motorAdapter.dash_number')) && this.get('motorAdapter.id') !== this.get('model.defaultMotorAdapter').toString()) {
        partNumberStr += '-' + this.get('motorAdapter.dash_number');
      }

      // Add shaft material shaft
      if (!Ember.isNone(this.get('shaftMaterial.dash_number')) && this.get('shaftMaterial.id') !== this.get('model.defaultShaftMaterial').toString()) {
        partNumberStr += '-' + this.get('shaftMaterial.dash_number');
      }

      // Add hardware part
      if (!Ember.isNone(this.get('hardwareMaterial'))) {
        partNumberStr += '-' + this.get('hardwareMaterial.dash_number');
      }

      // Add vapor seal material part
      if (!Ember.isNone(this.get('vaporSealMaterial')) && this.get('vaporSealMaterial.id') !== this.get('model.defaultSealMaterial').toString()) {
        partNumberStr += '-' + this.get('vaporSealMaterial.dash_number');
      }

      // Add motor safety feature part
      if (!Ember.isNone(this.get('motorSafetyFeature.dash_number'))) {
        partNumberStr += '-' + this.get('motorSafetyFeature.dash_number');
      }

      // Add motor part
      if (!Ember.isNone(this.get('motor'))) {
        partNumberStr += '-' + this.get('motor.dash_number');
      }

      // Add clamp ring
      if (!Ember.isNone(this.get('clampRing'))) {
        partNumberStr += '-' + this.get('clampRing.dash_number');
      }

      // Add baseplate part
      if (!Ember.isNone(this.get('baseplate.dash_number'))) {
        partNumberStr += ' WITH ' + this.get('baseplate.dash_number') + ' baseplate';
      }

      return partNumberStr;
    }),

    availableBaseplates: Ember.computed('housingMaterial', 'baseplates.[]', function () {
      var currentHousing = this.get('housingMaterial.id');

      if (!Ember.isEmpty(this.get('baseplates'))) {
        return this.get('baseplates').filter(function (item) {
          var hms = item.get('compatiableHousingMaterials');
          var matches = hms.filter(function (item) {
            if (item.get('id') === currentHousing) {
              return item;
            }
          });
          return matches.length;
        });
      }
    }),

    // TODO: Investigate usage -- Pretty sure this isn't used anymore?
    availableMotors: Ember.computed('pumpApplication.include_motors', 'motors.[]', function () {
      var _this = this;

      var chosenPower = 0;

      if (!Ember.isBlank(this.get('pumpApplication.motor_type.value')) && !Ember.isBlank(this.get('pumpApplication.motor_phase.value')) && !Ember.isBlank(this.get('pumpApplication.motor_adapter.value'))) {
        if (this.get('pumpApplication.motor_size') === 'design_point') {
          chosenPower = this.get('model.pumpCalculations.power');
        }

        if (this.get('pumpApplication.motor_size') === 'non_overloading') {
          chosenPower = this.get('model.pumpCalculations.nolPower');
        }

        return this.get('motors').filter(function (motor) {
          if (motor.get('motorTypeCode').toLowerCase() === _this.get('pumpApplication.motor_type.value') && motor.get('phase') === _this.get('pumpApplication.motor_phase.value') && motor.get('adapterType').toLowerCase() === _this.get('pumpApplication.motor_adapter.value') && motor.get('power') >= chosenPower) {
            return motor;
          }
        });
      }
    }),

    fluid: Ember.computed.oneWay('pumpApplication.fluid'),
    temperature: Ember.computed.oneWay('pumpApplication.temperature'),
    temperature_unit: Ember.computed.oneWay('pumpApplication.temperature_unit'),

    materialMaxTempsFiltered: Ember.computed('materialMaxTemps.@each.fluid', 'fluid', function () {
      var _this2 = this;

      var mmt = this.get('materialMaxTemps');

      if (!Ember.isBlank(this.get('fluid'))) {
        mmt = mmt.filter(function (n) {
          return n.get('fluid').trim() === _this2.get('fluid.name').trim();
        });
      }

      return mmt;
    }),

    model_id: Ember.computed.alias('model'),
    modelNumber: Ember.computed.alias('model.modelNumber'),

    bushingMaterials: Ember.computed('model.bushingMaterials.@each.name', 'temperature_unit', 'temperature', 'materialMaxTempsFiltered.[]', function () {
      var _this3 = this;

      var bushingMaterials = this.get('model.bushingMaterials');
      var compareTemp = new _conversions.Temperature(this.get('temperature_unit'), this.get('temperature')).convertTo('F');

      bushingMaterials = bushingMaterials.filter(function (m) {
        var fluidMaxTemp = _this3.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
        var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');

        if (compatibleTemp) {
          return m;
        }
      });

      return bushingMaterials;
    }),
    defaultBushingMaterialFound: Ember.computed('bushingMaterials', 'model.defaultBushingMaterial', function () {
      var bushingMaterials = this.get('bushingMaterials');
      var defaultBushingMaterial = this.get('model.defaultBushingMaterial');
      var defaultOptionFound = bushingMaterials.findBy('id', defaultBushingMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    shaftMaterials: Ember.computed('model.shaftMaterials.@each.name', 'temperature_unit', 'temperature', 'materialMaxTempsFiltered.[]', function () {
      var _this4 = this;

      var shaftMaterials = this.get('model.shaftMaterials');
      var compareTemp = new _conversions.Temperature(this.get('temperature_unit'), this.get('temperature')).convertTo('F');

      shaftMaterials = shaftMaterials.filter(function (m) {
        var fluidMaxTemp = _this4.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
        var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');

        if (compatibleTemp) {
          return m;
        }
      });

      return shaftMaterials;
    }),
    defaultShaftMaterialFound: Ember.computed('shaftMaterials', 'model.defaultShaftMaterial', function () {
      var shaftMaterials = this.get('shaftMaterials');
      var defaultShaftMaterial = this.get('model.defaultShaftMaterial');
      var defaultOptionFound = shaftMaterials.findBy('id', defaultShaftMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    oringMaterials: Ember.computed('model.oringMaterials.@each.name', 'temperature_unit', 'temperature', 'materialMaxTempsFiltered.[]', function () {
      var _this5 = this;

      var oringMaterials = this.get('model.oringMaterials');
      var compareTemp = new _conversions.Temperature(this.get('temperature_unit'), this.get('temperature')).convertTo('F');

      oringMaterials = oringMaterials.filter(function (m) {
        var fluidMaxTemp = _this5.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
        var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');

        if (compatibleTemp) {
          return m;
        }
      });

      return oringMaterials;
    }),
    defaultOringMaterialFound: Ember.computed('oringMaterials', 'model.defaultOringMaterial', function () {
      var oringMaterials = this.get('oringMaterials');

      var defaultOringMaterial = this.get('model.defaultOringMaterial');
      var defaultOptionFound = oringMaterials.findBy('id', defaultOringMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    housingMaterials: Ember.computed('model.housingMaterials.@each.name', 'temperature_unit', 'temperature', 'materialMaxTempsFiltered.[]', function () {
      var _this6 = this;

      var housingMaterials = this.get('model.housingMaterials');
      var compareTemp = new _conversions.Temperature(this.get('temperature_unit'), this.get('temperature')).convertTo('F');

      housingMaterials = housingMaterials.filter(function (m) {
        if (m.get('name') === 'Polypropylene') {
          return m;
        }

        var fluidMaxTemp = _this6.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
        var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');

        if (compatibleTemp) {
          return m;
        }
      });

      return housingMaterials;
    }),
    defaultHousingMaterialFound: Ember.computed('housingMaterials', 'model.defaultHousingMaterial', function () {
      var housingMaterials = this.get('housingMaterials');
      var defaultHousingMaterial = this.get('model.defaultHousingMaterial');
      var defaultOptionFound = housingMaterials.findBy('id', defaultHousingMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    hardwareMaterials: Ember.computed('model.hardwareMaterials.@each.name', 'temperature_unit', 'temperature', 'materialMaxTempsFiltered.[]', function () {
      var _this7 = this;

      var hardwareMaterials = this.get('model.hardwareMaterials');
      var compareTemp = new _conversions.Temperature(this.get('temperature_unit'), this.get('temperature')).convertTo('F');
      console.log('Hardware Materials:', hardwareMaterials, compareTemp);
      hardwareMaterials = hardwareMaterials.filter(function (m) {
        var fluidMaxTemp = _this7.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
        var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');
        var isDefaultOption = m.get('id') === _this7.get('model.defaultHardwareMaterial').toString();

        if (compatibleTemp && !isDefaultOption) {
          return m;
        }
      });

      return hardwareMaterials;
    }),

    vaporSealMaterials: Ember.computed.alias('model.sealMaterials'),
    defaultSealMaterialFound: Ember.computed('vaporSealMaterials', 'model.defaultSealMaterial', function () {
      var vaporSealMaterials = this.get('vaporSealMaterials');
      var defaultSealMaterial = this.get('model.defaultSealMaterial');
      var defaultOptionFound = vaporSealMaterials.findBy('id', defaultSealMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    connections: Ember.computed.alias('model.connections'),
    defaultConnectionFound: Ember.computed('connections', 'model.defaultConnection', function () {
      var connections = this.get('connections');
      var defaultConnection = this.get('model.defaultConnection');
      var defaultOptionFound = connections.findBy('id', defaultConnection.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    clampRings: Ember.computed.alias('model.clampRings'),
    defaultClampRingFound: Ember.computed('clampRings', 'model.defaultClampRing', function () {
      var clampRings = this.get('clampRings');
      var defaultClampRing = this.get('model.defaultClampRing');
      var defaultOptionFound = clampRings.findBy('id', defaultClampRing.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    magnets: Ember.computed.alias('model.magnets'),
    defaultMagnetFound: Ember.computed('magnets', 'model.defaultMagnet', function () {
      var magnets = this.get('magnets');
      var defaultMagnet = this.get('model.defaultMagnet');
      var defaultOptionFound = magnets.findBy('id', defaultMagnet.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    impellerDash: Ember.computed('model.{dashNumber,modelNumber,diameter,closedImpeller,impellerDashes}', function () {
      if (!Ember.isBlank(this.get('model.dashNumber'))) {
        return { dash: this.get('model.dashNumber') };
      } else {
        var modelRoot = this.get('model.modelNumber');
        var modelDiameter = this.get('model.diameter');
        var modelDiameterFixed = Number(modelDiameter.toFixed(2));
        var modelClosedImpeller = this.get('model.closedImpeller');
        var impellerDashes = this.get('impellerDashes');

        return impellerDashes.find(function (impeller) {
          if (impeller.get('root') === modelRoot && (
          // This seems really silly but ultimately is a fix to repair rounding issues
          // resulting from bad data. Impellers were originally kept with up to three
          // digits of precision and the table was rounded to two for some reason.
          // Do not remove this. Data will always be broken unless old selector is dead.
          impeller.get('diameter') === modelDiameter || impeller.get('diameter') === modelDiameterFixed) && impeller.get('closedImpeller') === modelClosedImpeller) {
            return impeller;
          }
        });
      }
    }),

    adapterMagnetResults: Ember.computed('model.magnets.[]', function () {
      var _this8 = this;

      var _resultsList = [];
      var _count = 0;

      // Magnets need to be able to handle the required operating HP of the design
      var magnetsFiltered = this.get('model.magnets').filter(function (magnet) {
        return magnet.get('maxHp') >= _this8.get('model.pumpCalculations.power');
      });

      magnetsFiltered.forEach(function (magnet) {
        var _adapters = magnet.get('motorAdapters').toArray();

        var filteredAdapters = _adapters.filter(function (adapter) {
          return adapter.get('adapterType') === _this8.get('pumpApplication.motor_adapter.value').toUpperCase();
        });

        filteredAdapters.forEach(function (adapter) {
          var resultLine = Ember.Object.create({
            id: _count,
            magnet: magnet,
            adapter: adapter,
            magnetDash: magnet.get('dash_number'),
            magnetName: magnet.get('description'),
            adapterDash: adapter.get('dash_number'),
            adapterName: adapter.get('name')
          });

          resultLine.selected = resultLine === _this8.get('selectedResult');

          _resultsList.push(resultLine);
          _count = _count + 1;
        });
      });

      return _resultsList;
    }),
    sortedAdapterMagnetResults: Ember.computed.sort('adapterMagnetResults', 'adapterMagnetSortKeys'),

    adapterMagnetMotorResults: Ember.computed('model.magnets.[]', 'motors.[]', 'pumpApplication.motor_adapter', function () {
      var _this9 = this;

      var _resultsList = [];
      var _count = 0;
      var _magnets = this.get('model.magnets');

      // Magnets need to be able to handle the required operating HP of the design
      var magnetsFiltered = _magnets.filter(function (magnet) {
        return magnet.get('maxHp') >= new _conversions.Power(_this9.get('power_unit'), _this9.get('model.pumpCalculations.power')).convertTo('HP');
      });

      magnetsFiltered.forEach(function (magnet) {
        var _adapters = magnet.get('motorAdapters').toArray();

        var filteredAdapters = _adapters.filter(function (adapter) {
          return adapter.get('adapterType') === _this9.get('pumpApplication.motor_adapter.value').toUpperCase();
        });

        filteredAdapters.forEach(function (adapter) {
          // Get all motors compatible with the magnet
          var motorsByAdapter = _this9.get('motors').filter(function (m) {
            var cma = m.get('compatibleMotorAdapter');
            var result = cma.get('id') === adapter.get('id');
            var frequency = _this9.get('pumpApplication.frequency.value');

            // Use non-overloading power calculation if selected as motor / magnet size
            // Otherwise use normal power as a comparison.
            if (_this9.get('pumpApplication.motor_size.value') === 'non_overloading') {
              var appNolPower = new _conversions.Power(_this9.get('power_unit'), _this9.get('model.pumpCalculations.nol_power')).convertTo('HP');
              result = result && m.get('power') > appNolPower;
            } else {
              var appPower = new _conversions.Power(_this9.get('power_unit'), _this9.get('model.pumpCalculations.power')).convertTo('HP');
              result = result && m.get('power') > appPower;
            }
            result = result && m.get('motorTypeCode') === _this9.get('pumpApplication.motor_type.value');
            result = result && m.get('phase') === _this9.get('pumpApplication.motor_phase.value');
            result = result && (m.get('is' + frequency + 'Hertz') == true || m.get('isDualHertz') == true);

            result = result && (m.get('speedRange') === _this9.get('pumpApplication.motor_speed.value') || _this9.get('pumpApplication.motor_speed.value') === 'both');

            return result;
          });

          motorsByAdapter.forEach(function (mba) {
            var magnetSR = magnet.get('selectionRank') || 0;
            var adapterSR = adapter.get('selectionRank') || 0;
            var motorSR = mba.get('sortOrder') || 0;
            var overallSR = magnetSR + adapterSR + motorSR;

            var resultLine = Ember.Object.create({
              id: _count,
              motor: mba,
              magnet: magnet,
              adapter: adapter,
              magnetDash: magnet.get('dash_number'),
              magnetName: magnet.get('description'),
              adapterDash: adapter.get('dash_number'),
              adapterName: adapter.get('name'),
              motorDash: mba.get('dash_number'),
              overallSelectionRank: overallSR
            });

            resultLine.selected = resultLine === _this9.get('selectedResult');

            _resultsList.push(resultLine);
            _count = _count + 1;
          });
        });
      });

      return _resultsList;
    }),
    sortedAdapterMagnetMotorResults: Ember.computed.sort('adapterMagnetMotorResults', 'adapterMagnetMotorSortKeys'),

    initialAdapterMagnetMotorResults: Ember.computed('model.magnets.[]', 'motors.[]', 'pumpApplication.motor_adapter', function () {
      var _this10 = this;

      var _resultsList = [];
      var _count = 0;
      var _magnets = this.get('model.magnets');

      // Magnets need to be able to handle the required operating HP of the design
      var magnetsFiltered = _magnets.filter(function (magnet) {
        return magnet.get('maxHp') >= new _conversions.Power(_this10.get('power_unit'), _this10.get('model.pumpCalculations.power')).convertTo('HP');
      });

      magnetsFiltered.forEach(function (magnet) {
        var _adapters = magnet.get('motorAdapters').toArray();

        var filteredAdapters = _adapters.filter(function (adapter) {
          return adapter.get('adapterType') === _this10.get('pumpApplication.motor_adapter.value').toUpperCase();
        });

        filteredAdapters.forEach(function (adapter) {
          // Get all motors compatible with the magnet
          var motorsByAdapter = _this10.get('motors').filter(function (m) {
            var cma = m.get('compatibleMotorAdapter');
            var result = cma.get('id') === adapter.get('id');
            var frequency = _this10.get('pumpApplication.frequency.value');

            // Use non-overloading power calculation if selected as motor / magnet size
            // Otherwise use normal power as a comparison.
            if (_this10.get('pumpApplication.motor_size.value') === 'non_overloading') {
              var appNolPower = new _conversions.Power(_this10.get('power_unit'), _this10.get('model.pumpCalculations.nol_power')).convertTo('HP');
              result = result && m.get('power') > appNolPower;
            } else {
              var appPower = new _conversions.Power(_this10.get('power_unit'), _this10.get('model.pumpCalculations.power')).convertTo('HP');
              result = result && m.get('power') > appPower;
            }
            result = result && m.get('motorTypeCode') === _this10.get('pumpApplication.motor_type.value');
            result = result && m.get('phase') === _this10.get('pumpApplication.motor_phase.value');
            result = result && (m.get('is' + frequency + 'Hertz') == true || m.get('isDualHertz') == true);

            result = result && (m.get('speedRange') === _this10.get('pumpApplication.motor_speed.value') || _this10.get('pumpApplication.motor_speed.value') === 'both');

            return result;
          });

          motorsByAdapter.forEach(function (mba) {
            var magnetSR = magnet.get('selectionRank') || 0;
            var adapterSR = adapter.get('selectionRank') || 0;
            var motorSR = mba.get('sortOrder') || 0;
            var overallSR = magnetSR + adapterSR + motorSR;

            var resultLine = Ember.Object.create({
              id: _count,
              motor: mba,
              magnet: magnet,
              adapter: adapter,
              magnetDash: magnet.get('dash_number'),
              magnetName: magnet.get('description'),
              adapterDash: adapter.get('dash_number'),
              adapterName: adapter.get('name'),
              motorDash: mba.get('dash_number'),
              overallSelectionRank: overallSR
            });

            resultLine.selected = resultLine === _this10.get('selectedResult');

            _resultsList.push(resultLine);
            _count = _count + 1;
          });
        });
      });

      return _resultsList;
    }),
    sortedInitialAdapterMagnetMotorResults: Ember.computed.sort('initialAdapterMagnetMotorResults', 'initialAdapterMagnetMotorSortKeys'),
    bestFitAdapterMagnetMotorResult: Ember.computed('sortedInitialAdapterMagnetMotorResults.[]', function () {
      return this.get('sortedInitialAdapterMagnetMotorResults.firstObject');
    }),

    configurationErrors: Ember.computed('dbIncompatibleAdapterType', 'emptyBushingMaterials', 'emptyShaftMaterials', 'emptyOringMaterials', 'emptyConnections', 'unavailableMotors', function () {
      return Ember.get(this, 'dbIncompatibleAdapterType') || Ember.get(this, 'emptyBushingMaterials') || Ember.get(this, 'emptyOringMaterials') || Ember.get(this, 'emptyConnections') || Ember.get(this, 'emptyShaftMaterials') || Ember.get(this, 'unavailableMotors');
    }),
    incompatibleMaterials: Ember.computed('emptyBushingMaterials', 'emptyOringMaterials', 'emptyConnections', 'emptyShaftMaterials', function () {
      return Ember.get(this, 'emptyBushingMaterials') || Ember.get(this, 'emptyOringMaterials') || Ember.get(this, 'emptyConnections') || Ember.get(this, 'emptyShaftMaterials');
    }),
    emptyBushingMaterials: Ember.computed('bushingMaterials', function () {
      return Ember.get(this, 'bushingMaterials.length') === 0;
    }),
    emptyShaftMaterials: Ember.computed('shaftMaterials.[]', function () {
      return Ember.get(this, 'shaftMaterials.length') === 0;
    }),
    emptyOringMaterials: Ember.computed('oringMaterials', function () {
      return Ember.get(this, 'oringMaterials.length') === 0;
    }),
    emptyMotors: Ember.computed('sortedAdapterMagnetMotorResults.length', function () {
      return Ember.get(this, 'sortedAdapterMagnetMotorResults.length') === 0;
    }),
    unavailableMotors: Ember.computed('emptyMotors', 'pumpApplication.include_motors', function () {
      return Ember.get(this, 'pumpApplication.include_motors') && Ember.get(this, 'emptyMotors') ? true : false;
    }),

    requiredFieldsFilled: Ember.computed('pumpApplication.includeMotors', 'pumpSelection.{motor,motorAdapter}', function () {
      var fieldsFilled = void 0;
      fieldsFilled = Ember.isBlank(this.get('pumpSelection.motorAdapter')) ? false : true;
      if (this.get('pumpApplication.includeMotors')) {
        fieldsFilled = fieldsFilled && !Ember.isBlank(this.get('pumpSelection.motor'));
      }
      return fieldsFilled;
    }),

    detectFactoryWarning: function detectFactoryWarning(obj) {
      if (obj.get('contactFactory')) {
        this.set('appState.contactFactoryPricing', true);
        (0, _emberSweetalert.default)({
          title: 'Contact Factory',
          text: 'Contact the factory for pricing',
          type: 'warning',
          confirmButtonText: 'Okay'
        }).catch(_emberSweetalert.default.noop);
      }
    },


    actions: {
      // Selection action for ember-select on [housingMaterial]
      selectHousingMaterial: function selectHousingMaterial(value, option) {
        this.set('housingMaterial', option);
      },

      // Selection action for ember-select on [bushingMaterial]
      selectBushingMaterial: function selectBushingMaterial(value, option) {
        this.set('bushingMaterial', option);
      },

      // Selection action for ember-select on [oringMaterial]
      selectOringMaterial: function selectOringMaterial(value, option) {
        this.set('oringMaterial', option);
      },

      // Selection action for ember-select on [connection]
      selectConnection: function selectConnection(value, option) {
        this.set('connection', option);
      },

      // Selection action for ember-select on [shaftMaterial]
      selectShaftMaterial: function selectShaftMaterial(value, option) {
        this.set('shaftMaterial', option);
      },

      // Selection action for ember-select on [hardwareMaterial]
      selectHardwareMaterial: function selectHardwareMaterial(value, option) {
        this.set('hardwareMaterial', option);
      },

      // Selection action for ember-select on [baseplate]
      selectBaseplate: function selectBaseplate(value, option) {
        this.set('baseplate', option);
      },

      // Selection action for ember-select on [vaporSealMaterial]
      selectVaporSealMaterial: function selectVaporSealMaterial(value, option) {
        this.set('vaporSealMaterial', option);
      },

      // Selection action for ember-select on [motorSafetyFeature]
      selectMotorSafetyFeature: function selectMotorSafetyFeature(value, option) {
        this.set('motorSafetyFeature', option);
      },

      // Selection action for ember-select on [clampRing]
      selectClampRing: function selectClampRing(value, option) {
        this.set('clampRing', option);
      },
      toggleMagnetAdapterModal: function toggleMagnetAdapterModal() {
        this.toggleProperty('isShowingMAModal');
      },
      toggleMagnetAdapterMotorModal: function toggleMagnetAdapterMotorModal() {
        this.toggleProperty('isShowingMAMModal');
      },
      handleModalSelectedResult: function handleModalSelectedResult(selectedResult) {
        this.set('modalSelectedResult', selectedResult);
      },
      handleMagnetAdapterSelection: function handleMagnetAdapterSelection() {
        if (this.get('modalSelectedResult')) {
          this.set('magnet', this.get('modalSelectedResult.magnet'));
          this.set('motorAdapter', this.get('modalSelectedResult.adapter'));
          this.toggleProperty('isShowingMAModal');
        }
      },
      handleMagnetAdapterMotorSelection: function handleMagnetAdapterMotorSelection() {
        if (this.get('modalSelectedResult')) {
          this.set('magnet', this.get('modalSelectedResult.magnet'));
          this.set('motorAdapter', this.get('modalSelectedResult.adapter'));
          this.set('motor', this.get('modalSelectedResult.motor'));
          this.toggleProperty('isShowingMAMModal');
        }
      }
    }
  });
});