define('fti-pump-selector/routes/ac-pump-options', ['exports', 'fti-pump-selector/routes/fti-route'], function (exports, _ftiRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ftiRoute.default.extend({
    appState: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('appState.validFilters')) {
        this.transitionTo('pump-selector');
      }
    }
  });
});