define('fti-pump-selector/models/qhn', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    q: (0, _attr.default)('number'),
    h: (0, _attr.default)('number'),
    eff: (0, _attr.default)('number'),
    power: (0, _attr.default)('number'),
    npsh: (0, _attr.default)('number'),

    pump: (0, _relationships.belongsTo)('pump', { async: false })
  });
});