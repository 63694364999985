define('fti-pump-selector/models/configuration', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Configuration = _model.default.extend({
    name: (0, _attr.default)('string')
  });

  Configuration.reopenClass({
    FIXTURES: [{ id: 1, name: 'Horizontal Mechanical Sealed' }, { id: 2, name: 'Cantilevered Vertical' }, { id: 3, name: 'Vertical' }, { id: 4, name: 'Vertical Sealless Mag Drive' }, { id: 5, name: 'Vertical Multi-stage' }, { id: 6, name: 'Heavy Duty Sealless Mag Drive' }, { id: 7, name: 'Horizontal Multi-stage Mag Drive' }, { id: 8, name: 'Horizontal Seallless Mag Drive' }, { id: 9, name: 'Self-Priming Sealless Mag Drive' }]
  });

  exports.default = Configuration;
});