define('fti-pump-selector/controllers/base', ['exports', 'fti-pump-selector/models/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    settings: _settings.default.create(),

    _units: null,
    _temperature: null,
    _temperatureUnit: null,
    _viscosity: null,
    _viscosity_unit: null,
    _expressHeadInPressure: null,

    units: Ember.computed('_units', 'settings.unitSystem', {
      get: function get() {
        if (!Ember.isNone(this.get('_units'))) {
          return this.get('_units');
        } else if (!Ember.isNone(this.get('settings.unitSystem'))) {
          return this.get('units_available').findBy('value', this.get('settings.unitSystem'));
        } else {
          return this.get('units_available.firstObject');
        }
      },
      set: function set(key, value) {
        this.set('_units', value);
        this.set('settings.unitSystem', value.value);
        return this.get('_units');
      }
    }),

    viscosity: Ember.computed('_viscosity', 'settings', {
      get: function get() {
        if (!Ember.isNone(this.get('_viscosity'))) {
          return this.get('_viscosity');
        } else {
          return this.get('settings.viscosity');
        }
      },
      set: function set(key, value) {
        this.set('_viscosity', value);
        this.set('settings.viscosity', value);
        return this.get('_viscosity');
      }
    }),

    viscosity_unit: Ember.computed('_viscosity_unit', 'settings', {
      get: function get() {
        if (!Ember.isNone(this.get('_viscosity_unit'))) {
          return this.get('_viscosity_unit');
        } else if (!Ember.isNone(this.get('settings.viscosityUnit'))) {
          return this.get('viscosity_units').findBy('value', this.get('settings.viscosityUnit'));
        } else {
          return this.get('viscosity_units.firstObject');
        }
      },
      set: function set(key, value) {
        this.set('_viscosity_unit', value);
        this.set('settings.viscosityUnit', value.value);
        return this.get('_viscosity_unit');
      }
    }),

    temperature: Ember.computed('_temperature', 'settings', {
      get: function get() {
        if (!Ember.isNone(this.get('_temperature'))) {
          return this.get('_temperature');
        } else if (!Ember.isNone(this.get('settings.temperature'))) {
          return this.get('settings.temperature');
        }
      },
      set: function set(key, value) {
        this.set('_temperature', value);
        this.set('settings.temperature', value);
        return this.get('_temperature');
      }
    }),

    temperatureUnitSelected: Ember.computed('_temperatureUnit', 'settings', {
      get: function get() {
        if (!Ember.isNone(this.get('_temperatureUnit'))) {
          return this.get('_temperatureUnit');
        } else if (!Ember.isNone(this.get('settings.temperatureUnit'))) {
          return this.get('temperatures').findBy('value', this.get('settings.temperatureUnit'));
        } else {
          return this.get('temperatures.firstObject');
        }
      },
      set: function set(key, value) {
        this.set('_temperatureUnit', value);
        this.set('settings.temperatureUnit', value.value);
        return value;
      }
    }),
    temperature_unit: Ember.computed('temperatureUnitSelected', function () {
      return this.get('temperatureUnitSelected.value');
    }),

    expressHeadInPressure: Ember.computed('_expressHeadInPressure', 'settings', {
      get: function get() {
        if (!Ember.isNone(this.get('_expressHeadInPressure'))) {
          return this.get('_expressHeadInPressure');
        } else {
          return this.get('settings.expressHeadInPressure');
        }
      },
      set: function set(key, value) {
        this.set('_expressHeadInPressure', value);
        this.set('settings.expressHeadInPressure', value);
        return this.get('_expressHeadInPressure');
      }
    }),

    viscosity_units: Ember.A([{
      value: 'centipoise',
      label: 'Centipoise'
    }, {
      value: 'centistokes',
      label: 'Centistokes'
    }, {
      value: 'ssu',
      label: 'SSU'
    }]),

    temperatures: Ember.A([{
      value: 'F',
      label: 'Fahrenheit'
    }, {
      value: 'C',
      label: 'Celcius'
    }]),

    units_available: Ember.A([{
      value: 1,
      label: 'English (gpm, ft, hp, psi)'
    }, {
      value: 2,
      label: 'SI (cmph, m, kW, kPA)'
    }, {
      value: 3,
      label: 'SI (cmph, m, kW, bar)'
    }, {
      value: 4,
      label: 'SI (lpm, m, kW, kPa)'
    }, {
      value: 5,
      label: 'SI (lpm, m, kW, bar)'
    }]),

    flow_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 3) {
        return 'CMPH';
      }
      if (unit_value === 4 || unit_value === 5) {
        return 'LPM';
      }

      // Default return or value == 1
      return 'GPM';
    }),

    length_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 3 || unit_value === 4 || unit_value === 5) {
        return 'M';
      }

      // Default return or value == 1
      return 'FT';
    }),

    head_unit: Ember.computed('units', 'expressHeadInPressure', function () {
      var unit_value = this.get('units.value');

      if (this.get('expressHeadInPressure')) {
        if (unit_value === 2 || unit_value === 4) {
          return 'KPA';
        }
        if (unit_value === 3 || unit_value === 5) {
          return 'BAR';
        }

        // Default return or value == 1
        return 'PSI';
      } else {
        if (unit_value === 2 || unit_value === 3 || unit_value === 4 || unit_value === 5) {
          return 'M';
        }

        // Default return or value == 1
        return 'FT';
      }
    }),

    power_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 3 || unit_value === 4 || unit_value === 5) {
        return 'KW';
      }

      // Default return or value == 1
      return 'HP';
    }),

    pressure_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 4) {
        return 'KPA';
      }
      if (unit_value === 3 || unit_value === 5) {
        return 'BAR';
      }

      // Default return or value == 1
      return 'PSI';
    })
  });
});