define('fti-pump-selector/utils/pump', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var PumpUtils = function () {
    function PumpUtils() {
      _classCallCheck(this, PumpUtils);
    }

    _createClass(PumpUtils, [{
      key: 'findRoot',
      value: function findRoot(modelNumber) {
        var components = modelNumber.split('-');

        var _components = _slicedToArray(components, 1),
            root = _components[0];

        components.splice(0, 1);

        return root;
      }
    }, {
      key: 'findAccessories',
      value: function findAccessories(modelNumber) {
        var components = modelNumber.split('-');
        if (components.length > 1) {
          var _components2 = _slicedToArray(components, 2),
              checkForWetEnd = _components2[1];

          // Remove root & wet end for accessories
          if (checkForWetEnd === 'W') {
            components.splice(0, 2);
          }
          // Remove duplicate entries for additives here.
          // Will later be taken care of using validation
          return components;
        }
      }
    }, {
      key: 'findPumpOptions',
      value: function findPumpOptions(modelNumber) {
        var leftOverNumber = void 0,
            root = void 0;
        root = this.findRoot(modelNumber);
        leftOverNumber = modelNumber.replace(root + '-', '');
        leftOverNumber = leftOverNumber.replace('' + root, '');
        return leftOverNumber.split('-');
      }
    }, {
      key: 'validatePumpNumber',
      value: function validatePumpNumber(pumpBase) {
        var accessories = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

        var validationObj = {
          valid: true,
          errors: []
        };
        var additiveCategories = {};

        if (Ember.isNone(pumpBase) || Ember.isNone(accessories)) {
          throw new Error('Missing required arguments');
        }
        var validPromise = new Ember.RSVP.Promise(function (resolve, reject) {
          var sanitizedDashNumbers = accessories.uniq();

          if (sanitizedDashNumbers.length !== accessories.length) {
            validationObj.valid = false;
            validationObj.errors.push({
              message: 'Duplicate dash numbers found'
            });
          }

          pumpBase.get('pumpAdditives').then(function (additives) {
            accessories.forEach(function (acc) {
              var additiveFound = additives.find(function (add) {
                return add.get('dashNumber') === acc;
              });

              if (additiveFound) {
                var category = additiveFound.get('fieldType');
                if (additiveCategories[category] && additiveCategories[category] === true) {
                  validationObj.valid = false;
                  validationObj.errors.push({
                    message: 'Multiple selections of upgrades in the category: ' + category
                  });
                } else {
                  additiveCategories[category] = true;
                }
              }
            });

            if (validationObj.valid) {
              resolve(validationObj);
            } else {
              reject(validationObj);
            }
          });
        });

        return validPromise;
      }
    }]);

    return PumpUtils;
  }();

  exports.default = PumpUtils;
});